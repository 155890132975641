// axios
import axios from 'axios'
import router from '@/router'
import { login } from '@/apis/login'
import { ElLoading, ElMessage } from 'element-plus'
// 实例化
axios.defaults.withCredentials = true
const instance = axios.create({

  timeout: 20000 // 超时时间
})
// 1.请求拦截器
instance.interceptors.request.use((config) => {
  let url = config.url
  // get参数编码
  if (config.method === 'get' && config.params) {
    url += '?'
    const keys = Object.keys(config.params)
    for (const key of keys) {
      url += `${key}=${encodeURIComponent(config.params[key])}&`
    }
    url = url.substring(0, url.length - 1)
    config.params = {}
  }
  config.url = url
  return config
}, (error) => {
  return Promise.reject(error)
})
// 添加响应拦截器
instance.interceptors.response.use((res) => {
  const { status } = res.data
  if (status === -2) {
    login()
  } else if (status === 302) {
    location.href = res.data.data
    return Promise.reject(res)
  } else if (status === -202) {
    router.push({
      path: '/noAuthority'
    })
  } else if (status === -5) {
    ElMessage.error(res.data.msg)
    return Promise.reject(res)
  } else if (status === 0) {
    return res.data
  } else if (status === 1 || status === -3) {
    return Promise.reject(res)
  } else {
    ElMessage.error(res.data.msg)
    return Promise.reject(res)
  }
})
/**
 * @description: 请求函数
 * @param {*} url  请求地址
 * @param {*} method  请求方法 GET/POST
 * @param {*} reqData  请求参数 Object
 * @return {*} Promise
 */
export function request(url, method, reqData, contentType = 'application/json', withCredentials = true, baseURL = process.env.VUE_APP_APP_HOST, responseType) {
  const obj = {
    baseURL: baseURL,
    url,
    method,
    [method.toLowerCase() === 'get' ? 'params' : 'data']: reqData,
    headers: { 'content-type': contentType },
    withCredentials
  }
  if (responseType) obj.responseType = responseType
  return instance(obj)
}
export function upload(file, data, callback) {
  const obj = data
  const formData = new FormData()
  formData.append('key', obj.key)
  formData.append('policy', obj.policy)
  formData.append('OSSAccessKeyId', obj.accessId)
  formData.append('success_action_status', 200)
  formData.append('callback', obj.callback)
  formData.append('signature', obj.signature)
  formData.append('file', file)
  const loading = ElLoading.service({
    lock: true,
    text: '上传中,请稍等',
    spinner: 'el-icon-loading',
    background: 'rgba(255, 255, 255, 0)'
  })
  request(obj.host, 'post', formData, 'application/x-www-form-urlencoded', false).then(() => {
    loading.close()
    callback(obj)
  })
}
