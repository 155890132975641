
<template>
  <main v-if="selectAddressVisible" class="address">
    <el-dialog
      v-model="selectAddressVisible"
      title="收货地址"
      custom-class="addressTable"
      append-to-body
    >
      <div class="add">
        <li>
          <el-input
            v-model="searchText"
            style="width: 200px;"
            placeholder="请输入搜索内容"
            prefix-icon="el-icon-search"
            clearable
            @change="searchAddress"
            @clear="searchAddress('')"
          />
        </li>
        <li>
          <ls-button type="primary" style="font-size:12px;" size="small" :icon="addAddress?'el-icon-check':'el-icon-plus'" @click="addAddressInput">
            {{ addAddress ? '完成' : '添加' }}
          </ls-button>
        </li>
      </div>
      <el-table :data="clientAddressList" height="500px" border>
        <el-table-column label="序号" width="60" align="center">
          <template #default="scope">
            <div>{{ scope.$index + 1 }}</div>
          </template>
        </el-table-column>
        <el-table-column property="addressDetail" label="详细地址">
          <template #default="scope">
            <div v-if="scope.row.editStatus">
              <el-input
                v-model="addForm.addressDetail"
                maxlength="20"
                placeholder="请填写地址"
              />
            </div>
            <div v-else class="addressDetail" :title="scope.row.addressDetail">
              {{ scope.row.addressDetail }}
            </div>
          </template>
        </el-table-column>
        <el-table-column property="contacts" label="收货人">
          <template #default="scope">
            <div v-if="scope.row.editStatus">
              <el-input
                v-model="addForm.contacts"
                maxlength="20"
                placeholder="收货人"
              />
            </div>
            <div v-else>
              {{ scope.row.contacts }}
            </div>
          </template>
        </el-table-column>
        <el-table-column property="phone" label="联系电话">
          <template #default="scope">
            <div v-if="scope.row.editStatus">
              <el-input
                v-model="addForm.phone"
                maxlength="20"
                placeholder="联系电话"
              />
            </div>
            <div v-else>
              {{ scope.row.phone }}
            </div>
          </template>
        </el-table-column>
        <el-table-column property="address" label="操作" width="200">
          <template #default="scope">
            <div v-if="scope.row.editStatus">
              <ls-button
                size="mini"
                type="primary"
                style="min-width:0;margin-right:8px;font-size: 12px "
                @click="updateAddress(scope.row)"
              >
                确定
              </ls-button>
              <ls-button
                size="mini"
                type="info"
                class="ls-button-info"
                @click="scope.row.editStatus = false"
              >
                取消
              </ls-button>
            </div>
            <div v-else>
              <ls-button
                size="mini"
                type="primary"
                class="ls-button-primary"
                @click="selectAddress(scope.row)"
              >
                选择
              </ls-button>
              <ls-button
                size="mini"
                type="info"
                class="ls-button-info"
                @click="editAddress(scope.row, scope.$index)"
              >
                编辑
              </ls-button>
              <ls-button
                size="mini"
                type="danger"
                class="icon-del"
                @click="delAddress(scope.row)"
              >
                <el-icon @click="delAddress(scope.row)">
                  <delete />
                </el-icon>
              </ls-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <ul v-if="addAddress" class="addAddress">
        <li>
          <span>{{ clientAddressList.length + 1 }}</span>
        </li>
        <li>
          <el-input
            v-model="addForm.addressDetail"
            maxlength="20"
            placeholder="请填写地址"
          />
        </li>
        <li>
          <el-input
            v-model="addForm.contacts"
            maxlength="10"
            placeholder="请填写收货人"
          />
        </li>
        <li>
          <el-input
            v-model.number="addForm.phone"
            type="number"
            oninput="if(value.length>11)value=value.slice(0,11)"
            placeholder="请填写联系电话"
          />
        </li>
        <li>
          <ls-button style="font-size:12px;min-width:0;" size="mini" type="primary" @click="createAddress()">
            添加
          </ls-button>
        </li>
      </ul>
      <!-- 删除提示框 -->
    </el-dialog>
    <el-dialog
      v-model="deleteDialog"
      title="删除"
      width="480px"
    >
      <div class="tip">
        <div class="icon">
          <i class="el-icon-warning" />
        </div>
        <div>
          <div>确定要删除该地址？</div>
          <div>删除之后不可恢复，请谨慎处理</div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="scopeRow='';deleteDialog=false">取消</el-button>
          <el-button
            type="primary"
            @click="deleteClick"
          >确定</el-button>
        </span>
      </template>
    </el-dialog>
  </main>
</template>
<script setup>
import { clientInfo, addClientAddress, deleteAddress, updateClientAddress } from '@/apis/order'
import { Delete } from '@element-plus/icons'
import { ElMessage } from 'element-plus'
import { ref, defineExpose, defineEmits } from 'vue'
const emits = defineEmits(['getAddressFun'])

const clientId = ref(null)
const selectAddressVisible = ref(false)
const addForm = ref({
  defaultFlag: false
})
const addAddress = ref(false)
const searchText = ref('')
const clientAddressList = ref([])
const addressListData = ref([])
const region = ref([])

// 获取地址列表
const getList = (id) => {
  if (id) {
    clientId.value = id
  }
  const json = {
    id: clientId.value
  }
  // 客户地址
  clientInfo(json).then((res) => {
    clientAddressList.value = res.data.clientAddressList
    addressListData.value = [...clientAddressList.value]
    selectAddressVisible.value = true
  })
}
// 添加收货地址
const addAddressInput = () => {
  addAddress.value = !addAddress.value
  addForm.value = {
    defaultFlag: false
  }
  region.value = []
}
// 选择地址
const selectAddress = (row) => {
  emits('getAddressFun', row)
  selectAddressVisible.value = false
}
// 更新地址
const updateAddress = (row) => {
  const addFrom = addForm.value
  addFrom.clientId = clientId.value
  if (!addFrom.clientId || !addFrom.contacts || !addFrom.phone) {
    ElMessage.warning('请填写联系人和联系方式')
    return
  }
  updateClientAddress(addForm.value)
    .then(() => {
      getList()
      addForm.value = {
        defaultFlag: false
      }
      row.editStatus = false
      ElMessage.success('修改成功')
    })
    .catch((err) => {
      ElMessage.error(err.msg)
    })
}
// 编辑地址
const editAddress = (row, index) => {
  region.value = [row.province, row.city, row.area]
  const { ...form } = row
  addForm.value = form
  clientAddressList.value[index].editStatus = true
}
const scopeRow = ref()
const deleteDialog = ref(false)
// 删除地址
const delAddress = (row) => {
  scopeRow.value = row
  deleteDialog.value = true
}
const deleteClick = () => {
  deleteAddress({
    id: scopeRow.value.id
  }).then(() => {
    getList()
    ElMessage.success('删除成功')
    deleteDialog.value = false
  })
}
// 创建收货地址
const createAddress = () => {
  const addFrom = addForm.value
  addFrom.clientId = clientId.value
  if (!addFrom.clientId || !addFrom.contacts || !addFrom.phone) {
    ElMessage.warning('请填写联系人和联系方式')
    return
  }
  addClientAddress(addFrom)
    .then(() => {
      getList()
      addForm.value = {
        defaultFlag: false
      }
      addAddress.value = !addAddress.value
      ElMessage.success('添加成功')
    })
    .catch((err) => {
      ElMessage.error(err.msg)
    })
}
// 搜索
const searchAddress = (str) => {
  const newList = addressListData.value.filter((item) => {
    item.phone = String(item.phone)
    return item.phone.includes(Number(str)) || item.contacts.includes(str) || item.addressDetail.includes(str)
  })
  clientAddressList.value = [...newList]
}
defineExpose({
  getList
})
</script>
<style lang="less" scoped>
.addAddress {
  display: flex;
  border: 1px solid #ebeef5;
  border-top: none;
  line-height: 49px;
  li {
    flex: 1;
    border-right: 1px solid #ebeef5;
    box-sizing: border-box;

    &:first-child {
      flex: 0 60px;
      padding: 0px 10px;
    }

    &:last-child {
      flex: 0 198px;
      border: none;
      text-align: center;
    }
  }
  input {
    border: none;
  }
}
:deep(.ls-button-primary) {
  background: #ecf5ff !important;
  color: #409eff !important;
  min-width: 0 !important;
  font-size: 12px !important;
  margin-right: 8px;
  &:hover {
    background: #409eff !important;
    color: #fff !important;
  }
}
:deep(.ls-button-info){
  border-color: #909399 !important;
  background: #f4f4f5 !important;
  color: #909399 !important;
  min-width: 0 !important;
  font-size: 12px !important;
  margin-right: 8px;
  &:hover {
    background: #909399 !important;
    color: #fff !important;
  }
}
:deep(.icon-del){
  min-width:0;
  background:#f56c6c;
  font-size:12px;
  border-color:#f56c6c;
  padding-left: 12px;
  &:hover{
    background:#f56c6c;
    border-color:#f56c6c;
  }
  i{
    margin-left: 0;
    font-size: 12px !important;
  }
}

:deep(.tip) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .icon {
      font-size: 40px;
      margin-right: 16px;

      .el-icon-warning {
        color: #1890ff;
      }
    }
  }
  .addressTable {
  .add {
    display: flex !important;
    justify-content: space-between;
    li {
      display: inline-block;
      :deep(.el-input__inner){
        height: 32px;
        line-height: 32px;
      }
    }
    margin-bottom: 7px;
  }
}
.address {
  .el-dialog__body {
    padding-bottom: 20px !important;
  }
  .addressDetail {
    line-height: 20px;
    display: -webkit-box !important;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
</style>
