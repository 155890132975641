import { request } from '@/utils/request'
// 板材列表
export function boardList(data) {
  return request('/plateType/web/page', 'post', data)
}
// 编辑板材
export function updateBoard(id, data) {
  return request('/plateType/update/' + id, 'post', data)
}
// 员工管理
export function getRoles() {
  return request('/company/roles', 'get')
}
// 删除员工
export function delRoles(data) {
  return request('/company/role/removeUser', 'post', data, 'application/x-www-form-urlencoded')
}
// 获取工厂信息
export function getCompany(id) {
  return request('/company/detail/' + id, 'get')
}
// 获取团队码
export function getCode() {
  return request('/company/nest/teams', 'get')
}
// 编辑工厂信息
export function setCompany(data) {
  return request('/company/update', 'post', data)
}
// 打印模板信息
export function printDetail(type) {
  return request('/print/detail/' + type, 'get')
}
// 创建更新打印模板
export function printCreateOrUpdate(type, data) {
  return request('/print/createOrUpdate/' + type, 'post', data)
}
// 获取其他设置
export function otherSettings() {
  return request('/company/settings/detail', 'get')
}
// 更新其他设置
export function updateOtherSettings(data) {
  return request('/company/update/settings', 'post', data)
}
