<template>
  <article>
    <aside>
      <div class="navTab">
        <el-radio-group v-model="tabPosition" @change="switchTab">
          <el-radio-button label="DELIVERY">
            发货单
          </el-radio-button>
          <el-radio-button label="OFFER">
            报价单
          </el-radio-button>
        </el-radio-group>
      </div>
      <h4>内容设置</h4>
      <p>注：公司logo、公司地址、公司电话在[工厂设置]里编辑</p>
      <li v-if="tabPosition==='OFFER'">
        <label>报价方式</label>
        <el-radio v-model="offerType" label="PART_QUOTE" @change="switchOffer">
          零件报价
        </el-radio>
        <el-radio v-model="offerType" label="PLATE_QUOTE" @change="switchOffer">
          整板报价
        </el-radio>
      </li>
      <li>
        <label>打印版式</label>
        <el-radio v-model="form.pageType" label="TRIPLICATE" @change="switchPageType">
          三联单
        </el-radio>
        <el-radio v-model="form.pageType" label="A4" @change="switchPageType">
          A4纸
        </el-radio>
      </li>
      <li>
        <label>表单抬头</label>
        <el-input v-model="form.formTitle" maxlength="20" placeholder="请输入抬头" />
      </li>
      <li class="list">
        <label>打印字段</label>
        <dl>
          <h5><span @click="resetField">重置</span></h5>
          <ul id="list-item">
            <li v-for="(item) in fieldList" :key="item.id" :data-no="j">
              <el-checkbox v-model="item.checked" :label="item" @change="selectField($event,item)">
                {{ item.title }}
              </el-checkbox><span class="handle icon-drags" />
            </li>
          </ul>
        </dl>
      </li>
      <li class="list">
        <label>备注</label>
        <el-input v-model="form.remarks" type="textarea" placeholder="请输入备注内容" show-word-limit maxlength="50" />
      </li>
      <li class="footer">
        <el-button type="primary" plain @click="resetForm">
          重置
        </el-button>
        <el-button type="primary" @click="savePost">
          保存
        </el-button>
      </li>
    </aside>
    <div class="preview">
      <h4>表单预览</h4>
      <div :class="{'print-box': true, 'A4': form.pageType==='A4'}">
        <div class="side">
          <i v-for="j in 7" :key="j" />
        </div>
        <ul>
          <div class="head">
            <div class="logo" />
            <h3>{{ form.formTitle }}</h3>
            <div class="addr">
              <p>地址：{{ orgInfo.address }}</p>
              <p>电话：021-61259023</p>
            </div>
          </div>
          <div :class="{inline:true,nb:tabPosition==='OFFER'}">
            <li>订单编号：210910-0008</li>
            <li>客户名称：宁波宁海周金强</li>
            <li><span>2021/11/2</span><span class="page">第 1 页，共 1 页</span></li>
          </div>
          <div v-if="tabPosition==='DELIVERY'" class="inline nb">
            <li>收货人：刘思思 | 13909845984</li>
            <li class="mright">
              收货地址：河南省南阳市邓州区中州路442号
            </li>
          </div>
          <div class="table">
            <div class="th">
              <table border="0" cellspacing="0" cellpadding="0">
                <tr>
                  <th>序号</th>
                  <th v-for="(item,index) in tableData" :key="index">
                    {{ item.title }}
                  </th>
                </tr>
                <tr>
                  <td>1</td>
                  <td v-for="(item,index) in tableData" :key="index" />
                </tr>
                <tr>
                  <td>2</td>
                  <td v-for="(item,index) in tableData" :key="index" />
                </tr>
                <tr>
                  <td>3</td>
                  <td v-for="(item,index) in tableData" :key="index" />
                </tr>
              </table>
            </div>
          </div>
          <div class="space">
            <li>备注：{{ form.remarks }}</li>
            <li>制单人：系统 <span v-if="tabPosition==='OFFER'">总报价：￥2800</span></li>
          </div>
          <div v-if="tabPosition==='DELIVERY'" class="sign">
            <li>送货单位及经手人(签字盖章)：</li>
            <li>收货单位及经手人(签字盖章)：</li>
          </div>
        </ul>
        <div class="side">
          <i v-for="j in 7" :key="j" />
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import { ref, reactive, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import { printDetail, printCreateOrUpdate } from '@/apis/my'
import Store from '@/store/index'
export default {
  name: 'PrintSetting',
  setup() {
    const form = reactive({
      formTitle: '发货单',
      pageType: 'TRIPLICATE',
      remarks: ''
    })
    const tabPosition = ref('DELIVERY')
    const offerType = ref('PART_QUOTE')
    const fieldList = reactive([])
    let queryType = 'DELIVERY'
    const orgInfo = reactive({})
    // 获取打印数据
    const getPrintDetail = () => {
      printDetail(queryType).then(({ data: res }) => {
        const { formTitle, remarks, pageType: type } = res
        form.pageType = type
        if (formTitle || remarks) {
          form.formTitle = formTitle
          form.remarks = remarks
        }
        const list = res.fieldList
        list.forEach((obj, j) => {
          if (obj.selected) {
            obj.checked = true
            tableData.push(obj)
          }
          obj.index = j
        })
        fieldList.push(...list)
      }).catch(({ data: err }) => {
        ElMessage.error(err.msg)
      })
    }
    onMounted(() => {
      var el = document.getElementById('list-item')
      window.Sortable.create(el, {
        animation: 150,
        handle: '.handle',
        dataIdAttr: 'data-no',
        onEnd({ newIndex, oldIndex }) {
          tableData.splice(0, tableData.length)
          const dmp = fieldList[oldIndex]
          fieldList[oldIndex] = fieldList[newIndex]
          fieldList[newIndex] = dmp
          fieldList.forEach(value => {
            if (value.checked) tableData.push(value)
          })
        }
      })
      getPrintDetail()
      // 工厂信息
      const { address } = Store.state.orgInfo
      orgInfo.address = address
    })
    // 模板类型
    const switchTab = (name) => {
      if (name === 'OFFER') {
        form.formTitle = '报价单'
        queryType = offerType.value
      } else {
        form.formTitle = '发货单'
        queryType = 'DELIVERY'
      }
      resetField()
    }
    // 报价方式
    const switchOffer = (name) => {
      queryType = name
      resetField()
    }
    // 打印版式
    const switchPageType = (type) => {
      form.pageType = type
    }
    // 字段操作
    const selectField = (flag, item) => {
      if (flag) {
        if (tableData.length >= 8) {
          ElMessage.warning('字段数量不能超过8个')
          item.checked = false
          return
        }
        tableData.splice(0, tableData.length)
        fieldList.forEach(value => {
          if (value.checked) tableData.push(value)
        })
      } else {
        tableData.forEach((obj, j) => {
          if (obj.id === item.id) {
            tableData.splice(j, 1)
          }
        })
      }
    }
    // 重置
    const resetField = () => {
      fieldList.length = 0
      tableData.length = 0
      getPrintDetail()
    }
    // 重置表单
    const resetForm = () => {
      fieldList.length = 0
      tableData.length = 0
      getPrintDetail()
      form.formTitle = queryType === 'OFFER' ? '报价单' : '发货单'
      form.remarks = ''
    }
    // 保存
    const savePost = () => {
      const printFieldList = []
      tableData.forEach((obj, j) => {
        printFieldList.push({
          id: obj.id,
          idx: j
        })
      })
      const json = {
        ...form,
        printFieldList
      }
      printCreateOrUpdate(queryType, json).then(() => {
        ElMessage.success('保存成功')
      }).catch(({ data: res }) => {
        ElMessage.error(res.msg)
      })
    }
    // 打印字段
    const tableData = reactive([])
    return {
      form,
      tabPosition,
      offerType,
      fieldList,
      tableData,
      orgInfo,
      switchTab,
      switchOffer,
      switchPageType,
      getPrintDetail,
      selectField,
      resetField,
      resetForm,
      savePost
    }
  }
}
</script>

<style lang="less" scoped>
article {
  display: flex;
  height: calc(100vh - 105px);
  aside {
    flex: 0 0 350px;
    border-right: 1px solid #e6e8ea;
    padding: 16px 20px;
    h4 {
      display: flex;
      align-items: center;
      font-size: 16px;
      margin-top: 24px;
      &::before {
        content: "";
        display: inline-block;
        width: 6px;
        height: 16px;
        background: #1890ff;
        border-radius: 4px;
        margin-right: 8px;
      }
    }
    p {
      color: #909399;
      margin-top: 16px;
    }
    & > li {
      display: flex;
      align-items: center;
      margin-top: 16px;
      label {
        line-height: 20px;
        flex: 0 0 56px;
        margin-right: 10px;
        text-align: right;
      }
      dl {
        flex: 1;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        h5 {
          line-height: 36px;
          border-bottom: 1px solid #dcdfe6;
          text-align: right;
          color: #1890ff;
          padding: 0px 12px;
          span {
            cursor: pointer;
            font-weight: 400;
          }
        }
        ul {
          height: 320px;
          padding: 0px 16px;
          overflow-y: auto;
          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            line-height: 30px;
            &:deep(.el-checkbox__input.is-checked + .el-checkbox__label) {
              color: #303133;
            }
            & > span {
              font-size: 16px;
              color: #909399;
              cursor: pointer;
            }
          }
        }
      }
      &.list {
        align-items: flex-start;
        & > label {
          margin-top: 8px;
        }
      }
    }
    .footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 23px;
      button {
        width: 80px;
      }
    }
  }
  .preview {
    flex: 1;
    padding: 75px 20px;
    background: url("~@/assets/img/print_main_bg.png");
    background-size: 162px;
    h4 {
      font-size: 16px;
      font-weight: 400;
      &::before {
        content: "";
        display: inline-block;
        width: 6px;
        height: 16px;
        background: #1890ff;
        border-radius: 4px;
        margin-right: 8px;
      }
    }
    .print-box {
      display: flex;
      width: 960px;
      background: #fff;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
      margin: 137px auto;
      padding-bottom: 24px;
      .side {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex: 0 0 28px;
        padding: 14px 0px;
        i {
          display: block;
          width: 10px;
          height: 10px;
          background: #d8d8d8;
          border-radius: 50%;
        }
      }
      ul {
        flex: 1;
        border: 1px dashed #d8d8d8;
        border-top: none;
        border-bottom: none;
        padding: 0px 8px;
        .head {
          display: flex;
          align-items: center;
          height: 52px;
          border-bottom: 1px solid #d8d8d8;
          .logo {
            flex: 0 0 165px;
          }
          h3 {
            flex: 1;
            font-size: 18px;
            text-align: center;
          }
          .addr {
            word-wrap: wrap;
            font-size: 12px;
            color: #303133;
            text-align: right;
          }
        }
        .inline {
          display: flex;
          line-height: 28px;
          justify-content: space-between;
          border-bottom: 1px solid #d8d8d8;
          font-size: 12px;
          .mright {
            margin-left: 20px;
            margin-right: auto;
          }
          &.nb {
            border: none;
          }
          li {
            span {
              margin-left: 12px;
            }
          }
        }
        .table {
          margin: 6px 0px 4px;
          table {
            width: 100%;
            border: 1px solid #d8d8d8;
            border-bottom: none;
            border-right: none;
            th,
            td {
              line-height: 28px;
              border-right: 1px solid #d8d8d8;
              border-bottom: 1px solid #d8d8d8;
              &:first-child {
                width: 36px;
                text-align: center;
              }
            }
          }
        }
        .space,
        .sign {
          display: flex;
          justify-content: space-between;
          span {
            margin-left: 24px;
          }
        }
        .space {
          li {
            &:last-child {
              white-space: nowrap;
              margin-left: 24px;
            }
          }
        }
        .sign {
          margin-top: 14px;
          padding-top: 4px;
          border-top: 1px solid #d8d8d8;
          li {
            flex: 1;
          }
        }
      }
      &.A4 {
        height: 600px;
        padding: 30px;
        margin: 30px auto;
        .side {
          display: none;
        }
        ul {
          border: none;
        }
      }
    }
  }
}
</style>
