import {
  request
} from '@/utils/request'
const EC_HOST = process.env.VUE_APP_EC_HOST
const MES_HOST = process.env.VUE_APP_MES_HOST
// 订单列表
export function orderList(data) {
  return request('/customer/order/page', 'post', data)
}
// 订单详情
export function orderDetail(id, data) {
  return request('/customer/order/detail/' + id, 'get', data, 'application/x-www-form-urlencoded')
}
// 订单日志
export function orderLog(id) {
  return request('/customer/order/logs/' + id, 'get')
}
// cad列表
export function cadList(id, data) {
  return request('/customer/order/cad/page/' + id, 'post', data)
}
// 上传文件token
export function cadToken(data) {
  return request('order/file/token/cad', 'post', data)
}
// 去排样
export function orderNest(id) {
  return request('order/nest/' + id, 'post')
}
// 获取排样列表
export function getTaskList(id) {
  return request('/order/detail/task/' + id, 'get')
}
// 获取机床列表
export function getMachineList() {
  return request('/company/machines', 'get')
}
// 去加工（单个任务）
export function setProcess(id, data) {
  return request('/order/process/' + id, 'post', data, 'application/x-www-form-urlencoded')
}
// 去加工（订单下全部任务）
export function setAllProcess(id, data) {
  return request('/order/processAll/' + id, 'post', data, 'application/x-www-form-urlencoded')
}
// 撤销任务
export function backTask(data) {
  return request('/task/quash', 'post', data, 'application/x-www-form-urlencoded')
}
// 获取订单任务详情列表
export function getTask(id) {
  return request('/order/detail/task/' + id, 'get')
}
// 去报价获取报价信息
export function getQuote(id, data) {
  return request('/order/quoteForm/quote/' + id, 'post', data, 'application/x-www-form-urlencoded')
}
// 重新报价获取报价信息
export function setReQuote(id) {
  return request('/order/quoteForm/reQuote/' + id, 'post')
}
// 获取报价信息
export function getQuoteDetail(id) {
  return request('/customer/order/quote/detail/' + id, 'get')
}
// 获取当前订单和最近一次的报价类型
export function getQuoteNewType(id) {
  return request('/order/quoteForm/type/' + id, 'get')
}
// 更换报价类型
export function changeType(id, data) {
  return request('/order/quoteForm/changeType/' + id, 'post', data, 'application/x-www-form-urlencoded')
}
// 打印
export function getPrintInfo(id, data) {
  return request('/print/printDetail/' + id, 'get', data)
}
// 创建上传附件
export function createOrderFile(data) {
  return request('/order/file/token', 'post', data)
}
// 创建订单
export function createNewOrder(orgCode, customerId, data) {
  return request('/customer/order/create/' + orgCode + '/' + customerId, 'post', data)
}
// 创建订单(新版)
export function newOrder(data) {
  return request('/order/create/miniprogram', 'post', data)
}
// 编辑订单
export function editOrderInfo(id, data) {
  return request('/customer/order/update/' + id, 'post', data)
}
// 编辑订单(新版)
export function editNewOrder(id, data) {
  return request('/order/update/miniprogram/' + id, 'post', data)
}
// 删除订单
export function delOrderPort(id, data) {
  return request('/customer/order/delete/' + id, 'post', data, 'application/x-www-form-urlencoded')
}
// 删除订单图纸
export function delFileCad(id) {
  return request('/order/file/delete/' + id, 'post')
}
// 获取机床列表
export function getMachines(data) {
  return request('/machine/page', 'post', data)
}
// 获取零件库分页列表
export function getPartPageList(id, data) {
  return request('/part/page/' + id, 'post', data)
}
// 获取零件库列表
export function getPartList(id) {
  return request('/order/list/part/' + id, 'post')
}
// 删除零件
export function delPartData(id, data) {
  return request('/order/delete/part/' + id, 'post', data, 'application/x-www-form-urlencoded')
}
// 批量推送
export function updatePartData(id, data) {
  return request('/order/update/parts/' + id, 'post', data)
}
// 获取零件图纸映射数据
export function getPartMapData(id) {
  return request('/customer/order/quote/recognizePartsMap/' + id, 'post', 'application/x-www-form-urlencoded')
}
// 自动报价
export function orderQuoteFormAutoQuote(id, quoteTypeId, data) {
  return request('/customer/order/quote/' + id + `?quoteTypeId=${quoteTypeId}`, 'post', data, 'application/json')
}
// 客户报价结果查询
export function orderQuoteFormAutoQuoteResult(id) {
  return request('/customer/order/quote/result/' + id, 'post', {}, 'application/json')
}
// 零件图层识别
export function getRecognizePartsMap(id) {
  return request('/customer/order/quote/recognizePartsMap/' + id, 'post', {}, 'application/x-www-form-urlencoded')
}
// 零件图层识别结果查询
export function getRecognizePartsMapResult(id) {
  return request('/customer/order/quote/recognizePartsMap/result/' + id, 'post', {})
}
// 获取所有报价类型
export function orderQuoteFormTypeList(data) {
  return request('/customer/order/quoteForm/type/list', 'get', data, 'application/x-www-form-urlencoded')
}
// 获取当前订单和默认的报价类型
export function orderQuoteFormType(id, data) {
  return request('/customer/order/quoteForm/type/' + id, 'GET', data, 'application/x-www-form-urlencoded')
}
// 客户获取订单报价信息
export function customerOrderQuoteDetail(id, data) {
  return request('/customer/order/quote/detail/' + id, 'GET', data, 'application/x-www-form-urlencoded')
}
// 更新报价信息零件列表
export function orderQuotePartUpdate(id, data) {
  return request('/customer/order/quote/part/update/' + id, 'post', data)
}
// 获取报价信息零件列表
export function orderQuoteFormTypePartList(id, data) {
  return request('/customer/order/quote/part/list/' + id, 'post', data)
}
// 获取板材类型列表
export function getPlateTypePage(data) {
  return request('/plateType/page', 'post', data)
}
// 客户更新零件信息后重新自动报价
export function updateAndReQuote(id) {
  return request('/customer/order/reQuote/' + id, 'post', {})
}
// 获取客户地址
export function customerAddressList(id) {
  return request('/customer/addressList/' + id, 'post')
}

// 提交订单
export function customerOrderSubmit(id) {
  return request('/customer/order/submit/' + id, 'post', {})
}
// 客户获取订单加工信息
export function orderProcessDetail(id) {
  return request('/customer/order/process/detail/' + id, 'GET', {}, 'application/x-www-form-urlencoded')
}
// 获取报价信息中的排板详情
export function orderQuoteTask(id, guid) {
  return request('/customer/order/quote/task/' + id + `?guid=${guid}`, 'POST', {}, 'application/x-www-form-urlencoded')
}
// 获取报价信息中的零件详情
export function orderQuotePart(id, guid) {
  return request('/customer/order/quote/part/' + id + `?guid=${guid}`, 'POST', {}, 'application/x-www-form-urlencoded')
}
// 获取订单错误cad图纸
export function customerCadFailed(id) {
  return request('/customer/cad/failed/' + id, 'POST')
}
/* ******************ec***************** */

// 获取客户信息
export function clientInfo(data) {
  return request('/external/client/info', 'get', data, 'multipart/form-data', true, EC_HOST)
}
// 编辑客户地址
export function updateClientAddress(data) {
  return request('/external/client/updateAddress', 'post', data, 'application/json', true, EC_HOST)
}
// 添加客户地址
export function addClientAddress(data) {
  return request('/external/client/addAddress', 'post', data, 'application/json', true, EC_HOST)
}
// 删除客户地址
export function deleteAddress(data) {
  return request('/external/client/deleteAddress', 'get', data, 'application/json', true, EC_HOST)
}
// 获取工序节点
export function nodeDefList(data) {
  return request('/mbos/client/workStep/listByClient', 'GET', data, 'multipart/form-data', true, EC_HOST)
}

/* ******************mes***************** */

// mes-排版缩略图（无文件）
export function nestResult2Img(data) {
  return request('/nest_result2img', 'post', data, 'application/json', true, MES_HOST)
}

// 获取客户报价频次配置及次数
export function getClientFrequency(data) {
  return request('/quote/customer/settings/view', 'get', data, 'multipart/form-data')
}

