<template>
  <aside>
    <div class="search">
      <li
        class="search-input"
        style="margin-right:10px"
      >
        <el-input
          v-model="page.searchText"
          placeholder="请输入搜索内容"
          :prefix-icon="Search"
          maxlength="20"
          clearable
          size="default"
          @change="searchFun"
        />
      </li>
      <li style="position:relative">
        <div class="dateInput">
          <el-input
            v-if="!searchDate"
            placeholder="交货日期"
            class="title"
            size="default"
            :suffix-icon="ArrowDown"
            @click="showDate"
          />
        </div>
        <div class="showDate">
          <el-input
            v-if="searchDate"
            v-model="showDates"
            class="date"
            placeholder="交货日期"
            clearable
            style="width:163px"
            @clear="clearDate"
          />
        </div>
        <el-date-picker
          ref="datePick"
          v-model="searchDate"
          value-format="YYYY.MM.DD"
          type="daterange"
          style="width: 240px;padding:0;overflow:hidden;height:0px;border:none;position:absolute;bottom:0;right:-50px"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="changeDate"
          @focus="onFocus"
          @blur="blurDate"
        />
      </li>
    </div>
    <div class="list">
      <ul class="nav">
        <el-tabs
          v-model="tabActiveName"
          @tab-click="tabClick"
        >
          <el-tab-pane
            v-for="(item, index) in tabData"
            :key="index"
            :name="item.status"
          >
            <template #label>
              <span class="tabs">{{ item.title }}<i>{{ statusTotal[item.status] }}</i></span>
            </template>
          </el-tab-pane>
        </el-tabs>
      </ul>
      <div
        class="list-item"
        :style="
          tabActiveName === 'DEFAULT'
            ? 'height:calc(100% - 79px)'
            : 'height:calc(100% - 50px)'
        "
      >
        <div
          v-if="tabActiveName === 'DEFAULT' "
          class="head"
        >
          <p class="page">
            共{{ statusTotal[tabActiveName] }}条数据 已加载{{
              listData.length
            }}条
          </p>
          <ls-button
            class="createBtn"
            @click="createOrder()"
          >
            + 新建订单
          </ls-button>
        </div>
        <p
          v-else
          class="page"
        >
          共{{ statusTotal[tabActiveName] }}条数据 已加载{{
            listData.length
          }}条
        </p>
        <dl id="listScroll">
          <li
            v-for="(item, index) in listData"
            :key="index"
            :class="{ active: infoData.id === item.id&&isEdit ,activeInfo: isEnter === index && !isLeave && tabActiveName === 'DEFAULT' }"
            @mouseenter="enter(index)"
            @mouseleave="leave(index)"
            @click="changeInfo(item)"
          >
            <div
              class="thumbnail"
              @click.stop
            >
              <el-image
                v-if="item.partList && item.partList.length"
                :hide-on-click-modal="true"
                :src="item.partList[0].thumbnail"
                :preview-src-list="
                  item.partList.map((item) => item.thumbnail)
                "
                style="height: 88px"
              />
              <el-image
                v-else-if="item.fileList && item.fileList.length"
                :hide-on-click-modal="true"
                :src="pathFilter(item.fileList[0].url)"
                :preview-src-list="
                  item.fileList.map((item) => pathFilter(item.url))
                "
                style="height: 88px"
              />
              <img
                v-else
                src="../../assets/img/default.png"
              >
            </div>
            <div class="info">
              <div>
                <p class="code">
                  {{ item.orderSn }}
                  <span v-if="item.status==='DRAFT'" class="status">未提交</span>
                </p>
                <h4>{{ item.projectName }}</h4>
                <p class="date">
                  交货日期：{{ item.deliverDate }}
                </p>
              </div>
              <el-icon
                v-if=" isEnter === index && !isLeave && tabActiveName === 'DEFAULT'"
                class="el-icon-delete del"
                @click="delOrder(item)"
              >
                <Delete />
              </el-icon>
            </div>
          </li>
        </dl>
      </div>
    </div>
    <!-- 删除 -->
    <el-dialog
      v-model="delFlag"
      title="删除"
      width="480px"
    >
      <div class="tips">
        <div class="del">
          <el-icon>
            <Delete />
          </el-icon>
        </div>
        <div>
          <div>确认要删除订单吗？</div>
          <div>删除之后不可恢复，请谨慎处理</div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="delFlag = false">取消</el-button>
          <el-button
            type="primary"
            @click="realDelOrder"
          >确定</el-button>
        </span>
      </template>
    </el-dialog>
  </aside>
  <div class="content">
    <div class="banner">
      <li v-if="isEdit">
        <span style="font-weight:700;">{{ infoData.orderSn }}</span>
        <h4 v-if="infoData.customer">
          {{ infoData.projectName }}
        </h4>
      </li>
      <div v-else>
        新建订单
      </div>
    </div>
    <div
      v-if="infoData.id"
      class="steps"
    >
      <li
        v-for="(item, index) in navData"
        :key="index"
        :class="{ success: item.status&&isEdit, active: item.type === navStatus }"
        @click="switchStatus(item)"
      >
        <el-icon v-if="item.type === navStatus">
          <CircleCheckFilled />
        </el-icon>
        <el-icon v-else-if="item.status &&isEdit" color="#1890ff">
          <SuccessFilled />
        </el-icon>
        <i v-if="!item.status||(!isEdit&&item.type!=='detail')" class="num-i">{{ index + 1 }}</i>
        <el-popover
          v-if="quoteStatus && quoteStatus === 'DEFAULT' && item.popover && item.type === navStatus"
          ref="popover"
          placement="top"
          :width="364"
          :visible="true"
        >
          <template #reference>
            <div>{{ item.title }}</div>
          </template>
          <div class="popover">
            <el-icon><WarningFilled /></el-icon>本价格为系统预估价格，最终报价请等待工厂确认
          </div>
        </el-popover>
        <template v-else>
          {{ item.title }}
        </template>
      </li>
    </div>
    <div :class="{'content-box':true,'height-box':listData.length===0}">
      <div
        v-if="!infoData.id&&!createOrderClick"
        class="defaultPage"
      >
        暂无数据
      </div>
      <!-- 创建订单 -->
      <Create
        v-if="createOrderClick"
        v-model:orderStatus="orderStatus"
        v-model:createOrderClick="createOrderClick"
        v-model:orderId="orderId"
        v-model:feed-list="feedList"
        v-model:incoming-list="incomingList"
        v-model:isEdit="isEdit"
        v-model:navStatus="navStatus"
        style="height:calc(100% - 38px);overflow:auto;"
        @getList="getList"
        @showInfo="showInfo"
        @autoQuoteFun="autoQuoteFun"
      />
      <!-- 订单详情页 -->
      <div
        v-if="navStatus === 'detail' && infoData.id && !createOrderClick"
        class="detail"
      >
        <div class="box-item">
          <h4>图纸</h4>
          <div class="item drawing">
            <div v-for="(item,index) in feedList" :key="item" class="files">
              <div class="demo-image__preview">
                <el-image
                  style="width: 100px; height: 88px"
                  :src="item.thumbnailUrl"
                  :preview-src-list="feedList.map(item=>item.thumbnailUrl)"
                  :initial-index="index"
                  :hide-on-click-modal="true"
                  fit="fit"
                />
              </div>
              <span>
                <h5>{{ item.fileName }}</h5>
                <label class="supply">供料</label>
              </span>
            </div>
            <div v-for="(item,index) in incomingList" :key="item" class="files">
              <div class="demo-image__preview">
                <el-image
                  style="width: 100px; height: 88px"
                  :src="item.thumbnailUrl"
                  :preview-src-list="incomingList.map(item=>item.thumbnailUrl)"
                  :initial-index="index"
                  :hide-on-click-modal="true"
                  fit="cover"
                />
              </div>
              <span>
                <h5>{{ item.fileName }}</h5>
                <label class="incoming">来料</label>
              </span>
            </div>
          </div>
        </div>
        <div class="box-item">
          <h4>
            订单详情
            <span
              v-if="infoData.status === 'DRAFT'"
              class="edit"
              @click="editOrder"
            >
              <i class="icon-edit" />编辑
            </span>
          </h4>
          <div class="item">
            <ul>
              <li><label>订单编号：</label>{{ infoData.orderSn }}</li>
              <li><label>项目名称：</label>{{ infoData.projectName }}</li>
              <li><label>交货日期：</label>{{ infoData.deliverDate }}</li>
            </ul>
            <ul>
              <li><label>接单员：</label>{{ infoData.creator.realName }}</li>
              <li><label>创建日期：</label>{{ infoData.createTime }}</li>
              <li><label>定货日期：</label>{{ infoData.orderDate }}</li>
            </ul>
            <ul>
              <li class="fileList">
                <label>附件：</label>
                <div>
                  <el-image-viewer
                    v-if="imgShow"
                    :url-list="previewList"
                    @close="()=>{imgShow=false}"
                  />
                  <img
                    v-for="(item, index) in infoData.fileList"
                    :key="index"
                    class="img"
                    :src="pathFilter(item.url)"
                    @click="imgClick(item)"
                  >
                </div>
              </li>
            </ul>
            <ul>
              <li><label>订单备注：</label> {{ infoData.remarks }}</li>
            </ul>
          </div>
          <h4>收货地址</h4>
          <div class="item">
            <ul class="inline">
              <li v-if="infoData.clientAddressSnapshot">
                <template v-if="infoData.clientAddressSnapshot.contacts">
                  {{ infoData.clientAddressSnapshot.contacts }}  {{ infoData.clientAddressSnapshot.phone?'| '+infoData.clientAddressSnapshot.phone :'' }}<span />
                </template>
                {{ infoData.clientAddressSnapshot.addressDetail? infoData.clientAddressSnapshot.addressDetail:'' }}
              </li>
            </ul>
          </div>
        </div>
        <div
          class="box-item"
        >
          <h4>订单日志</h4>
          <div class="item timeline">
            <el-timeline>
              <el-timeline-item
                v-for="(activity, index) in activities"
                :key="index"
                color="#C0C4CC"
              >
                <p class="date">
                  {{
                    activity.operator.realName
                  }}
                  {{ activity.createTime }}
                </p>
                <p>{{ activity.desc }}</p>
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </div>
      <!-- 报价 -->
      <div
        v-if="navStatus === 'offer' && infoData.id&&!createOrderClick"
        style="height: calc(100% - 38px);"
      >
        <!-- 整板报价及明细 -->
        <Board
          ref="boardRef"
          v-model:orderStatus="orderStatus"
          v-model:order-id="overallOrderId"
          v-model:navStatus="navStatus"
          v-model:createOrderClick="createOrderClick"
          v-model:is-part="isPart"
          @showInfo="showInfo"
          @reUploadFun="reUploadFun"
          @quoteStatus="getQuoteStatus"
        />
      </div>
      <!-- 加工 -->
      <div
        v-if="navStatus === 'process' && infoData.id"
        style="height: 100%;"
      >
        <Process ref="processRef" v-model:orderId="overallOrderId" />
      </div>
    </div>
  </div>
  <Print
    v-if="printView"
    :data="infoData"
    :print-type="printView"
    @hidePrintView="printView = false"
  />
</template>
<script setup>
import ElImageViewer from 'element-plus/lib/components/image-viewer'
import Board from '@/components/offer/board/index.vue'
import Print from '@/components/print/index'
import Create from '@/components/create/index'
import Process from '@/components/process/index'
import { getUserInfo } from '@/apis/login'
import {
  orderList,
  orderDetail,
  orderLog,
  delOrderPort,
  cadList,
  orderQuoteFormType
} from '../../apis/order'
import { ref, reactive, onMounted, watch, nextTick } from 'vue'
import { ElMessage, ElLoading } from 'element-plus' //
import { Delete, ArrowDown, Search, CircleCheckFilled, SuccessFilled } from '@element-plus/icons-vue'
import { WarningFilled } from '@element-plus/icons'
// 图片路径过滤
const suffix = ['jpg', 'png', 'gif', 'svg', 'jpeg', 'JPG']
const fileSuffix = ['pdf', 'word', 'xlsx']
const pathFilter = (path) => {
  const str = path.split('.')
  const fileType = str[str.length - 1].split('?')[0]
  // 保存图片后缀
  if (suffix.includes(fileType)) {
    return path
  } else if (fileSuffix.includes(fileType)) {
    return require('@/assets/img/word.svg')
  } else {
    return require('@/assets/img/default.png')
  }
}
// 查看图片
const imgShow = ref(false)
const previewList = ref([])
function imgClick(item) {
  const str = item.url.split('.')
  const fileType = str[str.length - 1].split('?')[0]
  if (fileSuffix.includes(fileType)) {
    window.open(item.url)
  } else {
    imgShow.value = true
    previewList.value = [item.url]
  }
}
// 图纸
const feedList = ref([])
const incomingList = ref([])
// 重新上传
const reUploadValue = ref(false)
const reUploadFun = () => {
  navStatus.value = 'detail'
  reUploadValue.value = true
}
// 打印
const printView = ref(false)
// 新建订单
const createOrderClick = ref(false)
const isEdit = ref(true)
const createOrder = () => {
  navStatus.value = 'detail'
  orderId.value = ''
  orderStatus.value = 'DRAFT'
  isEdit.value = false
  createOrderClick.value = false
  setTimeout(() => {
    createOrderClick.value = true
  })
}
const scrollLock = ref(false)
onMounted(() => {
  // 订单列表下拉加载
  const listDom = document.getElementById('listScroll')
  if (!listDom) return
  listDom.onscroll = () => {
    const total = statusTotal.value[tabActiveName.value]
    if (listDom.scrollHeight - listDom.scrollTop - listDom.clientHeight < 100) {
      if (!scrollLock.value && (page.value.pageNumber + 1) * page.value.pageSize < total) {
        page.value.pageNumber++
        scrollLock.value = true
        getList('scroll', 'detail', overallOrderId.value)
      }
    }
  }
})
// 搜索客户
const datePick = ref(null) // 将日期组件绑定到input
const icons = ref('ArrowDown') // 不同选择日状态icon
const showDates = ref('') // 展示选择后日期
const showDate = () => {
  // 将日期组件绑定到input
  datePick.value.focus()
}
const changeDate = () => {
  // 日期组件改变时
  search()
  showDates.value = `${searchDate.value[0]} - ${searchDate.value[1]}`
}
const clearDate = () => {
  // 展示日期点击取消后
  searchDate.value = ''
  search()
  icons.value = 'el-icon-arrow-down'
}
const blurDate = () => {
  // 日期组件失去焦点
  icons.value = 'el-icon-arrow-down'
}
const onFocus = () => {
  // 日期组件获取焦点
  icons.value = 'el-icon-arrow-up'
}

// 搜索
const searchDate = ref('')
const search = (isCreate) => {
  if (searchDate.value) {
    page.value.startDate = searchDate.value[0].replace(/\./g, '-')
    page.value.endDate = searchDate.value[1].replace(/\./g, '-')
  } else {
    page.value.startDate = ''
    page.value.endDate = ''
  }
  tabClick(isCreate)
}
// 订单状态
const tabActiveName = ref('DEFAULT')
const tabData = reactive([
  {
    title: '待确认',
    status: 'DEFAULT'
  },
  {
    title: '加工中',
    status: 'PROCESSING'
  },
  {
    title: '已完成',
    status: 'COMPLETED'
  }
])
// 切换订单状态
const tabClick = (tab) => {
  page.value.pageNumber = 0
  listData.value.length = 0
  infoData.value = {}
  scrollLock.value = true
  createOrderClick.value = false
  if (tab) tabActiveName.value = tab.paneName
  getList()
}
// 客户信息
const customer = ref({})
// 订单列表
const listData = ref([])
const statusTotal = ref({})
const page = ref({
  searchText: '',
  startDate: '',
  endDate: '',
  pageSize: 20,
  pageNumber: 0,
  status: 'DEFAULT'
})
const getUserInfoFun = () => {
  getUserInfo().then((res) => {
    customer.value = res.data
    getList()
  })
}
const searchFun = () => {
  page.value.pageNumber = 0
  getList()
}
const getList = (isScroll, type = 'detail', orderId) => {
  const loading = ElLoading.service({
    lock: true,
    text: 'loading',
    background: 'rgba(0, 0, 0, 0.7)'
  })
  const { orgCode, id } = customer.value.metadata
  page.value.orgCode = orgCode
  page.value.customerId = id
  page.value.status = tabActiveName.value
  orderList({ ...page.value }).then((res) => {
    isEdit.value = true
    createOrderClick.value = false
    if (autoQuoteIsNew.value || isScroll === 'scroll') isScroll === 'scroll' ? listData.value.push(...res.data.items) : listData.value = res.data.items
    // 订单对应状态数量
    statusTotal.value = {
      'DEFAULT': res.data.total['DEFAULT'] + res.data.total['DRAFT'],
      'PROCESSING': res.data.total['PROCESSING'] + res.data.total['NESTING'],
      'COMPLETED': res.data.total['IN_DELIVER'] + res.data.total['IN_STOCK']
    }
    if (listData.value.length) {
      showInfo({ id: orderId || listData.value[0].id }, type)
    }
    loading.close()
    scrollLock.value = false
  }).catch(() => {
    loading.close()
  })
}
getUserInfoFun()
// 订单详情
const infoData = ref({})
// 时间线
const activities = reactive([])
const orderStatus = ref('')
const changeInfo = (item) => {
  createOrderClick.value = false
  navStatus.value = 'detail'
  showInfo(item)
  quoteStatus.value = false
  isPart.value = ''
  isEdit.value = true
}
const overallOrderId = ref('')// 全局订单id
// 左侧订单信息展示
// eslint-disable-next-line space-before-function-paren
const showInfo = async (item, type = '') => {
  if (!item.id) return
  overallOrderId.value = item.id
  if (type) navStatus.value = type
  // 订单详情
  // eslint-disable-next-line space-before-function-paren
  orderDetail(item.id, {}).then(async (res) => {
    // isEdit.value = true
    infoData.value = res.data
    infoData.value.clientAddressSnapshot = JSON.parse(infoData.value.clientAddressSnapshot)
    orderStatus.value = res.data.status
    if (!infoData.value.customer.title) {
      navData.value = navData.value.map((item, index) => {
        if (index === 0 || index === 1) {
          item.status = 1
        } else {
          item.status = 0
        }
        return item
      })
      return
    }
  })
  // 日志
  activities.length = 0
  orderLog(item.id).then((res) => {
    activities.push(...res.data)
  })
  // 获取当前订单和默认的报价类型
  orderQuoteFormType(item.id).then(res => {
    navData.value[1].status = res.data.quoteTypeId ? 1 : 0
    const arr = ['DEFAULT', 'DRAFT']
    navData.value[2].status = !arr.includes(tabActiveName.value) ? 1 : 0
  })
  // 图纸
  await getCadList()
  await getCadList('CUSTOMER')
}
// 获取图纸
const getCadList = (type = 'COMPANY') => {
  cadList(overallOrderId.value, {
    pageSize: 1000,
    pageNumber: 0,
    suppliedType: type
  }).then((res) => {
    if (type !== 'NONE') type === 'COMPANY' ? feedList.value = res.data.items : incomingList.value = res.data.items
  })
}
// 订单状态
const navData = ref([
  {
    title: '订单',
    type: 'detail',
    status: 1
  },
  {
    title: '报价',
    type: 'offer',
    popover: true,
    status: 1
  },
  {
    title: '加工',
    type: 'process',
    status: 1
  }
])
// 自动报价
const isPart = ref('')
const autoQuoteIsNew = ref(true) // 为true则获取订单列表时会修改列表数据，反之不修改
const autoQuoteFun = (type, part, id) => {
  isPart.value = part
  autoQuoteIsNew.value = type === 'new'
  if (type === 'new') page.value.pageNumber = 0
  getList('', 'offer', id)
}
// 切换订单详情流程
const navStatus = ref('detail')
const boardRef = ref(null)
const processRef = ref(null)
watch(() => navStatus.value, (newValue) => {
  createOrderClick.value = false
  switch (newValue) {
    case 'offer':
      nextTick(() => {
        boardRef.value.getCustomerOrderQuoteDetail(isPart.value)
      })
      break
    case 'process':
      nextTick(() => {
        processRef.value.getOrderProcessDetail(overallOrderId.value)
      })
      break
    case 'detail':
      if (reUploadValue.value) {
        orderId.value = overallOrderId.value
        createOrderClick.value = true
        reUploadValue.value = false
      } else {
        showInfo({ id: overallOrderId.value })
      }
      break
  }
}, {
  immediate: true, // 立即执行
  deep: true // 深度监听
})
const switchStatus = (item) => {
  if (!isEdit.value) return
  if (item.status) {
    navStatus.value = item.type
  }
}
// 鼠标移入移除
const isEnter = ref(0)
const isLeave = ref(true)
const enter = (e) => {
  isEnter.value = e
  isLeave.value = false
}
const leave = (e) => {
  isEnter.value = e
  isLeave.value = true
}
// 删除订单
const delFlag = ref(false) // 删除订单弹窗
const orderId = ref() // 将要删除的订单id
const delOrder = (e) => {
  delFlag.value = true
  orderId.value = e
}
// 编辑订单
const editOrder = () => {
  createOrderClick.value = true
  orderId.value = overallOrderId.value
  isEdit.value = true
}
// 删除订单
const realDelOrder = () => {
  page.value = {
    ...page.value,
    pageNumber: 0
  }
  delOrderPort(orderId.value.id)
    .then(() => {
      page.value.pageNumber = 0
      listData.value.length = 0
      infoData.value = {}
      autoQuoteIsNew.value = true
      getList()
      ElMessage.success('删除成功')
      delFlag.value = false
    })
    .catch(() => {
      delFlag.value = false
    })
}
// 报价状态
const quoteStatus = ref(false)
const getQuoteStatus = (data) => {
  quoteStatus.value = data
}
</script>
<style lang="less" src="./index.less" scoped></style>
