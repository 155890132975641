<template>
  <Head rout="order" />
  <router-view />
</template>
<script>
import Head from '@/components/layout/Head.vue'
export default {
  components: {
    Head
  }
}
</script>

<style lang="less" src="@/assets/css/common.less"></style>
