import { createStore } from 'vuex'
import presistedState from 'vuex-persistedstate'
export default createStore({
  state: {
    userInfo: {},
    orgInfo: {},
    authorityList: [],
    customerInfo: [],
    recognizeResultList: [],
    canGetQuote: false
  },
  mutations: {
    setUserInfo(state, data) {
      state.userInfo = data
    },
    setOrgInfo(state, data) {
      state.orgInfo = data
    },
    setAuthority(state, data) {
      state.authorityList = data
    },
    setCustomerInfo(state, data) {
      state.customerInfo = data
    },
    setRecognizeResultList(state, data) {
      state.recognizeResultList = data
    },
    setCanGetQuote(state, data) {
      state.canGetQuote = data
    }
  },
  plugins: [presistedState({ storage: window.sessionStorage })]
})
