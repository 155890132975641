<template>
  <div class="create">
    <div class="box-item">
      <h4>图纸 <span>*最多可上传500个图纸</span></h4>
      <dl>
        <ul>
          <span>
            <h5>供料</h5>
            <el-tooltip
              content="工厂提供物料进行加工"
              placement="bottom-start"
              effect="customized"
            >
              <img src="@/assets/img/hint.svg">
            </el-tooltip>
          </span>
          <el-upload
            class="upload-demo"
            :drag="true"
            accept=".dxf,.lxds,.dwg,.lxd"
            :http-request="feedUploadFile"
            :show-file-list="false"
            multiple
          >
            <i class="icon-upload1" />
            <div class="el-upload__text">
              <p class="tips">
                点击上传或拖至此处上传
              </p>
              <p>文件支持dxf、dwg、lxds、lxd</p>
            </div>
          </el-upload>
          <div v-infinite-scroll="getCompanyCadList" :infinite-scroll-disabled="cadCompanyPage.scrollDisabled" class="item-list">
            <div v-for="item in feedList" :key="item" class="files">
              <img :src="item.thumbnailUrl">
              <h5>{{ item.fileName }}</h5>
              <div class="del" @click="delFun(item,'COMPANY')">
                <el-icon>
                  <Delete />
                </el-icon>
              </div>
            </div>
          </div>
        </ul>
        <ul>
          <span>
            <h5>来料</h5>
            <el-tooltip
              content="自带物料去工厂加工"
              placement="bottom-start"
              effect="customized"
            >
              <img src="@/assets/img/hint.svg">
            </el-tooltip>
          </span>
          <el-upload
            class="upload-demo"
            drag
            accept=".dxf,.lxds,.dwg,.lxd"
            :http-request="incomingUploadFile"
            :show-file-list="false"
            multiple
          >
            <i class="icon-upload1" />
            <div class="el-upload__text">
              <p class="tips">
                点击上传或拖至此处上传
              </p>
              <p>文件支持dxf、dwg、lxds、lxd</p>
            </div>
          </el-upload>
          <div v-infinite-scroll="getCustomerCadList" :infinite-scroll-disabled="cadCustomerPage.scrollDisabled" class="item-list">
            <div v-for="item in incomingList" :key="item" class="files">
              <img :src="item.thumbnailUrl">
              <h5>{{ item.fileName }}</h5>
              <div class="del" @click="delFun(item,'CUSTOMER')">
                <el-icon>
                  <Delete />
                </el-icon>
              </div>
            </div>
          </div>
        </ul>
      </dl>
    </div>
    <div class="box-item">
      <h4>订单信息</h4>
      <ul class="orderInfo">
        <label>基本信息：</label>
        <li>
          <label>项目名称</label>
          <el-input
            v-model="form.projectName"
            class="searchContent"
            clearable
            placeholder="请输入项目名称"
            maxlength="200"
          />
        </li>
        <li>
          <label><span style="color:red;">*</span> 交货日期</label>
          <el-date-picker
            v-model="form.deliverDate"
            value-format="YYYY-MM-DD"
            type="date"
            placeholder="请选择日期"
          />
        </li>
        <li>
          <div style="width: 100%;border-top:1px solid #eceef6;border-bottom:1px solid #eceef6" />
        </li>
      </ul>
      <ul class="remark">
        <label>备注：</label>
        <div class="remarkDetail">
          <el-input
            v-model="form.remarks"
            :autosize="{ minRows: 3.5 }"
            clearable
            style="font-size:14px;"
            placeholder="请输入备注"
            maxlength="5000"
            show-word-limit
            type="textarea"
          />
        </div>
      </ul>
      <ul class="fileList">
        <label>附件：<span class="label-span">文件支持表格xlsx、图片jpg/png等、文档word/pdf等</span></label>
        <div class="imgList">
          <span v-for="item in attachmentIdList" :key="item">
            <img :src="pathFilter(item.url)">
            <el-icon class="imgList-icon" @click="removeFile(item)"><CloseBold /></el-icon>
          </span>
          <div class="upload">
            <el-upload
              v-if="attachmentIdList.length<8"
              list-type="picture-card"
              class="elUpload"
              :http-request="createUploadFile"
              :show-file-list="false"
            >
              <el-icon><Plus /></el-icon>
            </el-upload>
          </div>
        </div>
      </ul>
      <ul class="address">
        <label>收货地址：<ls-button text @click="selectAddress">选择</ls-button> </label>
        <dl>
          <li>
            <label style="width: 20%">详细地址</label>
            <div style="width: 100%">
              {{ form.addressDetail }}
            </div>
          </li>
          <li>
            <label>收货人</label>
            <div>
              {{ form.contacts }}
            </div>
          </li>
          <li style="width: 100%">
            <label>联系电话</label>
            <div>
              {{ form.phone }}
            </div>
          </li>
        </dl>
      </ul>
    </div>
    <div class="footer">
      <ls-button plain style="width: 88px; height: 32px; margin-right: 8px;" @click="emits('update:createOrderClick', false);emits('update:isEdit', true)">
        取消
      </ls-button>
      <ls-button primary empty style="width: 88px; height: 32px; margin-right: 8px;" @click="save">
        保存
      </ls-button>
      <ls-button style="margin-right: 8px;background: #01CC75; border: 1px solid #01CC75" @click="getPriceClick">
        获取预估价格
      </ls-button>
      <ls-button primary style=" height: 32px;" @click="submitOrder">
        提交至工厂
      </ls-button>
    </div>
    <Address ref="address" @getAddressFun="getAddress" />
    <!-- 图纸识别失败-->
    <el-dialog
      title="提示"
      width="480px"
    >
      <div class="tip" style="margin-top: 24px">
        <div class="icon">
          <i class="el-icon-warning" />
        </div>
        <div>
          <div style="color: #303133; font-size: 16px; margin-bottom: 4px">
            图纸识别失败，请处理图纸后重新上传
          </div>
          <div>这里显示图纸识别失败的原因这里显示图纸识别失败的原因</div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer" style="margin: -24px">
          <el-button
            type="text"
          >知道了</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 排样失败-->
    <el-dialog
      title="提示"
      width="480px"
    >
      <div class="tip" style="margin-top: 24px">
        <div class="icon">
          <i class="el-icon-warning" />
        </div>
        <div style="color: #303133; font-size: 16px; margin-bottom: 4px">
          <div>排样失败，请联系工厂修改相关配置</div>
          <div>这里显示排样失败的原因这里显示排样失败的原因</div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer" style="margin: -24px">
          <el-button
            type="text"
          >知道了</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 删除图纸 -->
    <el-dialog
      v-model="delDrawings"
      title="提示"
      width="480px"
    >
      <div class="tips">
        <div class="del">
          <el-icon>
            <Delete />
          </el-icon>
        </div>
        <div>
          <div>确定要删除该图纸？</div>
          <div>删除之后不可恢复，请谨慎处理</div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="delDrawings = false">取消</el-button>
          <el-button
            type="primary"
            @click="delOrBack"
          >确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 图层映射 -->
    <el-dialog
      v-model="layerDialogVisible"
      title="上传成功，请设置图纸映射                                          "
      custom-class="layerDialog"
      :close-on-click-modal="false"
      width="740px"
      @open="getPartsMap"
    >
      <div class="part">
        <div style="display:flex;justify-content: space-between;">
          <div style="display:flex;align-items: center;">
            <span style="color:#fd5555;">*</span> 映射方式
            <el-select v-model="layerType" style="margin-left:5px;" size="mini" placeholder="请选择" @change="layerTypeChange">
              <el-option
                v-for="item in layerData"
                :key="item.mapType"
                :label="item.label"
                :value="item.mapType"
              />
            </el-select>
          </div>
          <ls-button v-if="layerType==='MIXED'" empty style="padding:5px 10px;margin:0 8px 0 12px;" @click="addLayerFun">
            <el-icon size="12px" style="margin-right:4px;">
              <Plus />
            </el-icon>添加
          </ls-button>
        </div>
        <div class="table-list">
          <ls-table
            id="layer-table"
            ref="layerTableDataRef"
            :key="priceTableKey"
            v-bind="layerTableData"
          >
            <template #color="{scope}">
              <div v-if="layerType!=='MIXED'" class="pic-color" :style="{background: scope.row.backgroundColor}" />
              <div v-else class="select-div">
                <div v-if="scope.row.colorID===-1" class="select-color" style="background:#fff">
                  所有
                </div>
                <div v-else class="pic-color select-color" :style="{background: scope.row.backgroundColor}" />
                <el-select size="small" placeholder="" @change="changeFun($event,scope.row)">
                  <el-option
                    v-for="item in colorList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                    <div v-if="!item.value">
                      所有
                    </div>
                    <div v-else class="pic-color" :style="{'background': item.label,'margin': '5px 0','height':'25px'}" />
                  </el-option>
                </el-select>
              </div>
            </template>
            <template #objectType="{scope}">
              {{ objectTypeObj[scope.row.objectType] }}
            </template>
            <template #layerName="{scope}">
              <div v-if="layerType!=='MIXED'">
                {{ scope.row.layerName }}
              </div>
              <el-select v-else v-model="scope.row.layerName" size="small" placeholder="请选择">
                <el-option
                  v-for="item in layerNameList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </template>
            <template #processMode="{scope}">
              <el-select v-model="scope.row.processMode" size="small" placeholder="请选择" style="width: 100%;">
                <el-option
                  v-for="item in processModeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </template>
            <template #action="{scope}">
              <ls-button text style="color:#fd5550" @click="deleteFun(scope.row)">
                删除
              </ls-button>
            </template>
            <template #sort>
              <i class="icon-move handle-cell" style="font-size: 14px;color:#1890ff;cursor: pointer;" />
            </template>
          </ls-table>
        </div>
      </div>
      <template #footer>
        <el-button @click="layerDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="autoQuote">
          确 定
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script setup >
import {
  createNewOrder, createOrderFile, cadToken, cadList, delFileCad, orderQuoteFormAutoQuote, orderQuoteFormAutoQuoteResult, orderQuoteFormTypeList,
  clientInfo, orderDetail, editOrderInfo, customerOrderSubmit, getRecognizePartsMap, getRecognizePartsMapResult, orderQuoteFormType
} from '@/apis/order'
import { upload } from '@/utils/request'
import { getFilePath } from '@/apis/common'
import { defineProps, defineEmits, ref, watch, nextTick } from 'vue'
import { ElMessage } from 'element-plus'
import { Delete, Plus, CloseBold } from '@element-plus/icons-vue'
import Store from '@/store/index'
import Address from '@/components/address'
import { ElLoading } from 'element-plus'
const props = defineProps({
  createOrderClick: { type: Boolean, default: true },
  orderId: { type: String, default: '' },
  isEdit: { type: Boolean, default: true },
  navStatus: { type: String, default: '' },
  orderStatus: { type: String, default: '' }
})
const emits = defineEmits(['showInfo', 'getList', 'update:createOrderClick', 'update:isEdit', 'update:navStatus', 'update:orderId', 'autoQuoteFun'])
const attachmentIdList = ref([])
const form = ref({
  deliverDate: '',
  remarks: '',
  projectName: '',
  clientAddressSnapshot: {}
})
// 创建订单上传附件
const createUploadFile = (file) => {
  createOrderFile({ 'fileName': file.file.name, 'suppliedType': 'NONE' })
    .then((res) => {
      const { data } = res
      upload(file.file, data, () => {
        getFilePath(data.id).then(({ data: path }) => {
          if (attachmentIdList.value.length >= 8) {
            ElMessage.warning('附件超过最大数量')
            return
          }
          attachmentIdList.value.push({
            id: data.id,
            url: path
          })
        })
      })
    })
    .catch(({ data: res }) => {
      ElMessage.error(res.msg)
    })
}
// 图片地址过滤
const suffix = ['jpg', 'png', 'gif', 'svg', 'jpeg', 'JPG']
const fileSuffix = ['pdf', 'word', 'xlsx']
const pathFilter = (path) => {
  const str = path.split('.')
  const fileType = str[str.length - 1].split('?')[0]
  // 保存图片后缀
  if (suffix.includes(fileType)) {
    return path
  } else if (fileSuffix.includes(fileType)) {
    return require('@/assets/img/word.svg')
  } else {
    return require('@/assets/img/default.png')
  }
}
// 删除附件
const removeFile = (item) => {
  attachmentIdList.value.forEach((obj, index) => {
    if (item.id === obj.id) {
      attachmentIdList.value.splice(index, 1)
    }
  })
}
// 编辑订单上传供料图纸
const feedUploadFile = (file) => {
  cadToken({ 'fileName': file.file.name, 'suppliedType': 'COMPANY', orderId: props.orderId })
    .then((res) => {
      upload(file.file, res.data, () => {
        if (feedList.value.length + incomingList.value.length >= 500) {
          ElMessage.warning('最多只可上传500个图纸！')
          return
        }
        feedList.value.push({
          id: res.data.id,
          thumbnailUrl: require('@/assets/img/default.png'),
          fileName: file.file.name
        })
      })
    })
}
// 编辑订单上传来料图纸
const incomingUploadFile = (file) => {
  cadToken({ 'fileName': file.file.name, 'suppliedType': 'CUSTOMER', orderId: props.orderId })
    .then((res) => {
      upload(file.file, res.data, () => {
        if (feedList.value.length + incomingList.value.length >= 500) {
          ElMessage.warning('最多只可上传500个图纸！')
          return
        }
        incomingList.value.push({
          id: res.data.id,
          thumbnailUrl: require('@/assets/img/default.png'),
          fileName: file.file.name
        })
      })
    })
}
const feedList = ref([])
const incomingList = ref([])
const companyTimer = ref()
const customerTimer = ref()
// 获取图纸列表
const cadCompanyPage = ref({
  pageSize: 10,
  pageNumber: 0,
  suppliedType: 'COMPANY',
  scrollDisabled: true
})
const cadCustomerPage = ref({
  pageSize: 10,
  pageNumber: 0,
  suppliedType: 'CUSTOMER',
  scrollDisabled: true
})
const getCompanyCadList = () => {
  if (!props.orderId) {
    feedList.value = []
    incomingList.value = []
    return
  }
  if (companyTimer.value) return
  companyTimer.value = setTimeout(() => {
    cadList(props.orderId, {
      ...cadCompanyPage.value
    }).then((res) => {
      feedList.value.push(...res.data.items)
      cadCompanyPage.value.scrollDisabled = feedList.value.length >= res.data.total
      cadCompanyPage.value.pageNumber++
      companyTimer.value = null
    })
  })
}
function getCustomerCadList() {
  if (!props.orderId) {
    feedList.value = []
    incomingList.value = []
    return
  }
  if (customerTimer.value) return
  customerTimer.value = setTimeout(() => {
    cadList(props.orderId, {
      ...cadCustomerPage.value
    }).then((res) => {
      incomingList.value.push(...res.data.items)
      cadCustomerPage.value.scrollDisabled = incomingList.value.length >= res.data.total
      cadCustomerPage.value.pageNumber++
      customerTimer.value = null
    })
  })
}
// 删除图纸
const delDrawings = ref(false)
const drawingId = ref('')
const delType = ref('')
const delFun = (item, type) => {
  delDrawings.value = true
  drawingId.value = item.id
  delType.value = type
}
// 确认删除
const delOrBack = () => {
  if (!props.orderId) {
    if (delType.value === 'COMPANY') {
      feedList.value.forEach((obj, index) => {
        if (drawingId.value === obj.id) {
          feedList.value.splice(index, 1)
        }
      })
    } else {
      incomingList.value.forEach((obj, index) => {
        if (drawingId.value === obj.id) {
          incomingList.value.splice(index, 1)
        }
      })
    }
    delDrawings.value = false
    return
  }
  delFileCad(drawingId.value)
    .then(() => {
      delDrawings.value = false
      ElMessage.success('删除成功')
      delType.value === 'COMPANY' ? cadCompanyPage.value.pageNumber = 0 : cadCustomerPage.value.pageNumber = 0
      delType.value === 'COMPANY' ? feedList.value = [] : incomingList.value = []
      delType.value === 'COMPANY' ? getCompanyCadList() : getCustomerCadList()
    })
    .catch((res) => {
      ElMessage.error(res.msg)
    })
}
// 保存订单
const save = (type, tag) => {
  return new Promise(resolve => {
    if (!form.value.deliverDate) {
      ElMessage.error('请选择交货日期！')
      return
    }
    const feedArr = feedList.value.map(item => item.id)
    const incomingArr = incomingList.value.map(item => item.id)
    const attrArr = attachmentIdList.value.map(item => item.id)
    form.value.attachmentIdList = attrArr.concat(feedArr, incomingArr)
    if (props.orderId || newOrderId.value || tag) {
      editOrderInfo(props.orderId || newOrderId.value, form.value).then(() => {
        if (type !== 'autoQuote') {
          emits('showInfo', { id: props.orderId || newOrderId.value })
          emits('update:createOrderClick', false)
          ElMessage.success('订单保存成功')
        }
        resolve(props.orderId || newOrderId.value)
      }).catch((err) => {
        ElMessage.error(err.data.msg)
      })
    } else {
      createNewOrder(customer.value.orgCode, customer.value.id, form.value).then((res) => {
        resolve(res.data)
        if (type !== 'autoQuote') {
          emits('getList')
          emits('update:createOrderClick', false)
          ElMessage.success('订单保存成功')
        }
      }).catch((err) => {
        ElMessage.error(err.data.msg)
      })
    }
  })
}
// 自动报价
const isLoading = ref(false)
const autoQuote = async() => {
  if (feedList.value.length === 0 && incomingList.value.length === 0) {
    ElMessage.warning('请先上传图纸！')
    return
  }
  newOrderId.value = await save('autoQuote', true)
  const quoteFormType = await getQuoteFormType(newOrderId.value)
  const json = {
    mapType: layerType.value,
    path: layerData.value.filter(item => item.mapType === layerType.value)[0].path,
    params: layerTableData.value.tr
  }
  orderQuoteFormAutoQuote(newOrderId.value, quoteFormType.defaultQuoteTypeId, json).then(() => {
    isLoading.value = ElLoading.service({
      lock: true,
      text: '订单正在自动报价中',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    autoQuoteResult()
  })
}
function autoQuoteResult() {
  const type = !props.orderId ? 'new' : 'old'
  orderQuoteFormAutoQuoteResult(newOrderId.value).then(() => {
    ElMessage.success('自动报价成功')
    emits('autoQuoteFun', type, '', newOrderId.value)
    layerDialogVisible.value = false
    isLoading.value.close()
  }).catch((err) => {
    if (err.data.status === 1) {
      autoQuoteResult()
      return
    }
    emits('autoQuoteFun', type, 'part', newOrderId.value)
    layerDialogVisible.value = false
    isLoading.value.close()
  })
}
// 获取所有报价类型
const quoteFormTypeList = ref([])
const getQuoteFormTypeList = () => {
  orderQuoteFormTypeList({}).then((res) => {
    quoteFormTypeList.value = res.data
  })
}
// 获取当前订单和默认的报价类型
const quoteFormType = ref({})
const getQuoteFormType = (id) => {
  return new Promise(resolve => {
    orderQuoteFormType(id).then((res) => {
      quoteFormType.value = res.data
      resolve(res.data)
    })
  })
}
// 获取订单详情
const clientAddressSnapshot = ref({})
const getOrderDetail = () => {
  orderDetail(props.orderId, {}).then((res) => {
    clientAddressSnapshot.value = res.data.clientAddressSnapshot ? JSON.parse(res.data.clientAddressSnapshot) : {}
    const { deliverDate, remarks, projectName, fileList } = res.data
    const { addressDetail, contacts, phone, addressId } = clientAddressSnapshot.value
    form.value = {
      deliverDate: deliverDate || '',
      remarks: remarks || '',
      projectName: projectName || '',
      addressDetail: addressDetail || '',
      contacts: contacts || '',
      phone: phone || '',
      addressId: addressId || ''
    }
    attachmentIdList.value = fileList
  })
}
// 获取客户地址
const customer = ref({})
const getCustomerAddressList = () => {
  if (!customer.value.clientId) {
    ElMessage.warning('未绑定客户')
    return
  }
  clientInfo({ id: customer.value.clientId }).then((res) => {
    const { contacts, addressDetail, id, phone } = res.data.clientAddressList
    form.value = { contacts, addressDetail, addressId: id, phone }
  })
}
// 选择客户地址
const address = ref()
const selectAddress = () => {
  if (!customer.value.clientId) {
    ElMessage.warning('客户未绑定地址！')
    return
  }
  address.value.getList(customer.value.clientId)
}
const getAddress = (row) => {
  const { id, contacts, phone, addressDetail } = row
  form.value.contacts = contacts
  form.value.addressDetail = addressDetail
  form.value.addressId = id
  form.value.phone = phone
}
// 提交订单
const submitOrder = () => {
  isLoading.value = ElLoading.service({
    lock: true,
    text: 'loading',
    background: 'rgba(0, 0, 0, 0.7)'
  })
  save('autoQuote').then(result => {
    customerOrderSubmit(result).then(() => {
      emits('getList', '', 'detail', result)
      ElMessage.success('提交成功')
    }).finally(() => {
      isLoading.value.close()
    })
  })
}
// 上传图纸映射
const layerDialogVisible = ref(false)
const layerType = ref('')
const layerTableData = ref({
  th: [],
  tr: [],
  total: 0,
  loading: false,
  showSum: false
})
const layerNameList = ref([])
const colorList = ref([])
const layerData = ref([])
const priceTableKey = ref(0)
const objectTypeObj = { 0: '实体', 1: '文字', 2: '混合' }
const processModeList = [
  { value: 0, label: '加工1' },
  { value: 1, label: '加工2' },
  { value: 2, label: '加工3' },
  { value: 3, label: '打标' },
  { value: 4, label: '不加工' },
  { value: 5, label: '删除' }
]
// 混合映射添加数据
function addLayerFun() {
  layerTableData.value.tr.push({ colorID: -1, color: '', layerName: '', objectType: 2, processMode: 0, identification: 'class_' + layerTableData.value.tr.length })
  nextTick(() => {
    priceTableKey.value++
    nextTick(() => {
      sortLayerTable()
    })
  })
}
// 删除图纸映射
function deleteFun(row) {
  layerTableData.value.tr = layerTableData.value.tr.filter(item => item.identification !== row.identification)
}
// 选择颜色
function changeFun(val, row) {
  row.color = val
  row.backgroundColor = colorList.value.filter(item => item.value === val)[0].label
  row.colorID = colorList.value.filter(item => item.value === val)[0].colorID
}
const newOrderId = ref()
async function getPriceClick() {
  if (!Store.state.canGetQuote) {
    ElMessage.warning('您免费报价服务已用完，联系工厂获取更多报价服务')
    return
  }

  if (feedList.value.length === 0 && incomingList.value.length === 0) {
    ElMessage.warning('请先上传图纸！')
    return
  }
  newOrderId.value = await save('autoQuote')
  layerDialogVisible.value = true
}
// 获取图层信息
function getPartsMap() {
  getRecognizePartsMap(newOrderId.value).then(() => {
    isLoading.value = ElLoading.service({
      lock: true,
      text: 'loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    getRecognizePartsMapFun()
  }).catch(() => {
    isLoading.value.close()
  })
}
// 获取图纸映射颜色数据 轮询
function getRecognizePartsMapFun() {
  getRecognizePartsMapResult(newOrderId.value).then(res => {
    const obj = {
      COLOR: '颜色映射',
      LAYER: '图层映射',
      MIXED: '混合映射'
    }
    layerData.value = res.data.map(item => {
      item.label = obj[item.mapType]
      item.params.map((value, index) => {
        if (value.color) {
          let str = value.color.toString(16)
          if (str.length < 6) {
            const sum = 6 - str.length
            for (let index = 0; index < sum; index++) {
              str = '0' + str
            }
          }
          const r = str.substring(4)
          const g = str.substring(2, 4)
          const b = str.substring(0, 2)
          value.backgroundColor = '#' + r + g + b
        }
        value.identification = 'class_' + index
        return value
      })
      return item
    })
    layerType.value = layerData.value[0].mapType
    layerTableData.value.tr = layerData.value.filter(item => item.mapType === layerType.value)[0].params
    nextTick(() => {
      layerTypeChange()
      isLoading.value.close()
    })
  }).catch(err => {
    if (err.data.status === 1) {
      getRecognizePartsMapFun()
      return
    }
    isLoading.value.close()
  })
}
// 图纸映射排序
function sortLayerTable() {
  const ele = document.querySelector('#layer-table tbody')
  window.Sortable.create(ele, {
    animation: 150,
    handle: '.handle-cell',
    onEnd({ oldIndex, newIndex }) {
      const temp = layerTableData.value.tr[oldIndex]
      const tempIndex = layerTableData.value.tr[oldIndex].index
      layerTableData.value.tr[oldIndex].index = layerTableData.value.tr[newIndex].index
      layerTableData.value.tr[newIndex].index = tempIndex
      layerTableData.value.tr.splice(oldIndex, 1, layerTableData.value.tr[newIndex])
      layerTableData.value.tr.splice(newIndex, 1, temp)
      nextTick(() => {
        priceTableKey.value++
        nextTick(() => {
          sortLayerTable()
        })
      })
    }
  })
}
function layerTypeChange() {
  layerTableData.value.tr = layerData.value.filter(item => item.mapType === layerType.value)[0].params
  switch (layerType.value) {
    case 'COLOR':
      layerTableData.value.th = [
        { title: '序号', type: 'seq', width: '56px', align: 'center' },
        { title: '颜色', field: 'color', width: '163px' },
        { title: '类型', field: 'objectType', width: '88px' },
        { title: '实体数', field: 'entityCount', width: '120px' },
        { title: '处理方式', field: 'processMode' }
      ]
      nextTick(() => {
        priceTableKey.value++
      })
      break
    case 'LAYER':
      layerTableData.value.th = [
        { title: '序号', type: 'seq', width: '56px', align: 'center' },
        { title: '图层', field: 'layerName', width: '163px' },
        { title: '类型', field: 'objectType', width: '88px' },
        { title: '实体数', field: 'entityCount', width: '120px' },
        { title: '处理方式', field: 'processMode' }
      ]
      nextTick(() => {
        priceTableKey.value++
      })
      break
    case 'MIXED':
      layerTableData.value.th = [
        { title: '序号', type: 'seq', width: '56px', align: 'center' },
        { title: '颜色', field: 'color', width: '163px' },
        { title: '图层', field: 'layerName', width: '120px' },
        { title: '类型', field: 'objectType', width: '88px' },
        { title: '处理方式', field: 'processMode', 'min-width': '120px' },
        { title: '操作', field: 'action', width: '64px', align: 'center' },
        { title: '顺序', field: 'sort', width: '56px', align: 'center' }
      ]
      layerNameList.value = []
      colorList.value = []
      layerTableData.value.tr.forEach(item => {
        if (layerNameList.value.filter(layer => layer.value === item.layerName).length === 0) layerNameList.value.push({ value: item.layerName, label: item.layerName })
        if (colorList.value.filter(color => color.value === item.color).length === 0) colorList.value.push({ value: item.color, label: item.backgroundColor, colorID: item.colorID })
      })
      layerNameList.value.push({ value: '', label: '所有' })
      colorList.value.push({ value: '', label: '所有', colorID: -1 })
      nextTick(() => {
        sortLayerTable()
      })
      break
  }
}
watch(() => props.createOrderClick, (newValue) => {
  if (newValue) {
    getQuoteFormTypeList()
    customer.value = Store.state.customerInfo
    if (!props.orderId) getCustomerAddressList()
  }
  if (props.orderId) {
    getCompanyCadList()
    getCustomerCadList()
    getQuoteFormType(props.orderId)
    getOrderDetail()
  }
}, {
  immediate: true, // 立即执行
  deep: true // 深度监听
})
</script>
<style lang="less" scoped>
.create {
  width: 100%;
  .box-item {
    border: 1px solid #DCDFE6;
    margin-bottom: 16px;

    h4 {
      display: flex;
      line-height: 48px;
      font-size: 16px;
      background: #F6F7FA;
      border-bottom: 1px solid #E5E7EF;
      padding: 0px 16px;
      >span{
        margin-left: 16px;
        color: #909399;
        font-size: 14px;
        font-weight: normal;
      }
    }
    dl {
      display: flex;

      ul {
        flex: 1;
        padding-bottom: 16px;
        & > span {
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          padding: 12px 16px 0 16px;

          h5 {
            line-height: 22px;
            font-size: 16px;

            &::before {
              content: "";
              display: inline-block;
              width: 8px;
              height: 16px;
              margin: 0 8px -2px 0;
              border-radius: 4px;
              background: #1890ff;
            }
          }

          img {
            width: 20px;
            height: 20px;
            margin-left: 4px;
            cursor: pointer;
          }
        }

        .upload-demo {
          padding: 0 16px;

          &:deep(.el-upload) {
            width: 100%;

            .el-upload-dragger {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 78px;
              border-color: #D6D6D6;

              .icon-upload1 {
                font-size: 38px;
              }

              .el-upload__text {
                margin-left: 24px;
                font-size: 12px;
                color: #B2BAC4;

                .tips {
                  font-size: 14px;
                  color: #1890FF;
                  margin-bottom: 8px;
                  font-weight: bold;
                }
              }
            }
          }
        }

        .item-list {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          height: 262px;
          overflow-y: auto;
          padding: 0 15px;

        .files {
          display: flex;
          justify-content: space-between;
          margin-top: 12px;
          width: calc(50% - 4px);
          height: 72px;
          cursor: pointer;
          border-radius: 4px;
          background: #fafbfc;

          img {
            width: 56px;
            height: 56px;
            padding: 8px;
          }

          h5 {
            width: 123px;
            margin-top: 8px;
            word-break: break-all;
          }

          .del {
            margin: 27px 9px 0 0;
            height: 15px;
            opacity: 1;
            color: red;
          }
        }
      }

      &:nth-child(1) {
        width: 500px;
        border-right: 1px solid #eceef6;
      }
    }
  }

    ul {
      label {
        padding: 8px 12px;
        line-height: 36px;
      }
    }

    .orderInfo {
      width: 100%;
      display: flex;
      margin-top: 36px;
      position: relative;
      >label{
        position: absolute;
        top: -42px;
      }
      li {
        display: flex;
        height: 36px;
        &:last-child{
          flex: 1;
        }
        label {
          text-align: center;
          vertical-align: center;
          flex-basis: 85px;
          line-height: 20px;
          color: #909399;
          background: #fafbfc;
          border-top: 1px solid #dcdfe6;
          border-bottom: 1px solid #dcdfe6;
        }
      }
    }

    :deep(.fileList) {
      border-bottom: 1px solid #eceef6;
      .imgList {
        display: flex;
        margin-left: 12px;
        >span{
          position: relative;
          img {
            margin: 0 10px 16px 0;
            max-width: 64px;
            height: 64px;
            border-radius: 5px;
          }
          .imgList-icon {
            position: absolute;
            right: 4px;
            top: -8px;
            display: inline-block;
            width: 18px;
            height: 18px;
            line-height: 23px;
            text-align: center;
            border-radius: 50%;
            background-color: #C0C4CC;
            color: #fff;
            cursor: pointer;
          }
        }

        :deep(.upload) {
          height: 64px;
          margin-bottom: 16px;
          // border: 1px dashed #c0c4cc;
          .el-upload--picture-card{
            width: 64px !important;
            height: 64px !important;
          }
          span {
            margin: 25px;
          }
        }
      }
      .label-span{
        font-size: 12px;
        color: #b2bac4;
      }
    }

    .address {
      dl {
        width: 100%;
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;

        li {
          display: flex;
          height: 36px;

          label {
            max-width: 85px !important;
            text-align: center;
            vertical-align: center;
            line-height: 20px;
            color: #909399;
            background: #fafbfc;
            border-top: 1px solid #eceef6;
            box-sizing: border-box;
          }
          >div{
            flex: 1;
            line-height: 36px;
            padding-left: 12px;
            border: 1px solid #dcdfe6;
            border-bottom: 0;
            box-sizing: border-box;
          }
        }
      }
    }
  }

  .footer {
    position: absolute;
    right: 16px;
    bottom: 0;
    height: 56px;
    width: calc(100% - 404px);
    margin: 0 -16px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
    padding: 12px 16px;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(#fff, 0.1);
    border: 1px solid #e5e7ef;
  }

  .tip {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .icon {
      font-size: 40px;
      margin-right: 16px;

      .el-icon-warning {
        color: #1890ff;
      }
    }
  }
}

:deep(.el-input--small .el-input__inner) {
  height: 36px;
  line-height: 32px;
  border-radius: 0;
}

:deep(input::-webkit-input-placeholder) {
  font-size: 14px;
}

:deep(.el-textarea__inner) {
  padding: 8px 12px;
  border: none;
  border-top: 1px solid #eceef6;
  border-bottom: 1px solid #eceef6;
  border-radius: 0;
}
 :deep(.deliverDate-date){
  .el-input--small .el-input__icon{
    line-height: 36px !important;
  }
}
.tips {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;

		.del {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			background-color: #F74B60;
			text-align: center;
			line-height: 47px;
      color: #fff;
			margin-right: 16px;
			font-size: 24px;

			.el-icon-delete {
				color: #E6E8EA;
			}
		}
	}
  .layerDialog{
  .part{
    width: 100%;
    height: 500px;
    .table-list{
      width: 100%;
      height: calc(100% - 40px);
      padding-top: 8px;
      .select-div{
        position: relative;
        .select-color{
          position: absolute;
          top: 7px;
          left: 7px;
          width: 92px;
          height: 20px;
          z-index: 1111;
        }
      }
    }
  }
  .search {
    width: 200px;
  }
}
:deep(.pic-color){
  width: 100%;
  height: 32px;
  border: 1px solid #e5e7ef;
}
</style>
