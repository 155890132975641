import {
  request
} from '@/utils/request'
// 获取用户列表
export function getCustomerList(data) {
  return request('/customer/select/page', 'post', data)
}
// 添加客户
export function addCustomerList(data) {
  return request('/customer/create', 'post', data)
}

// 编辑客户
export function editCustomerList(id, data) {
  return request('/customer/update/' + id, 'post', data)
}
// 删除客户
export function delCustomerList(id) {
  return request('/customer/delete/' + id, 'post')
}
// 客户报价模板列表
export function quteList(id) {
  return request('/quoteTemplate/list/' + id, 'get')
}
// 客户报价模板列表
export function updateQuteList(id, data) {
  return request('/quoteTemplate/update/' + id, 'post', data)
}
// 客户报价详情
export function QuteDetail(id) {
  return request('/quoteTemplate/detail/' + id, 'get')
}
// 获取零件库列表
export function getPartList(id, data) {
  return request('/part/page/' + id, 'post', data)
}
// 删除零件
export function delPart(id) {
  return request('/part/delete/' + id, 'post')
}
// 编辑零件
export function editPartData(id, data) {
  return request('/part/update/' + id, 'post', data)
}
