import { request } from '@/utils/request'
// 登录
export function login() {
  return request('login', 'get', { redirectUrl: location.href, loginFailUrl: location.href + '#/loginFail' })
}
// 用户信息
export function getUserInfo() {
  return request('getUserInfo', 'get')
}
// 退出登录
export function logout() {
  return request('logout', 'get')
}
export function casLogout(url) {
  return request(url, 'get')
}
