<template>
  <Head rout="user" />
  <main v-if="authority['CUSTOMER-VIEW']">
    <div class="main">
      <div class="search">
        <el-input
          v-model="texts"
          class="searchContent"
          clearable
          placeholder="请输入搜索内容"
          prefix-icon="el-icon-search"
          maxlength="20"
          @blur="wrapSearch"
          @keydown.enter="search"
          @clear="search"
        />
        <ls-button
          v-if="authority['CUSTOMER-MANAGE']"
          class="add"
          type="text"
          @click="addCustomer = true"
        >
          + 添加
        </ls-button>
      </div>
      <ls-table
        ref="table"
        v-bind="tableData"
        class="table"
        @scrollLoad="getPages"
      >
        <template #realName="{ scope }">
          <div class="realName">
            {{ scope.row.contact ? scope.row.contact.realName : "" }}
          </div>
        </template>
        <template #location="{ scope }">
          <div class="location">
            {{ scope.row.location? scope.row.location.province: "" }}
            {{ scope.row.location? scope.row.location.city: "" }}
            {{ scope.row.location? scope.row.location.district: "" }}
            {{ scope.row.location? scope.row.location.address: "" }}
          </div>
        </template>
        <template #mobile="{ scope }">
          <div class="mobile">
            {{ scope.row.contact ? scope.row.contact.mobile : "" }}
            <span
              v-if="!scope.row.contact.mobile"
              class="tips"
            >请在小程序绑定客户微信</span>
          </div>
        </template>
        <template #action="{ scope }">
          <div
            v-if="authority['CUSTOMER-MANAGE']"
            class="action"
          >
            <ls-button
              text
              class="edit"
              @click="editRow(scope.row)"
            >
              编辑
            </ls-button>
            <ls-button
              text
              class="offer"
              @click="toQute(scope.row)"
            >
              报价模板
            </ls-button>
            <ls-button
              text
              class="part"
              @click="getPartListData(scope.row)"
            >
              零件库
            </ls-button>
            <ls-button
              text
              color="#FD5550"
              class="del"
              @click="del(scope.row)"
            >
              删除
            </ls-button>
          </div>
          <div
            v-if="isShow === scope.row.id "
            class="hasTip"
          >
            该客户暂无报价信息，还未生成模板
          </div>
          <div
            v-if="isShowPart === scope.row.id "
            class="hasTip"
            style="width:150px"
          >
            该客户暂未生成零件库
          </div>
        </template>
      </ls-table>
    </div>
    <!-- 添加 -->
    <el-dialog
      v-model="addCustomer"
      width="512px"
      title="添加客户"
      destroy-on-close
      @close="closeForm"
    >
      <el-form :model="form">
        <el-form-item
          label="客户名称"
          label-width="80px"
          prop="title"
          :rules="[
            { required: true, message: '用户名不能为空', trigger: 'blur' },
          ]"
        >
          <el-input
            v-model="form.title"
            autocomplete="off"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item
          label="所在地区"
          label-width="80px"
        >
          <el-cascader
            v-model="addressValue"
            :options="areaOption"
            clearable
            style="width:382px"
            @change="addressChange"
          />
        </el-form-item>
        <el-form-item
          label="收货地址"
          label-width="80px"
        >
          <el-input
            v-model="location.address"
            autocomplete="off"
            placeholder="请输入"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addCustomer = false">取消</el-button>
          <el-button
            type="primary"
            @click="submitForm"
          >确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog
      v-model="editCustomer"
      width="512px"
      title="编辑客户"
      destroy-on-close
    >
      <el-form :model="editForm">
        <el-form-item
          label="客户名称"
          label-width="80px"
          prop="title"
          :rules="[
            { required: true, message: '用户名不能为空', trigger: 'blur' },
          ]"
        >
          <el-input
            v-model="editForm.title"
            autocomplete="off"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item
          label="所在地区"
          label-width="80px"
        >
          <el-cascader
            v-model="editAddressValue"
            :options="areaOption"
            clearable
            style="width:382px"
            @change="editAddressChange"
          />
        </el-form-item>
        <el-form-item
          label="收货地址"
          label-width="80px"
        >
          <el-input
            v-model="newAddress"
            autocomplete="off"
            placeholder="请输入"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editCustomer = false">取消</el-button>
          <el-button
            type="primary"
            @click="submitEditForm"
          >确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 删除 -->
    <el-dialog
      v-model="delFlag"
      title="删除"
      width="480px"
    >
      <div class="tip">
        <div class="del">
          <div class="el-icon-delete" />
        </div>
        <div>
          <div class="delTip">
            确认要删除该员工？
          </div>
          <div class="bottomTip">
            删除之后不可恢复，请谨慎处理
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="delFlag = false">取消</el-button>
          <el-button
            type="primary"
            @click="delClick"
          >确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 报价 -->
    <el-dialog
      v-model="offerDialog"
      title="报价模板"
      width="764px"
    >
      <div class="wrapOffer">
        <div class="left">
          <div
            v-for="(item,index) in quteInfoData"
            :key="index"
            :class="infoDetail.id === item.id ? 'activeItem' : isEnter === index && !isLeave ? 'enterItem' : 'item'"
            @click="selectQute(item)"
            @mouseenter="enterQute(index)"
            @mouseleave="leaveQute(index)"
          >
            <div class="inner">
              <span :class="infoDetail.id === item.id ? 'activeTitle' : isEnter === index && !isLeave ? 'enterTitle' : 'title'">{{ item.plateType.title }}</span>
              <span :class="infoDetail.id === item.id ? 'activeVal' : isEnter === index && !isLeave ? 'enterVal' : 'val'">{{ item.plateType.material }}/{{ item.plateType.thickness }}mm</span>
            </div>
          </div>
        </div>
        <div class="right">
          <el-form
            ref="formRule"
            class="form"
            :model="formQute"
            label-width="70px"
            :rules="rules"
          >
            <el-form-item
              label="板材单价"
              prop="basicPrice"
              :rules="[{ type: 'number', message: '请输入数字类型' }]"
            >
              <el-input
                v-model.number="formQute.basicPrice"
                type="basicPrice"
                autocomplete="off"
                class="input"
              >
                <template #append>
                  元/kg
                </template>
              </el-input>
            </el-form-item>
            <el-form-item
              label="穿孔单价"
              prop="piercePrice"
              :rules="[{ type: 'number', message: '请输入数字类型' }]"
            >
              <el-input
                v-model.number="formQute.piercePrice"
                type="piercePrice"
                class="input"
              >
                <template #append>
                  元/个
                </template>
              </el-input>
            </el-form-item>
            <el-form-item
              label="切割单价"
              prop="cutPrice"
              :rules="[{ type: 'number', message: '请输入数字类型' }]"
            >
              <el-input
                v-model.number="formQute.cutPrice"
                type="cutPrice"
                class="input"
              >
                <template #append>
                  元/米
                </template>
              </el-input>
            </el-form-item>
            <el-form-item
              label="激光折扣"
              prop="processDiscount"
              :rules="[{ type: 'number', message: '请输入数字类型' }]"
            >
              <el-input
                v-model.number="formQute.processDiscount"
                type="processDiscount"
                class="input"
              >
                <template #append>
                  &nbsp;&nbsp;%&nbsp;&nbsp;
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="废料开关">
              <el-switch v-model="formQute.enableScrap" />
            </el-form-item>
            <div v-if="formQute.enableScrap">
              <el-form-item label="损耗开关">
                <el-switch v-model="formQute.enableWastage" />
              </el-form-item>
              <el-form-item
                label="废料单价"
                prop="scrapPrice"
                :rules="[{ type: 'number', message: '请输入数字类型' }]"
              >
                <el-input
                  v-model.number="formQute.scrapPrice"
                  type="scrapPrice"
                  class="input"
                >
                  <template #append>
                    元/kg
                  </template>
                </el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="offerDialog = false">取消</el-button>
          <el-button
            type="primary"
            @click="submitFormQute('formQute')"
          >确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 零件库 -->
    <el-dialog
      v-model="partDialog"
      title="零件库"
      width="886px"
      destroy-on-close
    >
      <div style="width:200px;height:32px">
        <el-input
          v-model="partText"
          class="searchContent"
          clearable
          placeholder="请输入搜索内容"
          prefix-icon="el-icon-search"
          @change="searchPart"
        />
      </div>
      <div class="tabPart">
        <ls-table
          v-bind="partList"
          class="partList"
          @scrollLoad="getPagePartData"
        >
          <template #thumbnail="{ scope }">
            <div class="thumbnail">
              <el-image
                class="img"
                :src="scope.row.thumbnail"
                :preview-src-list="[scope.row.thumbnail]"
              />
            </div>
          </template>
          <template #title="{ scope }">
            <div class="title">
              <span v-if="editPartId !== scope.row.id">{{ scope.row.title }}</span>
              <el-input
                v-if="editPartId === scope.row.id"
                v-model="editTitle"
              />
            </div>
          </template>
          <template #thickness="{ scope }">
            <div class="thickness">
              {{ scope.row.thickness }}mm
            </div>
          </template>
          <template #range="{ scope }">
            <div class="range">
              {{ scope.row.width }}mm*{{ scope.row.height }}mm
            </div>
          </template>
          <template #action="{ scope }">
            <div
              class="action"
              :disabled="editPartId !== scope.row.id"
            >
              <span
                v-if="editPartId !== scope.row.id"
                @click="editPart(scope.row.id,scope.row.title)"
              >编辑</span>
              <el-popconfirm
                v-if="editPartId !== scope.row.id"
                confirm-button-text="确认"
                cancel-button-text="取消"
                icon="el-icon-delete"
                icon-color="#F74B60"
                title="删除后不可恢复，确定删除吗?"
                @confirm="delPartData(scope.row.id)"
              >
                <template #reference>
                  <span
                    v-if="editPartId !== scope.row.id"
                    class="delPart"
                  >删除</span>
                </template>
              </el-popconfirm>
              <div
                v-if="editPartId === scope.row.id"
                class="edit"
              >
                <div class="editPart">
                  <div
                    class="el-icon-error"
                    style="color:#C0C4CC"
                    @click="editPartId = ''"
                  />
                </div>
                <div class="editPart">
                  <div
                    class="el-icon-success"
                    @click="saveEdit(scope.row.id)"
                  />
                </div>
              </div>
              <el-icon>
                <circle-close />
              </el-icon>
              <span />
            </div>
          </template>
        </ls-table>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="partDialog = false">取消</el-button>
          <el-button
            type="primary"
            @click="partDialog = false"
          >确定</el-button>
        </span>
      </template>
    </el-dialog>
  </main>
  <main v-else>
    暂无数据
  </main>
</template>
<script>
import { ref, watch, reactive, onMounted } from 'vue'
import Store from '@/store/index'
import area from '@/static/area.js'
import {
  getCustomerList,
  addCustomerList,
  editCustomerList,
  delCustomerList,
  quteList,
  QuteDetail,
  updateQuteList,
  getPartList,
  delPart,
  editPartData
} from '@/apis/customer'
import { ElMessage } from 'element-plus'
export default {
  name: 'CustomerManage',
  setup() {
    // 权限
    const authority = ref({
      'CUSTOMER-VIEW': true,
      'CUSTOMER-MANAGE': true
    })
    watch(Store.state, () => {
      const authorityList = Store.state.authorityList
      // 客户信息查看
      if (authorityList.includes('LITE_MES_CUSTOMER-VIEW')) {
        authority.value['CUSTOMER-VIEW'] = true
      }
      // 客户管理
      if (authorityList.includes('LITE_MES_CUSTOMER-MANAGE')) {
        authority.value['CUSTOMER-MANAGE'] = true
      }
    })
    const listData = reactive([])
    onMounted(() => {
      areaOption.value = area
      listData.length = 0
      getData()
    })
    // 获取客户列表
    const page = ref({
      searchText: '', // 搜索文本
      pageSize: 20, // 每页大小
      pageNumber: 0 // 页码
    })
    let finish = false // 是否加载完成
    const getData = () => {
      getCustomerList({ ...page.value }).then((res) => {
        tableData.loading = false
        tableData.tr = [...tableData.tr, ...res.data.items]
        page.value.pageNumber++
        if (res.data.items < 20) {
          finish = true
        }
      })
    }
    // 分页
    const getPages = () => {
      if (finish) {
        return
      }
      tableData.loading = true
      getData()
    }
    // 搜索
    const texts = ref('')
    const table = ref(null)
    let recodeText = ''
    const wrapSearch = () => {
      if (texts.value !== recodeText) {
        search()
      }
    }
    const search = () => {
      listData.length = 0
      tableData.tr = []
      finish = false
      page.value = {
        ...page.value,
        searchText: texts.value,
        pageNumber: 0
      }
      recodeText = texts.value
      getData()
    }
    // 添加
    const areaOption = ref(null)
    const addressValue = ref()
    const location = ref({
      country: '',
      province: '',
      city: '',
      district: '',
      address: '',
      longitude: 0,
      latitude: 0
    })
    const addressChange = () => {
      location.value.province = addressValue.value ? addressValue.value[0] : ''
      location.value.city = addressValue.value ? addressValue.value[1] : ''
      location.value.district = addressValue.value ? addressValue.value[2] : ''
      location.value.longitude = 0
      location.value.latitude = 0
    }
    const addCustomer = ref(false)
    const form = ref({
      title: '',
      location: {}
    })
    const submitForm = () => {
      // 提交表单没有验证
      // 新建同名客户错误没有处理
      form.value = {
        title: form.value.title.trim(),
        location: location.value
      }

      addCustomerList({ ...form.value })
        .then(() => {
          addCustomer.value = false
          tableData.tr = []
          page.value = {
            ...page.value,
            searchText: texts.value,
            pageNumber: 0
          }
          listData.length = 0
          finish = false
          getData()
        })
        .catch(({ data: res }) => {
          ElMessage.error(res.msg)
        })
    }
    // 编辑
    const editAddressValue = ref()
    const editLocation = ref({
      country: '',
      province: '',
      city: '',
      district: '',
      address: '',
      longitude: 0,
      latitude: 0
    })
    const editAddressChange = () => {
      const editAddress = editAddressValue.value
      editLocation.value = {
        ...editLocation.value,
        province: editAddress ? editAddress[0] : '',
        city: editAddress ? editAddress[1] : '',
        district: editAddress ? editAddress[2] : '',
        longitude: 0,
        latitude: 0
      }
    }
    const editForm = ref({
      title: '',
      location: {}
    })
    const editCustomer = ref(false)
    const customerId = ref()
    const newAddress = ref()
    const editRow = (e) => {
      editCustomer.value = true
      const { title, location, id } = e
      editForm.value = {
        title: title,
        location: location
      }
      newAddress.value = location.address
      editAddressValue.value = [
        location.province,
        location.city,
        location.district
      ]
      editLocation.value = {
        ...editLocation.value,
        province: location.province,
        city: location.city,
        district: location.district,
        longitude: 0,
        latitude: 0
      }
      customerId.value = id
    }
    const submitEditForm = () => {
      editLocation.value.address = newAddress.value
      editForm.value = {
        ...editForm.value,
        title: editForm.value.title.trim(),
        location: editLocation.value
      }
      editCustomerList(customerId.value, { ...editForm.value })
        .then(() => {
          tableData.tr = []
          page.value = {
            ...page.value,
            searchText: texts.value,
            pageNumber: 0
          }
          listData.length = 0
          finish = false
          getData()
          editCustomer.value = false
        })
        .catch(({ data: res }) => {
          ElMessage.error(res.msg)
        })
    }
    // 删除客户
    const delFlag = ref(false)
    const deleteId = ref('')
    const del = (e) => {
      delFlag.value = true
      deleteId.value = e.id
    }
    const delClick = () => {
      delCustomerList(deleteId.value).then(() => {
        tableData.tr = []
        page.value = {
          ...page.value,
          searchText: texts.value,
          pageNumber: 0
        }
        listData.length = 0
        finish = false
        getData()
        delFlag.value = false
      })
    }

    const tableData = reactive({
      th: [
        { title: '序号', type: 'seq', width: 56, align: 'center' },
        { title: '客户', field: 'title' },
        { title: '联系人', field: 'realName', width: 120 },
        { title: '联系电话', field: 'mobile', width: 200 },
        { title: '送货地址', field: 'location' },
        { title: '操作', field: 'action', width: 260, align: 'center' }
      ],
      tr: [],
      cacheKey: 'page',
      total: 0,
      loading: false,
      showSum: false
    })
    // 去报价
    const formRule = ref(null)
    const formQute = ref({
      enableScrap: false,
      enableWastage: false,
      basicPrice: 0,
      piercePrice: 0,
      cutPrice: 0,
      scrapPrice: 0,
      processDiscount: 0
    })
    const offerDialog = ref(false) //  报价弹窗
    const quteInfoData = reactive([]) // 报价列表
    const isShow = ref(0)
    const toQute = (e) => {
      quteInfoData.length = 0
      quteList(e.id).then(({ data: res }) => {
        if (res.length > 0) {
          offerDialog.value = true
        } else {
          isShow.value = e.id
          setTimeout(() => {
            isShow.value = ''
          }, 1000)
          return
        }
        quteInfoData.push(...res)
        infoDetail.value = quteInfoData[0]
        const {
          enableScrap,
          enableWastage,
          basicPrice,
          piercePrice,
          cutPrice,
          scrapPrice,
          processDiscount
        } = quteInfoData[0]
        formQute.value = {
          enableScrap,
          enableWastage,
          basicPrice,
          piercePrice,
          cutPrice,
          scrapPrice,
          processDiscount
        }
        quteId.value = quteInfoData[0].id
      })
    }
    // 点击选择报价
    const infoDetail = ref({})
    const quteId = ref(0) // 订单id
    const selectQute = (item) => {
      QuteDetail(item.id).then(({ data: res }) => {
        const {
          enableScrap,
          enableWastage,
          basicPrice,
          piercePrice,
          cutPrice,
          scrapPrice,
          processDiscount
        } = res
        formQute.value = {
          enableScrap,
          enableWastage,
          basicPrice,
          piercePrice,
          cutPrice,
          scrapPrice,
          processDiscount
        }
      })
      quteId.value = item.id
      infoDetail.value = item
    }
    // 鼠标进入
    const isEnter = ref(0) // 鼠标是否移入
    const isLeave = ref(true)
    const enterQute = (e) => {
      isEnter.value = e
      isLeave.value = false
    }
    const leaveQute = (e) => {
      isEnter.value = e
      isLeave.value = true
    }
    //  确认报价
    const submitFormQute = () => {
      formRule.value.validate((valid) => {
        if (!valid) {
          ElMessage({
            message: '保存失败',
            type: 'error'
          })
          return
        } else {
          const json = {
            ...formQute.value
          }
          updateQuteList(quteId.value, { ...json })
            .then(() => {
              ElMessage({
                message: '保存成功',
                type: 'success'
              })
            })
            .catch(({ data: res }) => {
              ElMessage({
                message: res.msg,
                type: 'error'
              })
            })
        }
      })
    }
    const rules = ref({
      piercePrice: [
        {
          type: 'number',
          message: '请输入数字类型',
          trigger: 'blur'
        }
      ],
      basicPrice: [
        {
          type: 'number',
          message: '请输入数字类型',
          trigger: 'blur'
        }
      ],
      cutPrice: [
        {
          type: 'number',
          message: '请输入数字类型',
          trigger: 'blur'
        }
      ],
      processDiscount: [
        {
          type: 'number',
          message: '请输入数字类型',
          trigger: 'blur'
        }
      ],
      scrapPrice: [
        {
          type: 'number',
          message: '请输入数字类型',
          trigger: 'blur'
        }
      ]
    })
    // 零件库
    const partDialog = ref(false) // 零件库弹窗
    const pagePart = ref({
      // 分页
      pageSize: 10,
      pageNumber: 0,
      searchText: '',
      startDate: '',
      endDate: ''
    })
    // 获取列表数据
    const isShowPart = ref('')
    const partFinish = ref(false)
    const customerPartId = ref(0)
    const getPartListData = (item) => {
      pagePart.value.pageNumber = 0
      customerPartId.value = item.id
      partList.tr = []
      getList(item.id)
    }
    const getList = (id) => {
      getPartList(id, pagePart.value).then(({ data: res }) => {
        partList.loading = false
        if (!res.total) {
          if (isSearch.value) {
            isSearch.value = false
            return
          }
          isShowPart.value = id
          setTimeout(() => {
            isShowPart.value = ''
          }, 1000)
        } else {
          partDialog.value = true
          partList.tr = [...partList.tr, ...res.items]
          pagePart.value.pageNumber++
          if (res.items.length < 10) {
            partFinish.value = true
          }
        }
      })
    }
    const partList = reactive({
      th: [
        { title: '序号', type: 'seq', width: 56, align: 'center' },
        { title: '零件图', field: 'thumbnail', align: 'center', width: 88 },
        { title: '零件名称', field: 'title', width: 220 },
        { title: '材质', field: 'material', width: 88 },
        { title: '厚度', field: 'thickness', width: 88 },
        { title: '尺寸', field: 'range' },
        { title: '操作', field: 'action', width: 100, align: 'center' }
      ],
      tr: [],
      total: 0,
      loading: false,
      showSum: false
    })
    const getPagePartData = () => {
      if (partFinish.value) {
        return
      }
      partList.loading = true
      getList(customerPartId.value)
    }
    const editPartId = ref('')
    const editTitle = ref('')
    const editPart = (id, title) => {
      // 编辑零件
      editTitle.value = title
      editPartId.value = id
    }
    // 保存编辑
    const saveEdit = (id) => {
      const json = {
        title: editTitle.value
      }
      editPartData(id, json)
        .then(() => {
          editPartId.value = ''
          pagePart.value.pageNumber = 0
          partFinish.value = false
          partList.tr = []
          getList(customerPartId.value)
          ElMessage({
            message: '保存成功',
            type: 'success'
          })
        })
        .catch(({ data: res }) => {
          ElMessage({
            message: res.msg,
            type: 'error'
          })
        })
    }
    // 删除零件
    const delPartData = (id) => {
      delPart(id)
        .then(() => {
          const newArr = partList.tr.filter((item) => {
            return item.id !== id
          })
          partList.tr = []
          partList.tr = [...newArr]
          ElMessage({
            message: '删除成功',
            type: 'success'
          })
        })
        .catch(() => {
          ElMessage({
            message: '删除失败',
            type: 'error'
          })
        })
    }
    // 搜索零件
    const partText = ref('')
    const isSearch = ref(false) // 是否是搜索
    const searchPart = (text) => {
      isSearch.value = true
      partText.value = text
      pagePart.value.pageNumber = 0
      pagePart.value.searchText = text
      partFinish.value = false
      partList.tr = []
      getList(customerPartId.value)
    }

    return {
      deleteId,
      delFlag,
      delClick,
      del,
      editCustomer,
      editForm,
      form,
      addCustomer,
      authority,
      listData,
      tableData,
      table,
      page,
      texts,
      search,
      getData,
      submitEditForm,
      editRow,
      submitForm,
      getPages,
      areaOption,
      addressValue,
      location,
      addressChange,
      editAddressValue,
      editLocation,
      editAddressChange,
      toQute,
      offerDialog,
      selectQute,
      enterQute,
      formQute,
      quteInfoData,
      isEnter,
      leaveQute,
      isLeave,
      infoDetail,
      quteId,
      rules,
      submitFormQute,
      formRule,
      isShow,
      newAddress,
      wrapSearch,
      partDialog,
      partList,
      pagePart,
      getPartListData,
      isShowPart,
      customerPartId,
      getPagePartData,
      partFinish,
      editPartId,
      editPart,
      delPartData,
      saveEdit,
      editTitle,
      searchPart,
      partText,
      isSearch
    }
  }
}
</script>
<style lang="less" src="./index.less" scoped></style>
