<template>
  <div class="total">
    <div style="overflow:hidden;">
      <el-breadcrumb separator="/" style="padding: 12px 0;">
        <el-breadcrumb-item :to="{ path: '/' }">
          <span @click="emits('reQuoteFun', false)">订单报价</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <a href="/">零件汇总</a>
          <span v-if="errFlag" style="color:#ff9000;"> 存在零件信息有误，请仔细检查，以免出错</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <span v-if="recognizeResultList.length > 0" style="display:block;margin-bottom:12px;">
      存在图纸未识别成零件：
      <el-tooltip placement="bottom" effect="customized">
        <template #content>
          <span v-for="item in recognizeResultList" :key="item" style="display:block;margin-bottom:5px;">
            {{ item.fileName }}: <span> {{ item.recognizeResult }}</span>
          </span>
        </template>
        <span style="color: #1890ff">详情</span>
      </el-tooltip>
    </span>
    <div class="partTotal">
      <div v-if="!batchSetting" class="all-setting">
        <ls-button empty @click="batchSetting = true">
          批量设置材质/厚度
        </ls-button>
        <el-popover placement="bottom-start" width="345" trigger="hover">
          <div class="code-config-help">
            点击批量设置按钮，可勾选零件批量设置材质厚度
          </div>
          <template #reference>
            <span style="margin-left:13px;">
              <i class="icon-warning-outline" style="color:#1890ff" />
            </span>
          </template>
        </el-popover>
      </div>
      <div v-else class="all-setting" style="display:flex;align-items: center;">
        已选：<span style="font-size: 20px;margin-right: 16px;">{{ selectNum }}</span>
        设置材质
        <el-select v-model="batchMaterial" size="small" clearable filterable style="width:200px;margin: 0 8px;" placeholder="请选择" @change="materialFun('batch')">
          <el-option v-for="item in batchMaterialData" :key="item.id" :label="item.material" :value="item.material" />
        </el-select>
        设置厚度
        <el-select v-model="batchThickness" size="small" clearable filterable style="width:200px;margin: 0 8px;" placeholder="请选择" @change="thicknessFun('batch')">
          <el-option v-for="(item,index) in batchThicknessData" :key="index" :label="item+ 'mm'" :value="item" />
        </el-select>
        <ls-button empty @click="allSetting">
          批量应用
        </ls-button>
      </div>
      <ls-table ref="totalTables" :key="totalTablesKey" v-bind="totalDetail" :loading="totalDetail.loading" class="table" style="height: calc(100% - 46px)" @filter-confirm="filterConfirm">
        <template #name="{ scope }">
          <div>
            <i v-if="!scope.row.validFlag" class="warning">异常</i> {{ scope.row.name }}
          </div>
        </template>
        <template #thumbnail="{ scope }">
          <div class="thumbnail">
            <div class="demo-image__preview">
              <img v-if="scope.row.thumbnail" :src="scope.row.thumbnail" alt="" class="thumbnail-img" @click="thumbnailClick(scope.row)">
              <el-image v-else class="img" src="@/assets/img/default.png" />
              <el-image-viewer v-if="picDialogVisible&&scope.row.thumbnail" class="el-image-thumbnail" :url-list="previewList" @close="()=>{picDialogVisible=false}" />
            </div>
          </div>
        </template>
        <template #material="{scope}">
          <el-select v-model="scope.row.material" :class="{'no-sure':!scope.row.materialAndThicknessSure}" clearable filterable style="width: 142px;" @focus="thicknessFun(scope.row)" @change="isEdit">
            <el-option v-for="(item) in materialOption" :key="item.id" :label="item.material" :value="item.material" />
          </el-select>
        </template>
        <template #thickness="{scope}">
          <el-select v-model="scope.row.thickness" :class="{'no-sure':!scope.row.materialAndThicknessSure}" clearable filterable style="width: 142px;" @focus="materialFun(scope.row)" @change="isEdit">
            <el-option v-for="(item, index) in thicknessOption" :key="index" :label="item+' mm'" :value="item" />
          </el-select>
        </template>
        <template #amount="{scope}">
          <el-input-number v-model="scope.row.amount" controls-position="right" style="width: 120px;" @change="isEdit" />
        </template>
      </ls-table>
    </div>
    <div class="footer">
      <div class="left">
        <el-pagination
          v-model:current-page="page.currentPage"
          v-model:page-size="page.pageSize"
          :page-sizes="[50, 100, 150, 200]"
          small
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalDetail.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
        <el-popover placement="top" :width="400" trigger="hover">
          <div class="careful">
            <div>批量修改零件参数以及推送零件去报价，<span>只对当前加载页生效。</span></div>
            <div>如果想操作其他页数据，需要跳转到对应页，再执行相应操作。</div>
          </div>
          <template #reference>
            <span style="color:#1890ff;line-height: 33px;">
              <i class="icon-warning-outline" style="margin:0 5px 0 10px;" />注意
            </span>
          </template>
        </el-popover>
      </div>
      <div>
        <ls-button plain style="margin-right: 8px; height: 32px;" @click="backClick">
          返回
        </ls-button>
        <ls-button v-if="errFlag||recognizeResultList.length>0" plain style="margin-right: 8px; height: 32px;" @click="reUploadFun">
          重新上传
        </ls-button>
        <ls-button :disabled="recognizeResultList.length > 0" primary @click="reQuoteFun">
          重新报价
        </ls-button>
      </div>
    </div>
    <!-- 返回 -->
    <el-dialog v-model="save" title="提示" width="480px">
      <div class="tip">
        <div class="icon">
          <i class="el-icon-warning" />
        </div>
        <div>
          <div>你要保存当前所做的更改吗？</div>
          <div>如果不保存，您更改的内容将会丢失</div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="noSaveFun">不保存</el-button>
          <el-button type="primary" @click="reQuoteFun">保存</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script setup>
import { defineProps, watch, ref, defineEmits, defineExpose } from 'vue'
import { getPlateTypePage, orderQuoteFormTypePartList, updateAndReQuote, orderQuotePart, orderQuoteFormAutoQuoteResult, orderQuotePartUpdate } from '@/apis/order'
import { ElMessage, ElLoading } from 'element-plus'
import Store from '@/store/index'
function filterConfirm(key, value) {
  let arr = []
  if (value.length > 0) {
    for (const obj in value) {
      tableTr.value.forEach(item => {
        if (item[key] === value[obj]) arr.push(item)
      })
    }
  } else {
    arr = tableTr.value.map(row => {
      row.checked = false
      return row
    })
  }
  totalDetail.value.tr = arr
  if (value.length === 0) {
    // 表头筛选重置 刷新表格数据
    totalTablesKey.value++
  }
}
const props = defineProps({
  orderId: { type: String, default: '' },
  checkTotalClick: { type: Boolean, required: true },
  recognizeResultList: { type: Array, default: () => [] }
})
const emits = defineEmits(['update:checkTotalClick', 'reUploadFun', 'reQuoteFun'])
const reQuoteDisable = ref(false)
// 返回按钮点击
const backClick = () => {
  // !reQuoteDisable.value ? emits('reQuoteFun', false) : save.value = true
  emits('reQuoteFun', false)
}
// 零件汇总
const totalTablesKey = ref(0)
const totalDetail = ref({
  th: [
    { title: '序号', type: 'seq', width: 56, align: 'center' },
    { title: '零件图', field: 'thumbnail', width: 88, align: 'center' },
    { title: '零件名称', field: 'name' },
    { title: '来供料', field: 'ToFeed', width: 113, sortable: true, options: [], type: 'filter', filterField: 'ToFeed', value: [], template: [] },
    { title: '材质', field: 'material', width: 170, sortable: true, options: [], type: 'filter', filterField: 'material', value: [], template: [] },
    { title: '厚度', field: 'thickness', width: 170, sortable: true, options: [], type: 'filter', filterField: 'thickness', value: [], template: [] },
    { title: '数量', field: 'amount', width: 144 },
    { title: '尺寸', field: 'size', width: 111 }
  ],
  tr: [],
  total: 0,
  loading: false,
  showSum: false,
  events: {
    'checkbox-change': (row) => {
      row.row.checked = row.checked
      let number = 0
      totalDetail.value.tr.forEach((item) => {
        if (item.checked) {
          number++
        }
      })
      selectNum.value = number
    },
    'checkbox-all': (rows) => {
      totalDetail.value.tr.forEach((item) => {
        item.checked = rows.checked
      })
      selectNum.value = rows.checked ? totalDetail.value.tr.length : 0
    }
  }
})
const save = ref(false)
// 材质 厚度
const allOption = ref([])
const materialOption = ref([])
const thicknessOption = ref([])
// 筛选某材质的所有厚度
const materialFun = (row) => {
  const thicknessArr = []
  thicknessArr.sort(function(a, b) { return a - b })
  if (row === 'batch') { // 批量设置材质和厚度
    allOption.value.forEach(item => {
      if (batchMaterial.value === item.material) thicknessArr.push(item.thickness)
    })
    batchThicknessData.value = batchMaterial.value ? thicknessArr : scopeThicknessData.value
  } else { // 单个零件设置
    allOption.value.forEach(item => {
      if (row.material === item.material) thicknessArr.push(item.thickness)
    })
    thicknessOption.value = row.material ? thicknessArr : scopeThicknessData.value
  }
}
// 筛选某厚度的所有材质
const thicknessFun = (row) => {
  if (row === 'batch') {
    batchMaterialData.value = batchThickness.value ? allOption.value.filter(item => batchThickness.value === item.thickness) : scopeMaterialData.value
  } else {
    materialOption.value = row.thickness ? allOption.value.filter(item => row.thickness === item.thickness) : scopeMaterialData.value
  }
}
// 编辑校验
const isEdit = () => {
  totalDetail.value.tr.forEach(row => {
    row.notEdit = row.material === row.materialValue && row.thickness === row.thicknessValue && row.amount === row.amountValue
  })
  // 存在有编辑的情况为true
  reQuoteDisable.value = totalDetail.value.tr.filter(item => !item.notEdit).length > 0
  selectTotal()
}
// 重新报价
const reQuoteFun = () => {
  isLooking.value = ElLoading.service({
    lock: true,
    text: '订单正在自动报价中',
    background: 'rgba(0, 0, 0, 0.7)'
  })
  updateAndReQuote(props.orderId).then(() => {
    // 重新报价 轮询
    updateAndReQuoteFun()
  }).catch(() => {
    isLooking.value.close()
  })
}
function updateAndReQuoteFun() {
  orderQuoteFormAutoQuoteResult(props.orderId).then(() => {
    ElMessage.success('报价成功')
    Store.commit('setRecognizeResultList', [])
    save.value = false
    getData(props.orderId)
    !isReUpload.value ? emits('reQuoteFun', false) : emits('reUploadFun', isReUpload.value)
    isLooking.value.close()
  }).catch((err) => {
    if (err.data.status === 1) {
      updateAndReQuoteFun()
      return
    }
    save.value = false
    getPartList(props.orderId)
    isLooking.value.close()
  })
}
// 重新上传
const isReUpload = ref(false)
const reUploadFun = () => {
  if (reQuoteDisable.value) {
    isReUpload.value = true
    save.value = true
  } else {
    emits('reUploadFun', true)
  }
}

// 不保存
const noSaveFun = () => {
  save.value = false
  !isReUpload.value ? emits('reQuoteFun', false) : emits('reUploadFun', isReUpload.value)
}
const errFlag = ref(false)
const tableTr = ref([])
const page = ref({
  pageSize: 50,
  pageNumber: 0,
  currentPage: 1
})
function handleSizeChange(val) {
  page.value.pageSize = val
  getPartList(props.orderId, true)
}
function handleCurrentChange(val) {
  page.value.pageNumber = val - 1
  getPartList(props.orderId)
}
// 获取报价信息零件列表
const getPartList = (id, clear) => {
  if (clear) {
    page.value.currentPage = 1
    page.value.pageNumber = 0
    totalDetail.value.tr = []
  }
  totalDetail.value.loading = true
  selectNum.value = 0
  orderQuoteFormTypePartList(id, page.value).then((res) => {
    reQuoteDisable.value = false
    const materialDataArr = []
    const thicknessDataArr = []
    const toFeedArr = []
    totalDetail.value.tr = res.data.item.map((item, index) => {
      item.size = item.width + '*' + item.height
      item.materialValue = item.material// 保存material初始值
      item.thicknessValue = item.thickness// 保存thickness初始值
      item.amountValue = item.amount// 保存amount初始值
      item.notEdit = true
      item.index = index
      if (!item.validFlag) errFlag.value = true
      item.ToFeed = item.materialSuppliedType === 'COMPANY' ? '供料' : item.materialSuppliedType === 'CUSTOMER' ? '来料' : ''
      if (toFeedArr.filter(toFeed => toFeed.key === item.ToFeed).length === 0) toFeedArr.push({ key: item.ToFeed, label: item.ToFeed })
      if (materialDataArr.filter(mater => mater.key === item.material).length === 0) materialDataArr.push({ key: item.material, label: item.material, thickness: item.thickness })
      if (thicknessDataArr.filter(thick => thick.key === item.thickness).length === 0) thicknessDataArr.push({ key: item.thickness, label: item.thickness + 'mm', material: item.material })
      // 判断系统识别出的材质厚度信息是否在板材库
      const materialAndThicknessArr = allOption.value.filter(plate => plate.material === item.material && plate.thickness === item.thickness)
      item.materialAndThicknessSure = materialAndThicknessArr.length > 0
      return item
    })
    totalDetail.value.total = res.data.total
    totalDetail.value.th[3].options = toFeedArr
    totalDetail.value.th[4].options = materialDataArr
    totalDetail.value.th[5].options = thicknessDataArr
    tableTr.value = totalDetail.value.tr
    totalDetail.value.loading = false
  })
}
function selectTotal() {
  const materialDataArr = []
  const thicknessDataArr = []
  totalDetail.value.tr.forEach(item => {
    if (materialDataArr.filter(mater => mater.key === item.material).length === 0) materialDataArr.push({ key: item.material, label: item.material, thickness: item.thickness })
    if (thicknessDataArr.filter(thick => thick.key === item.thickness).length === 0) thicknessDataArr.push({ key: item.thickness, label: item.thickness + 'mm', material: item.material })
  })
  totalDetail.value.th[batchSetting.value ? 5 : 4].options = materialDataArr
  totalDetail.value.th[batchSetting.value ? 6 : 5].options = thicknessDataArr
  isLooking.value = ElLoading.service({
    lock: true,
    text: 'loading',
    background: 'rgba(0, 0, 0, 0.7)'
  })
  orderQuotePartUpdate(props.orderId, { quotePartList: totalDetail.value.tr }).then(() => {
    ElMessage.success('零件信息修改成功！')
    isLooking.value.close()
  }).catch(err => {
    ElMessage.error(err.msg)
    isLooking.value.close()
  })
}
const previewList = ref([])
const picDialogVisible = ref(false)
const isLooking = ref(false)
// 查看缩略图
const thumbnailClick = async(row) => {
  isLooking.value = ElLoading.service({
    lock: true,
    text: 'loading',
    background: 'rgba(0, 0, 0, 0.7)'
  })
  const url = await lookPart(row)
  previewList.value = [url]
  picDialogVisible.value = true
}
// 查看零件缩略图
const lookPart = (row) => {
  return new Promise(resolve => {
    orderQuotePart(props.orderId, row.guid).then(({ data }) => {
      const start = data.source.indexOf('saas-resource/')
      const end = data.source.indexOf('.prts')
      const str = data.source.slice(start, end + 5)
      const url = `https://fc-cloudnest.bochu.cn/prt2img/${str}?fill_color=C5E5FD&contain_text=1`
      isLooking.value.close()
      resolve(url)
    })
  })
}
// 批量设置材质和厚度
const batchMaterialData = ref([]) // 批量选择材质
const batchThicknessData = ref([]) // 批量选择厚度
const scopeMaterialData = ref([]) // 全局选择材质
const scopeThicknessData = ref([]) // 全局选择厚度
const batchMaterial = ref('') // 批量设置材质
const batchThickness = ref('') // 批量设置厚度
const selectNum = ref(0)
const batchSetting = ref(false)
watch(() => batchSetting, () => {
  if (batchSetting.value) {
    totalDetail.value.th.unshift({ width: 56, align: 'center', type: 'checkbox' })
  } else {
    totalDetail.value.th.splice(0, 1)
  }
}, {
  deep: true // 深度监听
})
// 批量应用
function allSetting() {
  const checkedArr = totalDetail.value.tr.filter(item => item.checked)
  if (!batchMaterial.value && !batchThickness.value) {
    ElMessage.warning('请设置材质或厚度！')
    return
  }
  if (checkedArr.length === 0) {
    ElMessage.warning('请选择零件')
    return
  }
  totalDetail.value.tr.forEach(item => {
    if (item.checked) {
      item.material = batchMaterial.value || item.material
      item.thickness = batchThickness.value || item.thickness
    }
  })
  setTimeout(() => {
    isEdit()
  })
}
function getData(orderId) {
  errFlag.value = false
  // 下拉框选的板材及厚度
  getPlateTypePage({
    'searchText': '',
    'pageSize': 500,
    'pageNumber': 0
  }).then((res) => {
    materialOption.value = []
    thicknessOption.value = []
    allOption.value = res.data.items
    if (res.data.items.length > 0) {
      materialOption.value = [res.data.items[0]]
      thicknessOption.value = [res.data.items[0].thickness]
      // 去重
      res.data.items.forEach(item => {
        if (item.material && materialOption.value.filter(value => value.material === item.material).length === 0) {
          materialOption.value.push(item)
        }
        if (!thicknessOption.value.includes(item.thickness)) {
          thicknessOption.value.push(item.thickness)
        }
      })
      thicknessOption.value.sort(function(a, b) { return a - b })
      batchMaterialData.value = materialOption.value
      batchThicknessData.value = thicknessOption.value
      scopeMaterialData.value = materialOption.value
      scopeThicknessData.value = thicknessOption.value
    }
    getPartList(orderId)
  })
}
defineExpose({
  getData
})
</script>
<style lang="less" scoped>
:deep(.partTotal) {
  height: calc(100% - 38px);
  .el-input__inner {
    padding: 0 8px;
    height: 32px;
    font-size: 14px;
    &::placeholder {
      font-size: 14px;
      color: #303133;
    }
  }
  .el-input-number__increase,
  .el-input-number__decrease {
    height: 15px !important;
  }
  .el-input-number .el-input__inner {
    text-align: left;
  }
  .thumbnail-img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    cursor: pointer;
  }
  .thumbnail {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .all-setting {
    margin-bottom: 8px;
  }
 .no-sure {
    .el-input {
      input {
        color: #ff9000 !important;
      }
    }
  }
}
.footer {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 56px;
  width: calc(100% - 404px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 12px 16px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(#fff, 0.1);
  border: 1px solid #e5e7ef;
  .left{
    display: flex;
  }
}

.tip {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .icon {
    font-size: 40px;
    margin-right: 16px;

    .el-icon-warning {
      color: #1890ff;
    }
  }
}
:deep(.warning) {
  display: inline-block;
  width: 27px;
  line-height: 15px;
  border: 1px solid #ff9000 !important;
  border-radius: 4px;
  font-size: 10px;
  color: rgb(255, 144, 0) !important;
  background: #fff6ea !important;
  text-align: center;
}
.careful{
  span{
    color:#ff9000
  }
}
</style>
