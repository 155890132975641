import {
  request
} from '@/utils/request'
// 获取附件地址
export function getFilePath(id) {
  return request('/order/file/downloadUrl/' + id, 'post')
}
// oss文件上传
export function ossUpload(data) {
  return request(process.env.VUE_APP_CAS_HOST + 'oss/upload', 'post', data, 'application/form-data')
}
