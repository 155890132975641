<template>
  <Head rout="my" />
  <main @click="borderImges = false">
    <div class="head">
      <ul class="nav">
        <el-tabs
          v-model="tabActiveName"
          @tab-click="tabClick"
        >
          <el-tab-pane
            v-for="(item, index) in tabData"
            :key="index"
            :label="item.title"
            :name="item.status"
          >
            <template #label>
              <span class="tabs">{{ item.title }}</span>
            </template>
          </el-tab-pane>
        </el-tabs>
      </ul>
    </div>
    <div class="content">
      <div v-if="false">
        暂无数据
      </div>
      <div v-else>
        <div
          v-if="tabActiveName === 'BOARD' && authority['BOARD-BASE_VIEW'] "
          class="board"
        >
          <div class="search">
            <el-input
              v-model="texts"
              placeholder="请输入搜索内容"
              prefix-icon="el-icon-search"
              clearable
              maxlength="20"
              @blur="search"
              @clear="search"
              @keydown.enter="search"
            />
          </div>
          <ls-table
            ref="table"
            v-bind="tableData"
            class="table"
            @scrollLoad="getPages"
          >
            <template #thumbnail="{ scope }">
              <div class="thumbnail">
                <div v-if="scope.row.thumbnail">
                  <img
                    class="img"
                    :src="scope.row.thumbnail"
                  >
                </div>
              </div>
            </template>
            <template #action="{ scope }">
              <div
                v-if="authority['BOARD-MANAGE']"
                class="action"
              >
                <ls-button
                  text
                  @click="editRow(scope.row)"
                >
                  编辑
                </ls-button>
              </div>
            </template>
          </ls-table>
          <!-- 编辑板材 -->
          <el-dialog
            v-model="setBoard"
            width="512px"
            title="编辑板材"
            destroy-on-close
          >
            <el-form :model="boardForm">
              <el-form-item
                label="板材图片"
                label-width="80px"
                class="upload"
              >
                <div
                  v-if="boardForm.thumbnail"
                  class="del"
                  src="../../assets/img/boss.svg"
                  @click="boardForm.thumbnail = ''"
                >
                  <span class="el-icon-close" />
                </div>
                <el-image
                  v-if="boardForm.thumbnail"
                  v-model="boardForm.thumbnail"
                  style="width: 72px; height: 72px;  cursor: pointer;"
                  :src="boardForm.thumbnail"
                  @click="borderImges = true"
                />
                <div
                  v-if="!boardForm.thumbnail"
                  :class="mouseEnter ? 'enter' : 'noPicture'"
                  @click="borderImges = true"
                  @mouseenter="mouseEnter = true"
                  @mouseleave="mouseEnter = false"
                >
                  +
                </div>
              </el-form-item>
            </el-form>
            <div
              v-if="borderImges"
              class="items"
              @click.stop
            >
              <div
                v-for="(obj, index) in boards"
                :key="index"
                class="item"
                @click="selectBoard(obj)"
              >
                <img :src="obj">
              </div>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="setBoard = false">取消</el-button>
                <el-button
                  type="primary"
                  @click="setBoardForm"
                >确定</el-button>
              </span>
            </template>
          </el-dialog>
        </div>
        <div
          v-if="tabActiveName === 'TEAM'"
          class="team"
        >
          <div
            v-for="(item, index) in roleList"
            :key="index"
            class="wrap"
          >
            <div class="head">
              <div class="headLeft">
                <div class="title">
                  {{ item.title }}
                </div>
                <div class="tip">
                  {{ item.description }}
                </div>
              </div>
              <div class="headRight">
                <img
                  v-if="item.title === '老板'"
                  src="../../assets/img/boss.svg"
                >
                <img
                  v-if="item.title === '排图'"
                  src="../../assets/img/figurer.svg"
                >
                <img
                  v-if="item.title === '制图'"
                  src="../../assets/img/draftsman.svg"
                >
                <img
                  v-if="item.title === '操机'"
                  src="../../assets/img/operator.svg"
                >
              </div>
            </div>
            <div
              class="list"
              :style="{ height: item.userList.length > 12 ? '600px' : '' }"
            >
              <div
                v-for="(obj, i) in item.userList"
                :key="i"
                class="item"
              >
                <div class="left">
                  <img
                    :src="obj.avatarUrl"
                    class="portrait"
                  >
                  <div class="name">
                    {{ obj.realName }}
                  </div>
                </div>
                <div class="right">
                  <div class="phone">
                    {{ obj.mobile }}
                  </div>
                  <div
                    v-if="authority['SETTING-TEAM']"
                    class="del"
                    @click="del(obj,item.id)"
                  >
                    <el-icon style="color:red;">
                      <delete />
                    </el-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 删除 -->
          <el-dialog
            v-model="delFlag"
            title="删除"
            width="480px"
          >
            <div class="tip">
              <div class="del">
                <div class="el-icon-delete" />
              </div>
              <div>
                <div>确认要删除该员工？</div>
                <div>删除之后不可恢复，请谨慎处理</div>
              </div>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="delFlag = false">取消</el-button>
                <el-button
                  type="primary"
                  @click="delClick"
                >确定</el-button>
              </span>
            </template>
          </el-dialog>
        </div>
        <div
          v-if="tabActiveName ==='SETTING'"
          class="setting"
        >
          <div class="wrap">
            <div class="head">
              <div class="title">
                {{ company.title }}
              </div>
              <div
                v-if="authority['SETTING-FACTORY']"
                class="edit"
                @click="dialogForm = true"
              >
                <img src="../../assets/img/edit.svg">
                <span class="tip">编辑</span>
              </div>
            </div>
            <div class="main">
              <div
                v-if="company.description"
                class="top"
              >
                <div class="dot" />
                <p class="des">
                  {{ company.description }}
                </p>
              </div>
              <div class=" bottomForm">
                <div class="leftLogo">
                  <el-image
                    style="width: 181px; height: 130px"
                    :src="company.logo ? company.logo :'../../assets/img/loginConfirm.svg'"
                    fit="scale-down"
                  />
                  <img>
                </div>
                <div class="info">
                  <div class="item">
                    <span class="title">所在地区</span>
                    <span
                      v-if="company.location.province"
                      class="val"
                    >
                      {{ company.location? company.location.province:'' }}/{{ company.location?company.location.city:'' }}/{{ company.location? company.location.district:'' }}
                    </span>
                  </div>
                  <div class="item">
                    <span class="title">详细地址</span>
                    <span class="val">
                      {{ company.location?company.location.address:'' }}
                    </span>
                  </div>
                  <div class="item">
                    <span class="title">电话</span>
                    <sapn class="val">
                      {{ company.mobile }}
                    </sapn>
                  </div>
                  <div class="teamCode">
                    <span class="title">团队码</span>
                    <sapn class="code">
                      {{ company.nestTeamCode }}
                    </sapn>
                    <span class="tip">*用于柏楚云排样软件的对接</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PrintPage v-if="tabActiveName === 'PRINT'" />
        <div
          v-if="false"
          class="other"
        >
          <div class="otherBox">
            <!-- tabActiveName === 'OTHER' -->
            <el-form
              ref="otherRuleForm"
              :model="otherForm"
              label-width="135px"
              class="demo-ruleForm"
            >
              <el-form-item
                label="加工允许误差范围"
                prop="normLimits"
                :rules="{
                  required: true,
                  message: '请输入误差范围',
                  trigger: 'blur',
                }"
                class="allowError"
              >
                <div class="lab">
                  ±
                </div>
                <el-input
                  v-model="otherForm.normLimits"
                  maxlength="5"
                  placeholder="请输入误差范围"
                  style="width: 253px"
                >
                  <template #append>
                    %
                  </template>
                </el-input>
                <el-button
                  type="primary"
                  style="width:80px;margin-left:12px"
                  @click="setOtherSettings"
                >
                  保存
                </el-button>
              </el-form-item>
              <p>注：若当前图纸总加工长度L'与预计切割总长度L不一致，且L’不在L*±（）%范围内，则在加工结束后向用户发送公众号通知</p>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      v-model="dialogForm"
      title="编辑"
      class="form"
      width="512px"
    >
      <div class="line" />
      <el-form
        :model="form"
        :label-width="formLabelWidth"
      >
        <el-form-item label="所在地区">
          <el-cascader
            v-model="addressValue"
            style="width: 400px"
            :options="areaOption"
            clearable
            @change="addressChange"
          />
        </el-form-item>
        <el-form-item label="详细地址">
          <el-input
            v-model="location.address"
            autocomplete="off"
            maxlength="50"
          />
        </el-form-item>
        <el-form-item label="电话">
          <el-input
            v-model="form.mobile"
            autocomplete="off"
            maxlength="15"
          />
        </el-form-item>
        <div class="img">
          <div>
            <el-form-item label="主页配图">
              <div
                v-if="form.backgroundUrl"
                style="display:flex"
              >
                <div
                  style="position:relative"
                  @mouseenter="enterBack"
                  @mouseleave="leaveBack"
                >
                  <img
                    v-if="form.backgroundUrl && !delBackImg"
                    :src="form.backgroundUrl"
                    style="height: 115.2px;width: 240px;margin-right:8px;position: absolute;"
                    alt="backImg"
                  >
                  <div
                    class="icon"
                    :style="showBackIcon ? 'margin-left:100px;margin-top:40px': 'display:none'"
                  >
                    <span
                      style="margin-right:10px"
                      @click="backPreview(file)"
                    >
                      <el-icon>
                        <zoom-in />
                      </el-icon>
                    </span>
                    <span @click="pictureBackRemove(file)">
                      <el-icon>
                        <delete />
                      </el-icon>
                    </span>
                  </div>
                </div>
              </div>
              <el-upload
                v-if="!form.backgroundUrl"
                ref="uploadbackImg"
                class="backImg"
                drag
                :show-file-list="false"
                :thumbnail-mode="false"
                :http-request="uploadBackFile"
              >
                <div style="width:50px;height:50px;margin:auto">
                  <upload-filled />
                </div>
                <div
                  class="el-upload__text"
                  style="margin-top:-15px"
                >
                  <em style="margin-top:-15px">点击上传或拖到此处上传</em>
                  <div style="margin-top:-45px">
                    <sapn style="font-size: 12px;">
                      图片支持png/jpg/jpeg/webp,3M以内
                    </sapn>
                  </div>
                </div>
              </el-upload>
            </el-form-item>
            <el-dialog v-model="backDialog">
              <img
                style="width:100%"
                :src="form.backgroundUrl"
                alt="logo"
              >
            </el-dialog>
          </div>
          <div>
            <el-form-item label="公司logo">
              <div
                v-if="form.logo"
                style="position:relative;height:72px;width:72px;"
              >
                <img
                  v-if="form.logo && !delLogo"
                  :src="form.logo"
                  style="height:72px;width:72px;"
                  alt="logo"
                  @mouseenter="enter"
                  @mouseleave="leave"
                >

                <div
                  class="icon"
                  :style="showIcon ? 'margin-top:-62px;padding-left:20px': 'display:none'"
                  @mouseenter="enter"
                  @mouseleave="leave"
                >
                  <span
                    style="margin-right:10px"
                    @click="pictureCardPreview(file)"
                  >
                    <el-icon>
                      <zoom-in />
                    </el-icon>
                  </span>
                  <span @click="pictureRemove(file,'logo')">
                    <el-icon>
                      <delete />
                    </el-icon>
                  </span>
                </div>
                <el-dialog v-model="previewDialog">
                  <img
                    style="width:100%"
                    :src="form.logo"
                    alt="logo"
                  >
                </el-dialog>
              </div>
              <el-upload
                v-if="!form.logo"
                ref="upload"
                list-type="picture-card"
                :show-file-list="false"
                :http-request="uploadFile"
              >
                <span class="el-icon-plus" />
              </el-upload>
            </el-form-item>
          </div>
        </div>
        <el-form-item
          label="工厂简介"
          :style="form.backgroundUrl?'padding-top:40px':''"
        >
          <el-input
            v-model="form.description"
            type="textarea"
            maxlength="500"
            show-word-limit="true"
          />
        </el-form-item>
        <div id="lineBottom" />
        <el-form-item
          label="团队码"
          :label-width="formLabelWidth"
        >
          <el-select
            v-model="form.nestTeamCode"
            placeholder="请选择团队码"
          >
            <el-option
              v-for="(item, index) in codes"
              :key="index"
              :label="item.code"
              :value="item.code"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogForm = false">取消</el-button>
          <el-button
            type="primary"
            @click="setCompanyInfo"
          >确定</el-button>
        </span>
      </template>
    </el-dialog>
  </main>
</template>
<script>
import { ref, watch, reactive, onMounted } from 'vue'
import Store from '@/store/index'
import {
  boardList,
  getRoles,
  getCompany,
  getCode,
  setCompany,
  updateBoard,
  delRoles,
  otherSettings,
  updateOtherSettings
} from '@/apis/my'
import { ossUpload } from '@/apis/common'
import { ElMessage, ElLoading } from 'element-plus'
import { Delete, ZoomIn, UploadFilled } from '@element-plus/icons'
import PrintPage from './components/printSetting/index'
import area from '@/static/area.js'
export default {
  name: 'MyPage',
  components: {
    Delete,
    PrintPage,
    ZoomIn,
    UploadFilled
  },
  setup() {
    // 权限
    const authority = ref({
      'BOARD-BASE_VIEW': true,
      'BOARD-PRICE_VIEW': true,
      'BOARD-MANAGE': true,
      'SETTING-FACTORY': true,
      'SETTING-TEAM': true
    })
    watch(Store.state, () => {
      const authorityList = Store.state.authorityList
      // 板材基本信息查看
      if (authorityList.includes('LITE_MES_BOARD-BASE_VIEW')) {
        authority.value['BOARD-BASE_VIEW'] = true
      }
      // 板材价格查看
      if (authorityList.includes('LITE_MES_BOARD-PRICE_VIEW')) {
        authority.value['BOARD-PRICE_VIEW'] = true
      }
      // 板材管理
      if (authorityList.includes('LITE_MES_BOARD-MANAGE')) {
        authority.value['BOARD-MANAGE'] = true
      }
      // 团队设置
      if (authorityList.includes('LITE_MES_SETTING-TEAM')) {
        authority.value['SETTING-TEAM'] = true
      }
      // 工厂设置
      if (authorityList.includes('LITE_MES_SETTING-FACTORY')) {
        authority.value['SETTING-FACTORY'] = true
      }
    })
    // 头部导航
    const tabActiveName = ref('BOARD')
    const tabData = reactive([
      {
        title: '板材管理',
        status: 'BOARD'
      },
      {
        title: '员工管理',
        status: 'TEAM'
      },
      {
        title: '工厂设置',
        status: 'SETTING'
      },
      {
        title: '打印设置',
        status: 'PRINT'
      }
    ])
    const tabClick = () => {
      if (tabActiveName.value === 'BOARD') {
        finish = false
        page.value = {
          ...page.value,
          searchText: texts.value,
          pageNumber: 0
        }
        tableList.length = 0
        getList()
      } else if (tabActiveName.value === 'SETTING') {
        getCopanyDetail()
        getCodeDetail()
      } else if (tabActiveName.value === 'OTHER') {
        getOtherSettings()
      }
    }
    onMounted(() => {
      areaOption.value = area
      getRoleList()
      getCopanyDetail()
      getCodeDetail()
      getList()
    })
    // 获取其他设置
    const getOtherSettings = () => {
      otherSettings()
        .then(({ data }) => {
          otherForm.value.normLimits = data.normLimits
        })
        .catch(({ data: res }) => {
          ElMessage.error(res.msg)
        })
    }
    const setOtherSettings = () => {
      const { normLimits } = otherForm.value
      if (normLimits === '') {
        return
      }
      if (normLimits > 100) {
        ElMessage.warning('加工允许误差范围不能大于100')
        return
      }
      updateOtherSettings({ normLimits })
        .then(() => {
          ElMessage.success('保存成功')
        })
        .catch(({ data: res }) => {
          ElMessage.error(res.msg)
        })
    }
    const tableData = reactive({
      th: [
        { title: '序号', type: 'seq', width: 56, align: 'center' },
        { title: '板材名称', field: 'title' },
        { title: '板材图片', field: 'thumbnail', width: 120 },
        { title: '材质', field: 'material', width: 120 },
        { title: '厚度', field: 'thickness', width: 120 },
        { title: '操作', field: 'action', width: 144, align: 'center' }
      ],
      tr: [],
      cacheKey: 'page',
      total: 0,
      loading: false,
      showSum: false
    })
    // 列表
    const listData = reactive([])
    const newListData = reactive([])
    const statusTotal = ref({})
    const page = ref({
      searchText: '',
      startDate: '',
      endDate: '',
      pageSize: 15,
      pageNumber: 0
    })
    // 获取板材列表
    const table = ref(null)
    let finish = false
    const total = ref(0) // 总数
    const tableList = reactive([])
    const getList = () => {
      const loading = ElLoading.service({
        lock: true,
        text: '请稍等',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.5)'
      })
      boardList({ ...page.value }).then((res) => {
        total.value = res.data.total
        tableData.loading = false
        if (tableList.length <= total.value) {
          tableList.push(...res.data.items)
          tableData.tr.push(...tableList)
        }
        table.value.tableEmit('insertAt', res.data.items, -1)
        page.value.pageNumber++
        if (res.data.items < 15) {
          finish = true
        }
        loading.close()
      }).catch(() => {
        loading.close()
      })
    }
    // 分页
    const getPages = () => {
      if (finish) {
        return
      }
      tableData.loading = true
      getList()
    }
    // 搜索
    const texts = ref('')
    const search = () => {
      tableList.length = 0
      finish = false
      page.value = {
        ...page.value,
        searchText: texts.value,
        pageNumber: 0
      }
      tableData.tr = []
      getList()
    }
    // 板材编辑
    const boards = ref([
      'http://fspub.oss-cn-shanghai.aliyuncs.com/mes-lite_DEV/config/plateTypeThumbnail/%E6%9D%BF%E6%9D%901.png',
      'http://fspub.oss-cn-shanghai.aliyuncs.com/mes-lite_DEV/config/plateTypeThumbnail/%E6%9D%BF%E6%9D%902.png',
      'http://fspub.oss-cn-shanghai.aliyuncs.com/mes-lite_DEV/config/plateTypeThumbnail/%E6%9D%BF%E6%9D%903.png',
      'http://fspub.oss-cn-shanghai.aliyuncs.com/mes-lite_DEV/config/plateTypeThumbnail/%E6%9D%BF%E6%9D%904.png',
      'http://fspub.oss-cn-shanghai.aliyuncs.com/mes-lite_DEV/config/plateTypeThumbnail/%E6%9D%BF%E6%9D%905.png',
      'http://fspub.oss-cn-shanghai.aliyuncs.com/mes-lite_DEV/config/plateTypeThumbnail/%E6%9D%BF%E6%9D%906.png',
      'http://fspub.oss-cn-shanghai.aliyuncs.com/mes-lite_DEV/config/plateTypeThumbnail/%E6%9D%BF%E6%9D%907.png',
      'http://fspub.oss-cn-shanghai.aliyuncs.com/mes-lite_DEV/config/plateTypeThumbnail/%E6%9D%BF%E6%9D%908.png',
      'http://fspub.oss-cn-shanghai.aliyuncs.com/mes-lite_DEV/config/plateTypeThumbnail/%E6%9D%BF%E6%9D%909.png',
      'http://fspub.oss-cn-shanghai.aliyuncs.com/mes-lite_DEV/config/plateTypeThumbnail/%E6%9D%BF%E6%9D%9010.png'
    ])
    // 选择板材
    const selectBoard = (e) => {
      boardForm.value = {
        ...boardForm.value,
        thumbnail: e
      }
      borderImges.value = false
    }
    const setBoard = ref(false) // 对话框开关
    const borderImges = ref(false) // 板材图片弹窗开关
    const boardForm = ref({
      thumbnail: '',
      basicPrice: '',
      piercePrice: '',
      cutPrice: '',
      scrapPrice: ''
    })
    const boardId = ref()
    const mouseEnter = ref(false)
    // 点击编辑按钮
    const editRow = (e) => {
      setBoard.value = true
      boardForm.value = {
        thumbnail: e.thumbnail,
        basicPrice: e.basicPrice,
        piercePrice: e.piercePrice,
        cutPrice: e.cutPrice,
        scrapPrice: e.scrapPrice
      }
      boardId.value = e.id
    }
    // 提交编辑表单
    const setBoardForm = () => {
      updateBoard(boardId.value, { ...boardForm.value }).then(() => {
        ElMessage.success('保存成功')
        tableData.tr = []
        page.value = {
          ...page.value,
          searchText: texts.value,
          pageNumber: 0
        }
        tableList.length = 0
        finish = false
        getList()
        setBoard.value = false
      })
    }
    // 获取员工管理
    const roleList = reactive([])
    const getRoleList = () => {
      const loading = ElLoading.service({
        lock: true,
        text: '请稍等',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.5)'
      })
      getRoles().then((res) => {
        roleList.push(...res.data)
        loading.close()
      })
    }
    // 删除员工
    const delFlag = ref(false)
    const delJson = ref({
      unionId: '',
      roleId: ''
    })
    const del = (obj, id) => {
      delFlag.value = true
      delJson.value = {
        ...delJson.value,
        unionId: obj.unionId,
        roleId: id
      }
    }
    const delClick = () => {
      const formData = new FormData()
      formData.append('unionId', delJson.value.unionId)
      formData.append('roleId', delJson.value.roleId)
      delRoles(formData).then(() => {
        roleList.length = 0
        getRoleList()
        delFlag.value = false
      })
    }
    // 上传logo
    const upload = ref(null)
    const uploadFlag = ref(false)
    const previewDialog = ref(false)
    const uploadFile = (file) => {
      uploadFlag.value = true
      const loading = ElLoading.service({
        lock: true,
        text: '请稍等',
        background: 'rgba(0, 0, 0, 0)'
      })
      const formData = new FormData()
      formData.append('uploadFile', file.file)
      upload.value.clearFiles()
      ossUpload(formData)
        .then((res) => {
          form.value.logo = res.data
          uploadFlag.value = true
          delLogo.value = false
          loading.close()
        })
        .catch(() => {
          uploadFlag.value = false
          loading.close()
          ElMessage.error('上传失败')
        })
    }
    const uploadbackImg = ref(null)
    const uploadBackFile = (file) => {
      const loading = ElLoading.service({
        lock: true,
        text: '请稍等',
        background: 'rgba(0, 0, 0, 0)'
      })
      const formData = new FormData()
      formData.append('uploadFile', file.file)
      uploadbackImg.value.clearFiles()
      ossUpload(formData)
        .then((res) => {
          form.value.backgroundUrl = res.data
          delBackImg.value = false
          loading.close()
        })
        .catch(() => {
          loading.close()
          ElMessage.error('上传失败')
        })
    }
    const pictureCardPreview = () => {
      previewDialog.value = true
    }
    const delLogo = ref(false)
    const delBackImg = ref(false)
    const showBackIcon = ref(false)
    const pictureBackRemove = () => {
      delBackImg.value = false
      showBackIcon.value = false
      form.value.backgroundUrl = ''
    }
    const backDialog = ref(false)
    const backPreview = () => {
      backDialog.value = true
    }
    const pictureBackPreview = () => {
      backDialog.value = true
    }
    const enterBack = () => {
      showBackIcon.value = true
    }
    const leaveBack = () => {
      showBackIcon.value = false
    }
    const pictureRemove = () => {
      delLogo.value = false
      showIcon.value = false
      form.value.logo = ''
    }
    const showIcon = ref(false)
    const enter = () => {
      showIcon.value = true
    }
    const leave = () => {
      showIcon.value = false
    }
    // 获取工厂信息
    const company = ref({})
    const getCopanyDetail = () => {
      getCompany().then((res) => {
        company.value = res.data
        form.value = { ...res.data }
        const { address, province, city, district } = form.value.location
        location.value.address = address
        addressValue.value = [
          province,
          city,
          district
        ]
      })
    }
    // 获取团队码
    const codes = ref()
    const getCodeDetail = () => {
      getCode().then((res) => {
        codes.value = res.data
      })
    }
    // 表单
    const form = ref({})
    const dialogForm = ref(false)
    const formLabelWidth = reactive('70px')
    const areaOption = ref(null)
    const location = ref({
      country: '',
      province: '',
      city: '',
      district: '',
      address: '',
      longitude: 0,
      latitude: 0
    })
    const addressValue = ref()
    const addressChange = () => {
      location.value.province = addressValue.value ? addressValue.value[0] : ''
      location.value.city = addressValue.value ? addressValue.value[1] : ''
      location.value.district = addressValue.value ? addressValue.value[2] : ''
      location.value.longitude = 0
      location.value.latitude = 0
      form.value.location = location.value
    }
    // 保存工厂信息
    const setCompanyInfo = () => {
      form.value.location = location.value.province
        ? location.value
        : form.value.location
      setCompany({ ...form.value })
        .then(() => {
          dialogForm.value = false
          getCopanyDetail()
          ElMessage.success('保存成功')
        })
        .catch(({ data: res }) => {
          ElMessage.error(res.msg)
        })
    }
    // 其他设置
    const otherForm = ref({
      normLimits: 0
    })
    return {
      upload,
      uploadFlag,
      total,
      tableList,
      selectBoard,
      delJson,
      del,
      delClick,
      delFlag,
      borderImges,
      boards,
      boardId,
      boardForm,
      setBoard,
      texts,
      finish,
      table,
      dialogForm,
      form,
      formLabelWidth,
      authority,
      tabActiveName,
      tabData,
      tableData,
      listData,
      newListData,
      statusTotal,
      page,
      codes,
      roleList,
      company,
      getPages,
      search,
      getCodeDetail,
      tabClick,
      setCompanyInfo,
      setBoardForm,
      editRow,
      uploadFile,
      previewDialog,
      pictureCardPreview,
      pictureRemove,
      delLogo,
      showIcon,
      enter,
      leave,
      areaOption,
      addressChange,
      location,
      addressValue,
      uploadBackFile,
      uploadbackImg,
      delBackImg,
      showBackIcon,
      pictureBackRemove,
      enterBack,
      leaveBack,
      pictureBackPreview,
      backDialog,
      backPreview,
      otherForm,
      setOtherSettings,
      mouseEnter
    }
  }
}
</script>
<style lang="less" src="./index.less" scoped></style>
