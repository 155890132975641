<template>
  <div class="wrap">
    <div v-if="showStatus" class="tabsWrap">
      <dl :class="{processing:orderStatus.status==='processing',stored:orderStatus.status==='stored',delivered:orderStatus.status==='delivered'}">
        <li class="status">
          <label>订单状态：</label><span>{{ orderStatus.statusDesc }}</span>
        </li>
        <li class="update">
          <label>更新时间：</label><span>{{ dateFormat(orderStatus.time,'YYYY-MM-DD hh:mm:ss') }}</span>
        </li>
      </dl>
      <div class="content">
        <div class="searchWrap">
          <el-input
            v-model.trim="processText"
            class="search"
            placeholder="输入零件名称、尺寸搜索"
            :prefix-icon="Search"
            clearable
            size="default"
            @change="processSearch"
          />
        </div>
        <ls-table
          ref="partProcessDataTable"
          key="partProcessData"
          v-bind="partProcessData"
          class="partProcessData"
          @scrollLoad="getPart"
        >
          <template #thumbnail="{scope}">
            <div class="thumbnail">
              <div>
                <img v-if="scope.row.thumbnail" :src="scope.row.thumbnail" alt="" class="img" @click="thumbnailClick(scope.row)">
              </div>
            </div>
          </template>
          <template #partName="{ scope }">
            <div class="taskName">
              <div
                v-if="scope.row.partName"
                class="inner"
              >
                {{ scope.row.partName }}
                <div
                  v-if="(scope.row.finishCount > scope.row.planCount)"
                  class="part"
                >
                  零件多切
                </div>
              </div>
            </div>
          </template>
        </ls-table>
      </div>
    </div>
    <div
      v-else
      class="defaultPage"
    >
      暂无数据
    </div>
    <el-image-viewer
      v-if="picDialogVisible"
      class="el-image-thumbnail"
      :url-list="previewList"
      @close="()=>{picDialogVisible=false}"
    />
  </div>
</template>

<script setup>
import { ref, defineExpose, defineProps } from 'vue'
import { orderProcessDetail, nodeDefList } from '../../apis/order.js'
import Store from '@/store/index'
import { Search } from '@element-plus/icons-vue'
import { ElLoading } from 'element-plus'
const props = defineProps({
  orderId: { type: String, default: '' }
})
const previewList = ref([])
const picDialogVisible = ref(false)
const isLooking = ref(false)
// 查看缩略图
const thumbnailClick = async(row) => {
  isLooking.value = ElLoading.service({
    lock: true,
    text: 'loading',
    background: 'rgba(0, 0, 0, 0.7)'
  })
  const url = await lookPart(row)
  previewList.value = [url]
  picDialogVisible.value = true
}
// 查看零件缩略图
function lookPart(row) {
  return new Promise(resolve => {
    const url = `https://fc-cloudnest.bochu.cn/prt2img${row.source}?fill_color=C5E5FD&contain_text=1`
    isLooking.value.close()
    resolve(url)
  })
}
// 加工页表格
const partProcessData = ref({
  th: [
    { title: '序号', type: 'seq', width: 56, align: 'center' },
    { title: '零件图', field: 'thumbnail', width: 88 },
    { title: '零件名称', field: 'partName' },
    { title: '尺寸', field: 'size', width: 200 },
    { title: '总数', field: 'planCount', width: 101 },
    { title: '激光进度', field: 'process', sortable: true, width: 101 }
  ],
  tr: [],
  total: 0,
  loading: false,
  showSum: false
})
const orderStatus = ref({ statusDesc: '', status: '', time: '' })
const showStatus = ref(true)
const processText = ref('')
// 搜索
const processSearch = () => {
  if (processText.value) {
    const arr = []
    partProcessData.value.tr.forEach(item => {
      const str = JSON.stringify(item)
      if (str.includes(processText.value)) arr.push(item)
    })
    partProcessData.value.tr = arr
  } else {
    getOrderProcessDetail(props.orderId)
  }
}
// 日期格式化
const dateFormat = (val, type) => {
  if (!val) return ''
  const _d = new Date(val)
  const YYYY = _d.getFullYear()
  let MM = _d.getMonth() + 1
  let DD = _d.getDate()
  let hh = _d.getHours()
  let mm = _d.getMinutes()
  let ss = _d.getSeconds()
  MM = MM > 9 ? MM : `0${MM}`
  DD = DD > 9 ? DD : `0${DD}`
  hh = hh > 9 ? hh : `0${hh}`
  mm = mm > 9 ? mm : `0${mm}`
  ss = ss > 9 ? ss : `0${ss}`
  return type.replace(/(YYYY)|(MM)|(DD)|(hh)|(mm)|(ss)/g, function(key) {
    if (key === 'YYYY') return YYYY
    if (key === 'MM') return MM
    if (key === 'DD') return DD
    if (key === 'hh') return hh
    if (key === 'mm') return mm
    if (key === 'ss') return ss
  })
}
const clientId = ref('')
const getNodeDefTitle = () => {
  return new Promise(resolve => {
    clientId.value = Store.state.customerInfo.clientId
    nodeDefList({ clientId: clientId.value }).then(({ data }) => {
      const newTh = data.map(item => {
        return { title: item.title, field: 'work_' + item.title, width: 88, sortable: true }
      })
      resolve(newTh)
    })
  })
}
// 客户获取订单加工信息
const getOrderProcessDetail = async(id) => {
  const newTh = await getNodeDefTitle()
  partProcessData.value.th.push(...newTh)
  orderProcessDetail(id).then(({ data }) => {
    if (!data.orderInfo) {
      showStatus.value = false
      return
    }
    orderStatus.value.statusDesc = data.orderInfo.statusDesc || ''
    switch (data.orderInfo.status) {
      case 'IN_DELIVER':
        orderStatus.value.time = data.orderInfo.inDeliverTime || ''
        orderStatus.value.status = 'delivered'
        break
      case 'IN_STOCK':
        orderStatus.value.time = data.orderInfo.inStockTime || ''
        orderStatus.value.status = 'stored'
        break
      default:
        orderStatus.value.time = data.orderInfo.processingTime || data.orderInfo.nestTime || ''
        orderStatus.value.status = 'processing'
        orderStatus.value.statusDesc = '加工中'
        break
    }
    partProcessData.value.tr = data.partList.map(item => {
      item.produceSchedule.forEach(value => {
        item[`work_${value.workStep}`] = value.schedule
      })
      item.size = item.height + 'mm*' + item.width + 'mm'
      item.process = item.finishCount >= item.planCount ? '100%' : item.finishCount ? parseInt(100 * (item.finishCount / item.planCount)) + '%' : '0%'
      return item
    })
  }).catch(() => {
    showStatus.value = false
  })
}
defineExpose({
  getOrderProcessDetail
})
</script>

<style lang="less" scoped>
.wrap {
  height: 100%;
  .tabsWrap {
    &:deep(.el-tabs__header) {
      border: 1px;
      margin: 0;
      width: auto;
    }

    &:deep(.el-tabs__nav) {
      width: auto;
      border-bottom: 1px solid #e7eaf2;
      border-radius: 4px;
    }

    &:deep(.el-tabs__item) {
      width: 100px;
      border: 0 1px 1px 0;
      padding: 0px;
      height: 32px;
      text-align: center;
    }

    .tabs {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 110%;
      color: rgba(144, 147, 153, 100);
      font-size: 14px;
    }

    dl {
      display: flex;
      align-items: center;
      height: 55px;
      line-height: 22px;
      border-radius: 4px;
      padding: 0 19px;

      label {
        color: #909399;
      }

      .update {
        margin-left: 188px;
      }
    }
    .processing,.stored,.delivered{
      box-sizing: border-box;
    }
    .processing {
      border: 1px solid #FFAE22;
      background-color: rgba(255, 174, 34, 0.05);
      .status {
        span {
          color: #FFAE22;
        }
      }
    }

    .stored {
      border: 1px solid #3AC602;
      background-color: rgba(58, 198, 2, 0.05);
      .status {
        span {
            color: #3AC602;
        }
      }
    }

    .delivered {
      border: 1px solid #909399;
      background-color: rgba(144, 147, 153, 0.05);
      .status {
        span {
            color: #909399;
        }
      }
    }

    .is-active {
      .tabs {
        background-color: rgba(232, 244, 255, 1);
        font-size: 14px;
        color: #1890FF;
      }
    }

    .content {
      height: 100%;

      .searchWrap {
        margin-top: 16px;
        width: 200px;
        &:deep(.el-input--suffix .el-input__inner) {
          padding-right: 0;
        }
      }

      .partProcessData {
        margin-top: 8px;
        height: calc(100vh - 350px);

        .thumbnail {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          .img {
            width: 32px;
            height: 32px;
            cursor: pointer;
          }
        }

        .taskName {
          .inner {
            display: flex;
            align-items: center;

            .part {
              margin-left: 7px;
              text-align: center;
              line-height: 20px;
              width: 64px;
              height: 20px;
              border-radius: 10px;
              background-color: #FD5555;
              color: #FFFFFF;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .defaultPage{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
</style>

