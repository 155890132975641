<template>
  <div class="preview" @click="hidePrintView">
    <div id="printTable" :class="{'print-box': true, 'A4': infoData.pageType==='A4'}" @click.stop>
      <ul v-for="(items, j) in listData" :key="j" style="page-break-after: always">
        <div class="head">
          <div class="logo">
            <img v-if="infoData.company.logo" :src="infoData.company.logo" :style="{width: imgWidth+'px'}">
          </div>
          <h3 class="formTitle">
            {{ infoData.formTitle }}
          </h3>
          <div class="addr">
            <p>地址：{{ infoData.company.location }}</p>
            <p>电话：{{ infoData.company.mobile }}</p>
          </div>
        </div>
        <div :class="{ inline: true, nb: pageType === 'QUOTE' }">
          <li>订单编号：{{ infoData.order.orderSn }}</li>
          <li v-if="infoData.order.customer">
            客户名称：{{ infoData.order.customer.title }}
          </li>
          <li>
            <span>{{ infoData.order.createTime }}</span><span class="page">第 {{ j+1 }} 页，共 {{ listData.length }} 页</span>
          </li>
        </div>
        <div v-if="pageType === 'DELIVERY'" class="inline nb left">
          <li>收货人：{{ infoData.order.customer.contact.realName }} <span v-if="infoData.order.customer.contact.realName && infoData.order.customer.contact.mobile">|</span> {{ infoData.order.customer.contact.mobile }}</li>
          <li v-if="infoData.order.customer && infoData.order.customer.location" class="mright">
            收货地址：{{ infoData.order.customer.location.formattedAddress }}
          </li>
        </div>
        <div class="table">
          <div class="th">
            <table border="0" cellspacing="0" cellpadding="0">
              <tr>
                <th>序号</th>
                <th v-for="(item, index) in infoData.fieldList" :key="index">
                  {{ item.title }}
                </th>
              </tr>
              <tr v-for="(item, index) in items" :key="index">
                <td>{{ j*pageNum+index+1 }}</td>
                <td v-for="(tdItem, i) in infoData.fieldList" :key="i">
                  {{ item[tdItem.id] }}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="space">
          <li class="remarks">
            备注：{{ infoData.remarks }}
          </li>
          <li>
            制单人：{{ infoData.username }}
            <span v-if="pageType === 'QUOTE'">总报价：￥{{ (infoData.totalFee).toFixed(2) || 0 }}</span>
          </li>
        </div>
        <div v-if="pageType === 'DELIVERY'" class="sign">
          <li>送货单位及经手人(签字盖章)：</li>
          <li>收货单位及经手人(签字盖章)：</li>
        </div>
      </ul>
    </div>
  </div>
</template>
<script>
import { ref, reactive, nextTick, onMounted } from 'vue'
import { ElMessage, ElLoading } from 'element-plus'
import Store from '@/store/index'
import { getPrintInfo } from '@/apis/order'
export default {
  name: 'PrintView',
  props: {
    printType: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  emits: ['hidePrintView'],
  setup(props, { emit }) {
    const infoData = ref({
      company: {},
      order: {},
      fieldList: [],
      fieldValueList: []
    })
    const form = reactive({})
    const tableData = reactive([])
    const orgInfo = reactive({})
    const listData = reactive([])
    const pageType = ref('')
    const pageNum = ref(0)
    const imgWidth = ref(0)
    onMounted(() => {
      const { data, printType } = props
      const { username } = Store.state.userInfo
      const orderId = data.id
      pageType.value = printType
      getPrintInfo(orderId, { type: printType }).then(res => {
        const loading = ElLoading.service({
          lock: true,
          text: '请稍等',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.5)'
        })
        if (!res.data.fieldValueList.length) {
          loading.close()
          emit('hidePrintView')
          ElMessage.warning('暂无数据')
          return
        }
        infoData.value = {
          ...res.data,
          username
        }
        let n = 0
        let y = 0
        if (infoData.value.pageType === 'A4') {
          y = 40
        } else {
          y = pageType.value === 'DELIVERY' ? 5 : 7
        }
        pageNum.value = y
        res.data.fieldValueList.forEach((item, index) => {
          if (index % y === 0) {
            listData.push([])
            n++
          }
          listData[n - 1].push(item)
        })
        const printFun = () => {
          nextTick(() => {
            loading.close()
            window.printJS({
              printable: 'printTable',
              type: 'html',
              maxWidth: '100%',
              targetStyles: ['*']
            })
            emit('hidePrintView')
          })
        }
        const logo = res.data.company.logo
        if (logo) {
          const newImg = new Image()
          newImg.src = logo
          newImg.onload = () => {
            imgWidth.value = Math.round(newImg.width / (newImg.height / 45))
            printFun()
          }
        } else {
          printFun()
        }
      }).catch(({ data: err }) => {
        ElMessage.error(err.msg)
      })
    })
    const hidePrintView = () => {
      emit('hidePrintView')
    }
    return {
      form,
      tableData,
      orgInfo,
      infoData,
      listData,
      pageType,
      pageNum,
      imgWidth,
      hidePrintView
    }
  }
}
</script>
<style lang="less" scoped>
.preview {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: -9999px;
  right: -9999px;
  background: rgba(#000,0.5);
  .print-box {
    width: 960px;
    min-height: 370px;
    background: #fff;
    padding: 0px 24px 10px;
    ul {
      border: 1px dashed #d8d8d8;
      border-top: none;
      border-bottom: none;
      padding: 0px 8px;
      .head {
        display: flex;
        align-items: center;
        height: 52px;
        border-bottom: 1px solid #d8d8d8;
        font-size: 18px;
        .logo {
          flex: 0 0 250px;
          display: block;
          img{
            height: 45px;
          }
        }
        h3 {
          flex: 1;
          line-height: 25px;
          font-size: 18px;
          text-align: center;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        .addr {
          flex: 0 0 250px;
          font-size: 12px;
          color: #303133;
          text-align: right;
          p{
            line-height: 18px;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
        }
      }
      .inline {
        display: flex;
        line-height: 28px;
        justify-content: space-between;
        border-bottom: 1px solid #d8d8d8;
        font-size: 12px;
        .mright {
          margin-left: 20px;
          margin-right: auto;
        }
        &.nb {
          border: none;
        }
        &.left{
          li{
            flex: 0 0 250px;
            &:last-child{
              margin-left: 20px;
              white-space: nowrap;
            }
          }
        }
        li {
          flex: 1;
          white-space: nowrap;
          &:nth-child(2){
            text-align: center;
          }
          &:last-child{
            text-align: right;
          }
          span {
            margin-left: 12px;
          }
        }
      }
      .table {
        margin: 6px 0px 4px;
        table {
          width: 100%;
          border: 1px solid #d8d8d8;
          border-bottom: none;
          border-right: none;
          th,
          td {
            line-height: 28px;
            border-right: 1px solid #d8d8d8;
            border-bottom: 1px solid #d8d8d8;
            padding: 0px 4px;
            text-align: left;
            &:first-child {
              width: 36px;
              text-align: center;
            }
          }
        }
      }
      .space,
      .sign {
        display: flex;
        justify-content: space-between;
        span {
          margin-left: 24px;
        }
      }
      .space {
        li {
          flex: 1;
          &:last-child {
            flex: 0 0 120px;
            white-space: nowrap;
            margin-left: 24px;
          }
        }
      }
      .remarks{
        line-height: 18px;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      .sign {
        margin-top: 14px;
        padding-top: 4px;
        border-top: 1px solid #d8d8d8;
        li {
          flex: 1;
        }
      }
    }
    &.A4{
      padding: 0px;
      ul{
        border: none;
      }
    }
  }
}
</style>
