import { createRouter, createWebHashHistory } from 'vue-router'
import Order from '../views/order/index.vue'
import My from '../views/my/index.vue'
import Customer from '../views/customer/index.vue'
const routes = [
  {
    path: '/',
    name: 'Order',
    component: Order
  },
  {
    path: '/my',
    name: 'My',
    component: My
  },
  {
    path: '/user',
    name: 'Customer',
    component: Customer
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/index.vue')
  },
  {
    path: '/loginFail',
    name: 'loginFail',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/loginFail.vue')
  },
  {
    path: '/noAuthority',
    name: 'noAuthority',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/noAuthority.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
