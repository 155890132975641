<template>
  <div class="wrap">
    <!-- 整板报价 -->
    <div v-if="!quoteDetailClick && !checkTotalClick" style="height: 100%;">
      <div class="banner">
        <dl>
          <ul>
            <img src="@/assets/img/horn.svg">
            <li>系统共识别出零件<i>{{ partKinds }}</i>种，零件总数量<i>{{ partNUm }}</i>个，请您确认报价：</li>
          </ul>
          <ul class="total">
            <li>
              总报价：<i>￥</i><a>{{ Number(totalFee).toFixed(2) }}</a>
            </li>
            <ls-button primary empty class="checkMore" @click="checkTotal">
              查看零件汇总
              <img src="@/assets/img/arrowRight.svg">
            </ls-button>
          </ul>
          <div v-if="quoteStatus && quoteStatus!=='DEFAULT'" class="priceConfirm">
            <img src="@/assets/img/priceConfirm.svg">
          </div>
        </dl>
      </div>
      <div class="tableDataRef">
        <ls-table ref="tableDataRef" v-bind="tableData" class="table table-all" @filter-confirm="filterConfirm">
          <template #name="{ scope }">
            <div>
              <i v-if="!scope.row.companySuppliedMaterialFlag&&scope.row.thumbnail!=='总计'" class="warning">来料</i>  {{ scope.row.name }}
            </div>
          </template>
          <template #thickness="{scope}">
            <div v-if="scope.row.thumbnail!=='总计'">
              {{ scope.row.thickness }}mm
            </div>
          </template>
          <template #material="{scope}">
            <div v-if="scope.row.thumbnail!=='总计'">
              {{ scope.row.material }}
            </div>
          </template>
          <template #materialTotalFee="{scope}">
            {{ scope.row.companySuppliedMaterialFlag?scope.row.materialTotalFee.toFixed(2):'-' }}
          </template>
          <template #processTotalFee="{scope}">
            {{ scope.row.processTotalFee.toFixed(2) }}
          </template>
          <template #partAvgPrice="{scope}">
            {{ scope.row.thumbnail!=='总计'?Number(scope.row.partAvgPrice).toFixed(2):'' }}
          </template>
          <template #totalFee="{scope}">
            {{ Number(scope.row.totalFee).toFixed(2) }}
          </template>
          <template #totalWeight="{ scope }">
            {{ Number(scope.row.totalWeight).toFixed(2) }}
          </template>
          <template #thumbnail="{scope}">
            <div v-if="scope.row.thumbnail!=='总计'" :class="{'thumbnail-img':offerType !== 'PLATE'}">
              <img v-if="scope.row.thumbnail" :src="scope.row.thumbnail" alt="" class="thumbnail-img" @click="thumbnailClick(scope.row)">
              <el-image
                v-else
                class="img"
                src="@/assets/img/default.png"
              />
              <el-image-viewer
                v-if="picDialogVisible&&scope.row.thumbnail"
                class="el-image-thumbnail"
                :url-list="previewList"
                @close="()=>{picDialogVisible=false}"
              />
            </div>
          </template>
          <template #otherCost="{ scope }">
            <template v-if="scope.row.type!=='total'">
              {{ offerType!== 'PLATE'?((scope.row.bendTotalFee + scope.row.weldTotalFee + scope.row.otherTotalFee)*scope.row.amount-scope.row.reliefTotalFee).toFixed(2):(scope.row.bendTotalFee + scope.row.weldTotalFee + scope.row.otherTotalFee-scope.row.reliefTotalFee).toFixed(2) }}
            </template>
            <template v-else>
              <span>{{ Number(scope.row.otherCost).toFixed(2) }}</span>
            </template>
          </template>
          <template #partUnitPrice="{ scope }">
            <span>{{ (scope.row.totalFee / scope.row.amount).toFixed(2) }}</span>
          </template>
          <template #action="{ scope }">
            <div v-if="scope.row.thumbnail!=='总计'" class="action">
              <ls-button text @click="quoteDetail(scope.row.index)">
                报价明细
              </ls-button>
            </div>
          </template>
        </ls-table>
      </div>
      <div class="footer">
        <ls-button plain style="margin-right: 8px; height: 32px;" @click="emits('update:navStatus','detail')">
          返回
        </ls-button>
        <ls-button v-if="orderStatus==='DRAFT'" primary empty @click="submitOrder">
          提交至工厂
        </ls-button>
      </div>
      <!-- 返回 -->
      <el-dialog v-model="saveDialog" title="提示" width="480px">
        <div class="tip">
          <div class="icon">
            <i class="el-icon-warning" />
          </div>
          <div>
            <div>你要保存当前所做的更改吗？</div>
            <div>如果不保存，您更改的内容将会丢失</div>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="noSave">不保存</el-button>
            <el-button type="primary" @click="saveClick">保存</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <!-- 报价明细 -->
    <div v-if="quoteDetailClick" class="details">
      <div style="overflow:hidden">
        <el-breadcrumb separator="/" style="padding: 12px 0;">
          <el-breadcrumb-item :to="{ path: '/' }">
            <span @click="quoteDetailClick=false">订单报价</span>
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            <a href="/">报价明细</a>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="content">
        <!-- 左右箭头 -->
        <div v-if="subscript > 0" class="left" @click="leftOrRight('left')">
          <div class="arrow">
            <el-icon color="#fff">
              <ArrowLeft />
            </el-icon>
          </div>
        </div>
        <div v-if="subscript <detailList.length-1 " class="right" @click="leftOrRight('right')">
          <div class="arrow">
            <el-icon color="#fff">
              <ArrowRight />
            </el-icon>
          </div>
        </div>
        <!-- 排版tab -->
        <div v-if="detailList.length>1">
          <el-tabs v-model="subscript" type="card" class="detailTab" @tab-click="changeTab">
            <el-tab-pane v-for="(obj, index) in detailList" :key="index" :name="index+''">
              <template #label>
                <span class="tabs">{{ offerType==='PLATE'?'排版':'零件' }}{{ index+1 }}</span>
              </template>
            </el-tab-pane>
          </el-tabs>
        </div>
        <!-- 板材信息 -->
        <div v-if="offerType==='PLATE'" class="mainInfo">
          <div class="top">
            <span class="tip">板材信息</span>
          </div>
          <div class="info">
            <div class="demo-image__preview">
              <img :src="detailInfo.thumbnail" alt="" class="img" @click="thumbnailClick(detailInfo)">
            </div>
            <div class="detail">
              <div class="col">
                <div class="item">
                  <span class="title">尺寸:</span>
                  <span class="val">{{ detailInfo.width }}mm*{{ detailInfo.height }}mm</span>
                </div>
                <div class="item">
                  <span class="title">重量:</span>
                  <span class="val">{{ detailInfo.weight }}kg</span>
                </div>
                <div v-if="detailInfo.pierceCount||detailInfo.contoursCount" class="item">
                  <span class="title">穿孔数:</span>
                  <span class="val">{{ offerType !== 'PLATE'? detailInfo.pierceCount:detailInfo.contoursCount }}个</span>
                </div>
              </div>
              <div class="col">
                <div class="item">
                  <span class="title">材质:</span>
                  <span class="val">{{ detailInfo.material }}</span>
                </div>
                <div class="item">
                  <span class="title">零件数量:</span>
                  <span class="val">{{ detailInfo.partAmount }}个</span>
                </div>
                <div v-if="detailInfo.curveLength" class="item">
                  <span class="title">切割长度:</span>
                  <span class="val">{{ detailInfo.curveLength }}m</span>
                </div>
              </div>
              <div class="col">
                <div class="item">
                  <span class="title">厚度:</span>
                  <span class="val">{{ detailInfo.thickness }}mm</span>
                </div>
                <div v-if="detailInfo.totalTime" class="item">
                  <span class="title">单次加工:</span>
                  <span class="val">
                    <span v-if="parseInt(detailInfo.totalTime/3600)">{{ parseInt(detailInfo.totalTime/3600) }}时</span>
                    <span v-if="parseInt((detailInfo.totalTime%3600)/60)">{{ parseInt((detailInfo.totalTime%3600)/60) }}分</span>
                    <span v-if="parseInt((detailInfo.totalTime%3600)%60)">{{ parseInt((detailInfo.totalTime%3600)%60) }}秒</span>
                    <span v-if="parseInt(detailInfo.totalTime%1*10)">{{ parseInt(detailInfo.totalTime%1*10) }}毫秒</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="middle">
          <div class="total">
            <div class="total-info">
              <div v-if="offerType!=='PLATE'" class="demo-image__preview">
                <img :src="detailInfo.thumbnail" alt="" class="img" @click="thumbnailClick(detailInfo)">
              </div>
              <div class="orderTitle">
                {{ detailInfo.name }}
              </div>
              <div class="items">
                <div v-if="detailInfo.companySuppliedMaterialFlag" class="item">
                  <span class="title">材料报价： </span>
                  <span class="val">
                    <i>￥</i>
                    {{ detailInfo.materialTotalFee.toFixed(2) }} </span>
                  <div class="jia">
                    +
                  </div>
                </div>
                <div class="item">
                  <span class="title">激光加工： </span>
                  <span class="val">
                    <i>￥</i>
                    {{ detailInfo.processTotalFee.toFixed(2) }}</span>
                  <div class="jia">
                    +
                  </div>
                </div>
                <div class="item">
                  <span class="title">其他费用： </span>
                  <span class="val">
                    <i>￥</i>
                    {{ offerType!== 'PLATE'?(detailInfo.bendTotalFee + detailInfo.weldTotalFee + detailInfo.otherTotalFee)*detailInfo.amount.toFixed(2):(detailInfo.bendTotalFee + detailInfo.weldTotalFee + detailInfo.otherTotalFee).toFixed(2) }}
                  </span>
                </div>
                <div>=</div>
                <div class="item">
                  <span class="title">总报价： </span>
                  <span class="val">
                    <i>￥</i>
                    {{ Number(detailInfo.totalFee).toFixed(2) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 列表模块 -->
        <div class="bottom" style="height:calc(100vh - 595px )">
          <!-- 材料报价 -->
          <template v-if="detailInfo.companySuppliedMaterialFlag">
            <div class="head">
              <div class="left">
                <div class="mark" />
                <div class="tip">
                  材料报价
                </div>
              </div>
            </div>
            <div class="material">
              <ls-table ref="materialTables" v-bind="boardDetail" class="table">
                <template #height="{ scope }">
                  <div class="item">
                    <div>
                      {{ scope.row.nestHeightFlag?scope.row.nestHeight: scope.row.height }}mm
                    </div>
                  </div>
                </template>
                <template #width="{ scope }">
                  <div class="item">
                    <div v-if="!isEdit">
                      {{ scope.row.nestWidthFlag?scope.row.nestWidth: scope.row.width }}mm
                    </div>
                  </div>
                </template>
                <template #thickness="{ scope }">
                  <div class="item">
                    <div v-if="!isEdit">
                      {{ scope.row.thickness }}mm
                    </div>
                  </div>
                </template>
                <template #density="{ scope }">
                  <div class="item">
                    <div v-if="!isEdit">
                      {{ scope.row.density }}kg/m³
                    </div>
                  </div>
                </template>
                <template #weight="{ scope }">
                  <div class="item">
                    <div v-if="!isEdit">
                      {{ scope.row.weight }}kg
                    </div>
                  </div>
                </template>
                <template #amount="{ scope }">
                  <div class="item">
                    <div v-if="!isEdit">
                      {{ scope.row.amount }}
                    </div>
                  </div>
                </template>
                <template #totalWeight="{ scope }">
                  <div class="item">
                    <div v-if="!isEdit">
                      {{ scope.row.totalWeight }}kg
                    </div>
                  </div>
                </template>
                <template #basicPrice="{ scope }">
                  <div class="item">
                    <div v-if="!isEdit">
                      {{ scope.row.basicPrice }}元/kg
                    </div>
                  </div>
                </template>
                <template #materialTotalFee="{scope}">
                  <div v-if="scope.row.materialTotalFee" class="item" style="color: red;">
                    {{ Number(scope.row.materialTotalFee).toFixed(2) }}元
                  </div>
                </template>
              </ls-table>
            </div>
          </template>
          <!--激光加工  -->
          <div class="head">
            <div class="left">
              <div class="mark" />
              <div class="tip">
                激光加工
              </div>
            </div>
          </div>
          <div class="process">
            <ls-table ref="processTables" v-bind="processDetail" class="table">
              <template #cutPrice="{ scope }">
                <div class="item">
                  <div v-if="!isEdit">
                    {{ scope.row.cutPrice }}元/m
                  </div>
                </div>
              </template>
              <template #curveLength="{ scope }">
                <div class="item">
                  <div v-if="!isEdit">
                    {{ scope.row.curveLength }}m
                  </div>
                </div>
              </template>
              <template #pierceCount="{ scope }">
                <div class="item">
                  <div v-if="!isEdit">
                    {{ scope.row.pierceCount }}
                  </div>
                  <div>个</div>
                </div>
              </template>
              <template #contoursCount="{ scope }">
                <div class="item">
                  <div v-if="!isEdit">
                    {{ scope.row.contoursCount }}个
                  </div>
                </div>
              </template>
              <template #piercePrice="{ scope }">
                <div class="item">
                  <div v-if="!isEdit">
                    {{ scope.row.piercePrice }}元/个
                  </div>
                </div>
              </template>
              <template #markingLength="{ scope }">
                <div class="item">
                  <div v-if="!isEdit">
                    {{ scope.row.markingLength }}m
                  </div>
                </div>
              </template>
              <template #markingPrice="{ scope }">
                <div class="item">
                  <div v-if="!isEdit">
                    {{ scope.row.markingPrice }}元/m
                  </div>
                </div>
              </template>
              <template #processDiscount="{ scope }">
                <div class="item">
                  <div v-if="!isEdit">
                    {{ scope.row.processDiscount }}折
                  </div>
                </div>
              </template>
              <template #processTotalFee="{scope}">
                <div class="item" style="color: red;">
                  {{ scope.row.processTotalFee.toFixed(2) }}元
                </div>
              </template>
            </ls-table>
          </div>
          <!-- 废料损耗 -->
          <template v-if="lossFlag">
            <div class="head">
              <div class="left">
                <div class="mark" />
                <div class="tip">
                  废料损耗
                </div>
              </div>
            </div>
            <div class="process">
              <ls-table ref="processTables" v-bind="lossDetail" class="table">
                <template #scrapWeight="{ scope }">
                  <div class="item">
                    <div>
                      {{ scope.row.scrapWeight }}kg
                    </div>
                  </div>
                </template>
                <template #wastageRate="{ scope }">
                  <div class="item">
                    <div>
                      {{ scope.row.wastageRate }}%
                    </div>
                  </div>
                </template>
                <template #wastageWeight="{ scope }">
                  <div class="item">
                    <div v-if="!isEdit">
                      {{ scope.row.wastageWeight }}kg
                    </div>
                  </div>
                </template>
                <template #restitutionRate="{ scope }">
                  <div class="item">
                    <div v-if="!isEdit">
                      {{ scope.row.restitutionRate }}%
                    </div>
                  </div>
                </template>
                <template #scrapPrice="{scope}">
                  <div class="item" style="color: red;">
                    {{ scope.row.scrapPrice }}元/kg
                  </div>
                </template>
                <template #restitutionFee="{scope}">
                  <div class="item" style="color: red;">
                    {{ scope.row.restitutionFee }}元
                  </div>
                </template>
              </ls-table>
            </div>
          </template>
          <!--其他费用  -->
          <div class="head">
            <div class="left">
              <div class="mark" />
              <div class="tip">
                其他费用
              </div>
            </div>
          </div>
          <div class="process">
            <ls-table ref="processTables" v-bind="otherCostData" class="table">
              <template #bendTotalFee="{scope}">
                <div class="item">
                  {{ scope.row.bendTotalFee }}元
                </div>
              </template>
              <template #weldTotalFee="{scope}">
                <div class="item">
                  {{ scope.row.weldTotalFee }}元
                </div>
              </template>
              <template #otherTotalFee="{scope}">
                <div class="item">
                  {{ scope.row.otherTotalFee }}元
                </div>
              </template>
              <template #reliefTotalFee="{scope}">
                <div class="item" style="color:#ff9000;">
                  {{ scope.row.reliefTotalFee }}元
                </div>
              </template>
              <template #otherCost="{scope}">
                <div class="item" style="color: red;">
                  {{ offerType!== 'PLATE'?((scope.row.bendTotalFee + scope.row.weldTotalFee + scope.row.otherTotalFee)*scope.row.amount - scope.row.reliefTotalFee).toFixed(2):(scope.row.bendTotalFee + scope.row.weldTotalFee + scope.row.otherTotalFee - scope.row.reliefTotalFee).toFixed(2) }}元
                </div>
              </template>
            </ls-table>
          </div>
        </div>
      </div>
      <div v-if="quoteDetailClick" class="btn">
        <div class="wrapBtn">
          <ls-button plain empty style="width: 80px" @click="quoteDetailClick=false">
            返回
          </ls-button>
        </div>
      </div>
      <el-image-viewer
        v-if="picDialogVisible"
        :url-list="previewList"
        class="el-image-thumbnail"
        @close="()=>{picDialogVisible=false}"
      />
      <!-- 返回 -->
      <el-dialog v-model="save" title="提示" width="480px">
        <div class="tip">
          <div class="icon">
            <i class="el-icon-warning" />
          </div>
          <div>
            <div>你要保存当前所做的更改吗？</div>
            <div>如果不保存，您更改的内容将会丢失</div>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="noSave">不保存</el-button>
            <el-button type="primary" @click="saveClick">保存</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <!-- 查看零件汇总 -->
    <Total v-show="checkTotalClick" ref="totalRef" v-model:checkTotalClick="checkTotalClick" v-model:recognizeResultList="recognizeResultList" style="height:100%;overflow: hidden;" :order-id="orderId" @reQuoteFun="getCustomerOrderQuoteDetail" @reUploadFun="emits('reUploadFun')" />
  </div>
</template>
<script setup>
import Total from '@/components/offer/total/index.vue'
import { ref, defineProps, defineExpose, defineEmits, watch, nextTick } from 'vue'
import { customerOrderQuoteDetail, customerOrderSubmit, orderQuoteFormTypePartList, orderQuoteTask, orderQuotePart, getPlateTypePage, customerCadFailed } from '../../../apis/order'
import { ElMessage } from 'element-plus'
import { ArrowRight, ArrowLeft } from '@element-plus/icons-vue'
import axios from 'axios'
import { ElLoading } from 'element-plus'
const props = defineProps({
  orderId: { type: String, default: '' },
  orderStatus: { type: String, default: '' },
  navStatus: { type: String, default: '' },
  createOrderClick: { type: Boolean, default: true }
})
const emits = defineEmits(['showInfo', 'update:navStatus', 'update:createOrderClick', 'update:isPart', 'reUploadFun', 'quoteStatus'])
const tableTr = ref([])
function filterConfirm(key, value) {
  let arr = []
  if (value.length > 0) {
    for (const obj in value) {
      tableTr.value.forEach(item => {
        if (item[key] === value[obj]) arr.push(item)
      })
    }
  } else {
    arr = tableTr.value
  }
  tableData.value.tr = arr
}
// 编辑报价
const isEdit = ref(false)
// 整板报价
const materialTables = ref(null)
const processTables = ref(null)
const tableData = ref({
  th: [
    { title: '序号', type: 'seq', width: 56, align: 'center' },
    { title: '缩略图', field: 'thumbnail', width: 88 },
    { title: '任务名称', field: 'name' },
    { title: '来供料', field: 'ToFeed', sortable: true, width: 112, options: [], type: 'filter', filterField: 'ToFeed', value: [], template: [] },
    { title: '材质', field: 'material', sortable: true, width: 88, options: [], type: 'filter', filterField: 'material', value: [], template: [] },
    { title: '厚度', field: 'thickness', sortable: true, width: 88, options: [], type: 'filter', filterField: 'thickness', value: [], template: [] },
    { title: '尺寸', field: 'specification', width: 160 },
    { title: '板材数量', field: 'amount', width: 88 },
    { title: '零件总数', field: 'partAmount', width: 88 },
    { title: '材料总重(kg)', field: 'totalWeight', width: 114 },
    { title: '材料报价', field: 'materialTotalFee', width: 88 },
    { title: '激光加工费', field: 'processTotalFee', width: 104 },
    { title: '其他费用', field: 'otherCost', width: 88 },
    { title: '零件单价', field: 'partAvgPrice', width: 88 },
    { title: '总报价', field: 'totalFee', width: 88 },
    { title: '操作', field: 'action', align: 'center', width: 100, fixed: 'right' }
  ],
  tr: [],
  total: 0,
  loading: false,
  showSum: false,
  attributes: {
    'sort-config': {
      trigger: 'cell'
    },
    'show-footer': true,
    'footer-row-class-name': 'foot'
  }
  // events: { 'sort-change': sortChange }
})
// 板材报价表尾
function footerMethod({ columns, data }) {
  const means = []
  columns.forEach((column, columnIndex) => {
    if (columnIndex === 0) {
      means.push('/')
    } else if (columnIndex === 1) {
      means.push('合计')
    } else if (columnIndex >= 9 && columnIndex <= 12 || columnIndex === 14) {
      let meanCell = null
      switch (column.property) {
        case 'totalWeight':
          meanCell = meanNum(data, column.property).toFixed(2)
          break
        case 'materialTotalFee':
          meanCell = materialTotal.value >= 0 ? materialTotal.value.toFixed(2) : ''
          break
        case 'processTotalFee':
          meanCell = processTotal.value.toFixed(2)
          break
        case 'otherCost':
          meanCell = otherCostTotal.value.toFixed(2)
          break
        case 'totalFee':
          meanCell = total.value.toFixed(2)
          break
      }
      means.push(meanCell)
    } else {
      means.push(null)
    }
  })
  // 返回一个二维数组的表尾合计
  return [means]
}
// 零件报价标尾
function partFooterMethod({ columns, data }) {
  if (!data[0]) return
  const means = []
  const flag = !!data[0].material
  const startIndex = flag ? 7 : 6
  const endIndex = flag ? 10 : 9
  const lastIndex = flag ? 12 : 11
  columns.forEach((column, columnIndex) => {
    if (columnIndex === 0) {
      means.push('/')
    } else if (columnIndex === 1) {
      means.push('合计')
    } else if (columnIndex >= startIndex && columnIndex <= endIndex || columnIndex === lastIndex) {
      let meanCell = null
      switch (column.property) {
        case 'amount':
          meanCell = meanNum(data, column.property).toFixed(2)
          break
        case 'materialTotalFee':
          meanCell = materialTotal.value >= 0 ? materialTotal.value.toFixed(2) : ''
          break
        case 'otherCost':
          meanCell = otherCostTotal.value.toFixed(2)
          break
        case 'processTotalFee':
          meanCell = processTotal.value.toFixed(2)
          break
        case 'totalFee':
          meanCell = total.value.toFixed(2)
          break
      }
      means.push(meanCell)
    } else {
      means.push(null)
    }
  })
  // 返回一个二维数组的表尾合计
  return [means]
}
function meanNum(data, property) {
  return data.reduce((result, item) => {
    return result + item[property]
  }, 0)
}
// 材料报价表格
const boardDetail = ref({
  th: [
    { title: '整板长', field: 'height', width: 112 },
    { title: '整板宽', field: 'width', width: 112 },
    { title: '厚', field: 'thickness', width: 112 },
    { title: '材料密度', field: 'density', width: 112 },
    { title: '单件重量', field: 'weight', width: 112 },
    { title: '板材数量', field: 'amount', width: 112 },
    { title: '材料总重', field: 'totalWeight', width: 112 },
    { title: '材料单价', field: 'basicPrice', width: 112 },
    { title: '材料总价', field: 'materialTotalFee' }
  ],
  tr: [],
  total: 0,
  loading: false,
  showSum: false
})
// 激光加工表格
const processDetail = ref({
  th: [
    { title: '切割长度', field: 'curveLength', width: 170 },
    { title: '切割单价', field: 'cutPrice', width: 170 },
    { title: '穿孔数量', field: 'contoursCount', width: 170 },
    { title: '穿孔单价', field: 'piercePrice', width: 170 },
    { title: '激光折扣', field: 'processDiscount', width: 170 },
    { title: '激光加工总价', field: 'processTotalFee' }
  ],
  tr: [],
  total: 0,
  loading: false,
  showSum: false
})
// 废料损耗表格
const lossDetail = ref({
  th: [
    { title: '废料重量', field: 'scrapWeight', width: 170 },
    { title: '板材损耗率', field: 'wastageRate', width: 170 },
    { title: '损耗重量', field: 'wastageWeight', width: 170 },
    { title: '废料返还率', field: 'restitutionRate', width: 170 },
    { title: '废料单价', field: 'scrapPrice', width: 170 },
    { title: '废料返还总费用', field: 'restitutionFee' }
  ],
  tr: [],
  total: 0,
  loading: false,
  showSum: false
})
// 其他费用
const otherCostData = ref({
  th: [
    { title: '折弯费用', field: 'bendTotalFee', width: 198 },
    { title: '焊接费用', field: 'weldTotalFee', width: 198 },
    { title: '其他费用', field: 'otherTotalFee', width: 198 },
    { title: '优惠金额', field: 'reliefTotalFee', width: 198 },
    { title: '备注', field: 'remarks' },
    { title: '其他费用总价', field: 'otherCost', width: 143 }
  ],
  tr: [],
  total: 0,
  loading: false,
  showSum: false
})
// 查看报价明细
const quoteDetailClick = ref(false)
const quoteDetail = (index) => {
  quoteDetailClick.value = true
  subscript.value = index + ''
  changeTab()
}
// 查看零件汇总
const checkTotalClick = ref(false)
const checkTotal = () => {
  checkTotalClick.value = true
}
// 客户获取订单报价信息
const partKinds = ref()
const partNUm = ref(0)
const totalFee = ref(0)
const detailList = ref([])// 排版信息列表
const offerType = ref('') // 报价类型
const quoteType = ref('')// 报价方式
const quoteStatus = ref(false)
const materialTotal = ref(0)
const processTotal = ref(0)
const total = ref(0)
const totalWeight = ref(0)
const otherCostTotal = ref(0)
const getCustomerOrderQuoteDetail = async(part) => {
  if (part === 'part') {
    checkTotalClick.value = true
    return
  }
  emits('update:isPart', '')
  await getSelection()
  checkTotalClick.value = false
  customerOrderQuoteDetail(props.orderId).then(({ data }) => {
    const { quoteInfoType, quoteStatus: status, quoteFormType } = data
    quoteStatus.value = status
    emits('quoteStatus', status)
    offerType.value = quoteInfoType || ''
    quoteType.value = quoteFormType || ''
    const arr = []
    // 整版报价总计部分
    materialTotal.value = 0
    processTotal.value = 0
    total.value = 0
    totalWeight.value = 0
    otherCostTotal.value = 0
    partNUm.value = 0
    totalFee.value = data.totalFee || ''
    if (data.quoteList.length === 0) return
    imgList.value = []
    previewList.value = []
    const materialDataArr = []
    const thicknessDataArr = []
    const toFeedArr = []
    data.quoteList.forEach((item, index) => {
      // 获取材料信息和合计一行中的值
      if (offerType.value === 'PLATE') {
        item.totalWeight ? totalWeight.value += item.totalWeight : totalWeight
      }
      item.size = item.width + 'mm' + '*' + item.height + 'mm'
      item.processTotalFee ? processTotal.value += item.processTotalFee : processTotal
      item.totalFee ? total.value += item.totalFee : total
      item.materialTotalFee && item.companySuppliedMaterialFlag ? materialTotal.value += item.materialTotalFee : materialTotal
      item.ToFeed = item.companySuppliedMaterialFlag ? '工厂供料' : '客户来料'
      if (toFeedArr.filter(toFeed => toFeed.key === item.ToFeed).length === 0) toFeedArr.push({ key: item.ToFeed, label: item.ToFeed })
      if (materialDataArr.filter(mater => mater.key === item.material).length === 0) materialDataArr.push({ key: item.material, label: item.material, thickness: item.thickness })
      if (thicknessDataArr.filter(thick => thick.key === item.thickness).length === 0) thicknessDataArr.push({ key: item.thickness, label: item.thickness + 'mm', material: item.material })
      otherCostTotal.value += offerType.value === 'PLATE' ? (item.bendTotalFee + item.weldTotalFee + item.otherTotalFee - item.reliefTotalFee) : ((item.bendTotalFee + item.weldTotalFee + item.otherTotalFee) * item.amount - item.reliefTotalFee)
      if (offerType.value === 'PLATE') {
        item.partAmount ? partNUm.value += item.partAmount : partNUm.value
      } else {
        item.amount ? partNUm.value += item.amount : partNUm.value
      }
      item.index = index
      arr.push(item)
      imgList.value.push(item)
    })
    tableData.value.tr = arr
    // 排版信息列表
    detailList.value = data.quoteList
    detailInfo.value = detailList.value[0]
    detailImgList.value = [detailInfo.value.thumbnail]
    boardDetail.value.tr = [detailList.value[0]]
    boardDetail.value.th[0].title = offerType.value === 'PLATE' ? (detailList.value[0].nestHeightFlag ? '板坯长' : '整板长') : '长'
    boardDetail.value.th[1].title = offerType.value === 'PLATE' ? (detailList.value[0].nestWidthFlag ? '板坯宽' : '整板宽') : '宽'
    processDetail.value.tr = [detailList.value[0]]
    // 打标未开启则显示，反之不显示
    if (detailInfo.value.enableMarking) {
      processDetail.value.th.splice(4, 2, { title: '打标长度', field: 'markingLength', width: 170 }, { title: '打标单价', field: 'markingPrice', width: 170 })
    }
    lossDetail.value.tr = [detailList.value[0]]
    otherCostData.value.tr = [detailList.value[0]]
    showMaterialConfig()
    tableTr.value = tableData.value.tr
    tableData.value.attributes.footerMethod = offerType.value === 'PLATE' ? footerMethod : partFooterMethod
    // 零件报价
    if (offerType.value !== 'PLATE') {
      tableData.value.th = [
        { title: '序号', type: 'seq', width: 56, align: 'center' },
        { title: '零件图', field: 'thumbnail', width: 88 },
        { title: '零件名称', field: 'name' },
        { title: '来供料', field: 'ToFeed', sortable: true, width: 112, options: [], type: 'filter', filterField: 'ToFeed', value: [], template: [] },
        { title: '厚度', field: 'thickness', sortable: true, width: 112, options: [], type: 'filter', filterField: 'thickness', value: [], template: [] },
        { title: '尺寸', field: 'specification', sortable: true, width: 160 },
        { title: '材料报价', field: 'materialTotalFee', width: 100 },
        { title: '零件总数', field: 'amount', width: 88 },
        { title: '其他费用', field: 'otherCost', width: 88 },
        { title: '激光加工', field: 'processTotalFee', width: 100 },
        { title: '零件单价', field: 'partUnitPrice', width: 88 },
        { title: '总价', field: 'totalFee', width: 100 },
        { title: '操作', field: 'action', width: 100, align: 'center' }
      ]
      if (data.quoteList[0] && data.quoteList[0].material) {
        tableData.value.th.splice(4, 0, { title: '材质', field: 'material', width: 112, options: [], type: 'filter', filterField: 'material', value: [], template: [] })
        tableData.value.th[4].options = materialDataArr
        tableData.value.th[5].options = thicknessDataArr
      } else {
        tableData.value.th[4].options = thicknessDataArr
      }
      processDetail.value.th[2].field = 'pierceCount'
      boardDetail.value.th[5].title = '零件数量'
      otherCostData.value.th.splice(3, 0, { title: '零件数量', field: 'amount', width: 88 })
    } else {
      tableData.value.th[4].options = materialDataArr
      tableData.value.th[5].options = thicknessDataArr
    }
    tableData.value.th[3].options = toFeedArr
  })
  // 获取报价信息零件列表
  orderQuoteFormTypePartList(props.orderId, { pageSize: 20, pageNumber: 0 }).then((res) => {
    partKinds.value = res.data.total
  })
}
const imgList = ref([])
const detailImgList = ref([])
// 点击明细排版
const subscript = ref('')
const detailInfo = ref({})

// 列表显示
const lossFlag = ref(false)
const showMaterialConfig = () => {
  // 材料报价可配置项
  const materialArr = ['density', 'weight', 'basicPrice']
  materialArr.forEach(item => {
    boardDetail.value.th.forEach(value => {
      if (value.field === item) {
        value.show = detailInfo.value[item] !== undefined
      }
    })
  })
  // 激光加工可配置项
  const processArr = ['curveLength', 'cutPrice', 'pierceCount', 'contoursCount', 'piercePrice', 'markingLength', 'markingPrice', 'processDiscount']
  processArr.forEach(item => {
    processDetail.value.th.forEach(value => {
      if (value.field === item) {
        value.show = detailInfo.value[item] !== undefined
      }
    })
  })
  // 废料损耗可配置项
  const lossArr = ['scrapWeight', 'wastageRate', 'wastageWeight', 'restitutionRate', 'scrapPrice', 'restitutionFee']
  lossArr.forEach(item => {
    lossDetail.value.th.forEach(value => {
      if (value.field === item) {
        value.show = detailInfo.value[item] !== undefined
      }
    })
  })
  lossFlag.value = lossDetail.value.th.filter(item => item.show).length > 0
}
const changeTab = () => {
  detailInfo.value = detailList.value[Number(subscript.value)]
  detailImgList.value = [detailInfo.value.thumbnail]
  showMaterialConfig()
  boardDetail.value.tr = [detailList.value[Number(subscript.value)]]
  boardDetail.value.th[0].title = offerType.value === 'PLATE' ? (detailInfo.value.nestHeightFlag ? '板坯长' : '整板长') : '长'
  boardDetail.value.th[1].title = offerType.value === 'PLATE' ? (detailInfo.value.nestWidthFlag ? '板坯宽' : '整板宽') : '宽'
  processDetail.value.tr = [detailList.value[Number(subscript.value)]]
  // 打标未开启则显示，反之不显示
  if (detailInfo.value.enableMarking) {
    processDetail.value.th.splice(4, 2, { title: '打标长度', field: 'markingLength', width: 170 }, { title: '打标单价', field: 'markingPrice', width: 170 })
  }
  lossDetail.value.tr = [detailList.value[Number(subscript.value)]]
  otherCostData.value.tr = [detailList.value[Number(subscript.value)]]
}
// 明细页面 排版左右点击
const leftOrRight = (type) => {
  type === 'left' ? subscript.value-- : subscript.value++
  subscript.value += ''
  changeTab()
}
// 提交订单
const submitOrder = () => {
  isLooking.value = ElLoading.service({
    lock: true,
    text: 'loading',
    background: 'rgba(0, 0, 0, 0.7)'
  })
  customerOrderSubmit(props.orderId).then(() => {
    ElMessage.success('提交成功')
    emits('showInfo', { id: props.orderId })
  }).finally(() => {
    isLooking.value.close()
  })
}
const previewList = ref([])
const picDialogVisible = ref(false)
const isLooking = ref(false)
// 查看缩略图
const thumbnailClick = async(row) => {
  isLooking.value = ElLoading.service({
    lock: true,
    text: 'loading',
    background: 'rgba(0, 0, 0, 0.7)'
  })
  let url
  if (quoteType.value === 'AUTO') { // 自动报价
    url = offerType.value === 'PLATE' ? await lookPlate(row) : await lookPart(row)
  } else {
    url = offerType.value === 'PLATE' ? await manualLookPlate(row) : await lookPart(row)
  }
  isLooking.value.close()
  previewList.value = [url]
  picDialogVisible.value = true
}
// 查看排版缩略图(手动报价)
const manualLookPlate = (row) => {
  return new Promise(resolve => {
    axios({
      method: 'get',
      url: process.env.VUE_APP_MES_HOST + `/nest_result2img?plan_uuid=${row.guid}&fill=0&line_color=909399&contain_part_text=1`,
      withCredentials: true,
      responseType: 'blob',
      data: { 'result_uuid': row.guid }
    }).then((response) => {
      const blob = new Blob([response.data], { type: 'image/png' })
      const url = window.URL.createObjectURL(blob)
      isLooking.value.close()
      resolve(url)
    })
  })
}
// 查看排版缩略图
const lookPlate = (row) => {
  return new Promise(resolve => {
    orderQuoteTask(props.orderId, row.guid).then(({ data }) => {
      const { width, height } = data
      const partList = data.partList.map(item => {
        item.location = item.locationList.map(value => {
          value.mirror = value.isMirror
          value.rotate = value.rotation
          return value
        })
        return item
      })
      const json = {
        width,
        height,
        partList
      }
      axios({
        method: 'post',
        url: process.env.VUE_APP_MES_HOST + '/mes/nest_result2img?contain_part_text=1',
        withCredentials: true,
        responseType: 'blob',
        data: json
      }).then((response) => {
        const blob = new Blob([response.data], { type: 'image/png' })
        const url = window.URL.createObjectURL(blob)
        isLooking.value.close()
        resolve(url)
      })
    })
  })
}
// 查看零件缩略图
const lookPart = (row) => {
  return new Promise(resolve => {
    orderQuotePart(props.orderId, row.guid).then(({ data }) => {
      const start = data.source.indexOf('saas-resource/')
      const end = data.source.indexOf('.prts')
      const str = data.source.slice(start, end + 5)
      const url = `https://fc-cloudnest.bochu.cn/prt2img/${str}?fill_color=C5E5FD&contain_text=1`
      isLooking.value.close()
      resolve(url)
    })
  })
}
// 材质 厚度
const allOption = ref([])
const materialOption = ref([])
const thicknessOption = ref([])
const getSelection = () => {
  // 下拉框选的板材及厚度
  getPlateTypePage({
    'searchText': '',
    'pageSize': 500,
    'pageNumber': 0
  }).then((res) => {
    materialOption.value = []
    thicknessOption.value = []
    if (res.data.items.length > 0) {
      allOption.value = res.data.items
      materialOption.value = [res.data.items[0]]
      thicknessOption.value = [res.data.items[0].thickness]
      // 去重
      res.data.items.forEach(item => {
        if (item.material && materialOption.value.filter(value => value.material === item.material).length === 0) {
          materialOption.value.push(item)
        }
        if (!thicknessOption.value.includes(item.thickness)) {
          thicknessOption.value.push(item.thickness)
        }
      })
      thicknessOption.value.sort(function(a, b) { return a - b })
      materialOption.value = materialOption.value.map(item => {
        item.key = item.material
        item.label = item.material
        return item
      })
      thicknessOption.value = thicknessOption.value.map(item => {
        const obj = {}
        obj.key = item
        obj.label = item + 'mm'
        return obj
      })
    }
  })
}
const recognizeResultList = ref([])// 保存识别失败的图纸信息
// 获取错误图纸
const getCustomerCadFailed = () => {
  return new Promise(resolve => {
    customerCadFailed(props.orderId).then((res) => {
      const recognizeResultList = res.data.filter(item => item.recognizeResult)
      resolve(recognizeResultList)
    })
  })
}
const totalRef = ref(null)
watch(() => props.navStatus, async(newValue) => {
  if (newValue === 'offer') {
    recognizeResultList.value = await getCustomerCadFailed()
    if (recognizeResultList.value.length > 0) {
      checkTotalClick.value = true
    }
    nextTick(() => {
      totalRef.value.getData(props.orderId)
    })
  }
}, {
  immediate: true, // 立即执行
  deep: true // 深度监听
})
defineExpose({
  getCustomerOrderQuoteDetail
})
</script>

<style src="./style/board.less" lang="less" scoped />
