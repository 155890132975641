<template>
  <div class="head">
    <div class="logo">
      <img :src="companyInfo.logo" alt="logo">
      <text>{{ companyInfo.title }}</text>
    </div>
    <div class="head-nav">
      <li
        v-for="(item, index) in headNav"
        :key="index"
        :class="{ active: item.type === navStatus }"
        @click="switchNav(item)"
      >
        <span v-if="item.type === navStatus" :class="item.icon" />
        <i :class="{ active: item.type === navStatus }">{{ item.title }}</i>
      </li>
    </div>
    <div v-if="!unlimited">
      <div v-if="quoteTimes !== frequency" style="color:#909399;margin-right: 15px;">
        剩余报价次数： <span style="color:#FF9000;font-size: 16px;font-weight: bold;">{{ quoteTimes > frequency ? 0 : frequency - quoteTimes }}</span> 次
      </div>
      <div v-else style="color:#FF9000;margin-right: 15px;">
        您免费报价次数已用完，联系工厂获取更多报价服务~
      </div>
    </div>
    <div class="avatar">
      <el-dropdown @command="logoOutChange">
        <span class="el-dropdown-link">
          <i class="icon-user" />
          {{ userInfo.username }}
          <i class="el-icon-arrow-down el-icon--right" />
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="a">
              退出
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LoGin'
}
</script>
<script setup>
import { ref, reactive, watch } from 'vue'
import Store from '@/store/index'
import router from '@/router/index.js'
import { useRoute } from 'vue-router'
import { login, logout, casLogout, getUserInfo } from '@/apis/login'
import { getCompany } from '@/apis/my'
import { getClientFrequency } from '@/apis/order'
const frequency = ref('')
const quoteTimes = ref('')
const unlimited = ref(false)
const route = useRoute()
const userInfo = ref({})
const companyInfo = ref({})
getUserInfo().then((res) => {
  Store.commit('setUserInfo', res.data)
  Store.commit('setOrgInfo', res.data.orgInfo)
  Store.commit('setAuthority', res.data.authorityList)
  Store.commit('setCustomerInfo', res.data.metadata)
  userInfo.value = Store.state.userInfo
  getCompany(res.data.metadata.orgCode).then(({ data }) => {
    companyInfo.value = data
  })
  getClientFrequency({ customerId: res.data.metadata.id }).then((res) => {
    frequency.value = res.data.frequency
    quoteTimes.value = res.data.quoteTimes
    unlimited.value = res.data.unlimited
    if (!res.data.unlimited && res.data.frequency <= res.data.quoteTimes) {
      Store.commit('setCanGetQuote', false)
    } else {
      Store.commit('setCanGetQuote', true)
    }
  })
})
const logoOutChange = () => {
  logout().then((res) => {
    casLogout(res.data).then(() => {
      login()
    })
  })
}
const headNav = reactive([
  {
    title: '订单管理',
    icon: 'el-icon-tickets',
    type: 'order',
    status: 1
  }
])
// 头部导航
const navStatus = ref('order')
const switchNav = (item) => {
  if (item.status) {
    navStatus.value = item.type
    if (item.type === 'order') {
      router.push({
        path: `/`,
        params: {}
      })
    } else if (item.type === 'task') {
      router.push({
        path: `/task`,
        params: {}
      })
    } else if (item.type === 'user') {
      router.push({
        path: `/user`,
        params: {}
      })
    } else if (item.type === 'my') {
      router.push({
        path: `/my`,
        params: {}
      })
    }
  }
}
const unWatch = watch(route, () => {
  if (route.path !== '/') {
    navStatus.value = route.path.split('/')[1]
    unWatch()
  }
})

</script>
<style lang="less" scoped>
.head {
  width: 100%;
  height: 64px;
  padding: 0px 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ebeef5;
  box-shadow: 0px 4px 6px 0px rgba(0, 61, 117, 0.12);
  .logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 36px;
    }
    text{
      margin-right: 16px;
      font-size: 16px;
      color: #131415;
    }
    &::after {
      content: "";
      display: inline-block;
      width: 1px;
      height: 32px;
      background: #d8d8d8;
    }
  }
  text {
    margin-right: auto;
    margin-left: 8px;
  }
  .head-nav {
    display: flex;
    margin: auto auto auto 8px;
    li {
      width: 104px;
      line-height: 32px;
      margin-left: 36px;
      text-align: center;
      border-radius: 4px;
      cursor: pointer;
      i {
        color: #909399;
        padding-left: 6px;
        font-size: 14px;
        font-style: normal;
        &.active {
          color: #1890ff;
        }
      }
      &.active {
        color: #1890ff;
        background-color: rgba(24, 144, 255, 0.1);
      }
    }
  }
  .avatar {
    border-left: 1px solid #d8d8d8;
    padding-left: 18px;
    .icon-user {
      font-size: 16px;
      margin-right: 6px;
    }
    .el-dropdown-link {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
}
</style>
